@charset "UTF-8";
/*--------------------------------------------------------------------------------------------
*
*	Vars
*
*--------------------------------------------------------------------------------------------*/
/* colors */
/* acf-field */
/* responsive */
/*--------------------------------------------------------------------------------------------
*
*	ACF 6 ↓
*
*--------------------------------------------------------------------------------------------*/
/*--------------------------------------------------------------------------------------------
*
*  Mixins
*
*--------------------------------------------------------------------------------------------*/
/*--------------------------------------------------------------------------------------------
*
*  Global
*
*--------------------------------------------------------------------------------------------*/
/* Horizontal List */
.acf-hl {
  padding: 0;
  margin: 0;
  list-style: none;
  display: block;
  position: relative;
}

.acf-hl > li {
  float: left;
  display: block;
  margin: 0;
  padding: 0;
}

.acf-hl > li.acf-fr {
  float: right;
}

/* Horizontal List: Clearfix */
.acf-hl:before,
.acf-hl:after,
.acf-bl:before,
.acf-bl:after,
.acf-cf:before,
.acf-cf:after {
  content: "";
  display: block;
  line-height: 0;
}

.acf-hl:after,
.acf-bl:after,
.acf-cf:after {
  clear: both;
}

/* Block List */
.acf-bl {
  padding: 0;
  margin: 0;
  list-style: none;
  display: block;
  position: relative;
}

.acf-bl > li {
  display: block;
  margin: 0;
  padding: 0;
  float: none;
}

/* Visibility */
.acf-hidden {
  display: none !important;
}

.acf-empty {
  display: table-cell !important;
}
.acf-empty * {
  display: none !important;
}

/* Float */
.acf-fl {
  float: left;
}

.acf-fr {
  float: right;
}

.acf-fn {
  float: none;
}

/* Align */
.acf-al {
  text-align: left;
}

.acf-ar {
  text-align: right;
}

.acf-ac {
  text-align: center;
}

/* loading */
.acf-loading,
.acf-spinner {
  display: inline-block;
  height: 20px;
  width: 20px;
  vertical-align: text-top;
  background: transparent url(../../images/spinner.gif) no-repeat 50% 50%;
}

/* spinner */
.acf-spinner {
  display: none;
}

.acf-spinner.is-active {
  display: inline-block;
}

/* WP < 4.2 */
.spinner.is-active {
  display: inline-block;
}

/* required */
.acf-required {
  color: #f00;
}

/* Allow pointer events in reusable blocks */
.acf-button,
.acf-tab-button {
  pointer-events: auto !important;
}

/* show on hover */
.acf-soh .acf-soh-target {
  -webkit-transition: opacity 0.25s 0s ease-in-out, visibility 0s linear 0.25s;
  -moz-transition: opacity 0.25s 0s ease-in-out, visibility 0s linear 0.25s;
  -o-transition: opacity 0.25s 0s ease-in-out, visibility 0s linear 0.25s;
  transition: opacity 0.25s 0s ease-in-out, visibility 0s linear 0.25s;
  visibility: hidden;
  opacity: 0;
}

.acf-soh:hover .acf-soh-target {
  -webkit-transition-delay: 0s;
  -moz-transition-delay: 0s;
  -o-transition-delay: 0s;
  transition-delay: 0s;
  visibility: visible;
  opacity: 1;
}

/* show if value */
.show-if-value {
  display: none;
}

.hide-if-value {
  display: block;
}

.has-value .show-if-value {
  display: block;
}

.has-value .hide-if-value {
  display: none;
}

/* select2 WP animation fix */
.select2-search-choice-close {
  -webkit-transition: none;
  -moz-transition: none;
  -o-transition: none;
  transition: none;
}

/*---------------------------------------------------------------------------------------------
*
*  tooltip
*
*---------------------------------------------------------------------------------------------*/
/* tooltip */
.acf-tooltip {
  background: #1D2939;
  border-radius: 6px;
  color: #D0D5DD;
  padding-top: 8px;
  padding-right: 12px;
  padding-bottom: 10px;
  padding-left: 12px;
  position: absolute;
  z-index: 900000;
  max-width: 280px;
  box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03);
  /* tip */
  /* positions */
}
.acf-tooltip:before {
  border: solid;
  border-color: transparent;
  border-width: 6px;
  content: "";
  position: absolute;
}
.acf-tooltip.top {
  margin-top: -8px;
}
.acf-tooltip.top:before {
  top: 100%;
  left: 50%;
  margin-left: -6px;
  border-top-color: #2f353e;
  border-bottom-width: 0;
}
.acf-tooltip.right {
  margin-left: 8px;
}
.acf-tooltip.right:before {
  top: 50%;
  margin-top: -6px;
  right: 100%;
  border-right-color: #2f353e;
  border-left-width: 0;
}
.acf-tooltip.bottom {
  margin-top: 8px;
}
.acf-tooltip.bottom:before {
  bottom: 100%;
  left: 50%;
  margin-left: -6px;
  border-bottom-color: #2f353e;
  border-top-width: 0;
}
.acf-tooltip.left {
  margin-left: -8px;
}
.acf-tooltip.left:before {
  top: 50%;
  margin-top: -6px;
  left: 100%;
  border-left-color: #2f353e;
  border-right-width: 0;
}
.acf-tooltip .acf-overlay {
  z-index: -1;
}

/* confirm */
.acf-tooltip.-confirm {
  z-index: 900001;
}
.acf-tooltip.-confirm a {
  text-decoration: none;
  color: #9ea3a8;
}
.acf-tooltip.-confirm a:hover {
  text-decoration: underline;
}
.acf-tooltip.-confirm a[data-event=confirm] {
  color: #f55e4f;
}

.acf-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  cursor: default;
}

.acf-tooltip-target {
  position: relative;
  z-index: 900002;
}

/*---------------------------------------------------------------------------------------------
*
*  loading
*
*---------------------------------------------------------------------------------------------*/
.acf-loading-overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  cursor: default;
  z-index: 99;
  background: rgba(249, 249, 249, 0.5);
}
.acf-loading-overlay i {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/*--------------------------------------------------------------------------------------------
*
*	acf-icon
*
*--------------------------------------------------------------------------------------------*/
.acf-icon {
  display: inline-block;
  height: 28px;
  width: 28px;
  border: transparent solid 1px;
  border-radius: 100%;
  font-size: 20px;
  line-height: 21px;
  text-align: center;
  text-decoration: none;
  vertical-align: top;
  box-sizing: border-box;
}
.acf-icon:before {
  font-family: dashicons;
  display: inline-block;
  line-height: 1;
  font-weight: 400;
  font-style: normal;
  speak: none;
  text-decoration: inherit;
  text-transform: none;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 1em;
  height: 1em;
  vertical-align: middle;
  text-align: center;
}

.acf-icon.-plus:before {
  content: "\f543";
}

.acf-icon.-minus:before {
  content: "\f460";
}

.acf-icon.-cancel:before {
  content: "\f335";
  margin: -1px 0 0 -1px;
}

.acf-icon.-pencil:before {
  content: "\f464";
}

.acf-icon.-location:before {
  content: "\f230";
}

.acf-icon.-up:before {
  content: "\f343";
  margin-top: -0.1em;
}

.acf-icon.-down:before {
  content: "\f347";
  margin-top: 0.1em;
}

.acf-icon.-left:before {
  content: "\f341";
  margin-left: -0.1em;
}

.acf-icon.-right:before {
  content: "\f345";
  margin-left: 0.1em;
}

.acf-icon.-sync:before {
  content: "\f463";
}

.acf-icon.-globe:before {
  content: "\f319";
  margin-top: 0.1em;
  margin-left: 0.1em;
}

.acf-icon.-picture:before {
  content: "\f128";
}

.acf-icon.-check:before {
  content: "\f147";
  margin-left: -0.1em;
}

.acf-icon.-dot-3:before {
  content: "\f533";
  margin-top: -0.1em;
}

.acf-icon.-arrow-combo:before {
  content: "\f156";
}

.acf-icon.-arrow-up:before {
  content: "\f142";
  margin-left: -0.1em;
}

.acf-icon.-arrow-down:before {
  content: "\f140";
  margin-left: -0.1em;
}

.acf-icon.-search:before {
  content: "\f179";
}

.acf-icon.-link-ext:before {
  content: "\f504";
}

.acf-icon.-duplicate {
  position: relative;
}
.acf-icon.-duplicate:before, .acf-icon.-duplicate:after {
  content: "";
  display: block;
  box-sizing: border-box;
  width: 46%;
  height: 46%;
  position: absolute;
  top: 33%;
  left: 23%;
}
.acf-icon.-duplicate:before {
  margin: -1px 0 0 1px;
  box-shadow: 2px -2px 0px 0px currentColor;
}
.acf-icon.-duplicate:after {
  border: solid 2px currentColor;
}

.acf-icon.-trash {
  position: relative;
}
.acf-icon.-trash:before, .acf-icon.-trash:after {
  content: "";
  display: block;
  box-sizing: border-box;
  width: 46%;
  height: 46%;
  position: absolute;
  top: 33%;
  left: 23%;
}
.acf-icon.-trash:before {
  margin: -1px 0 0 1px;
  box-shadow: 2px -2px 0px 0px currentColor;
}
.acf-icon.-trash:after {
  border: solid 2px currentColor;
}

.acf-icon.-collapse:before {
  content: "\f142";
  margin-left: -0.1em;
}

.-collapsed .acf-icon.-collapse:before {
  content: "\f140";
  margin-left: -0.1em;
}

span.acf-icon {
  color: #555d66;
  border-color: #b5bcc2;
  background-color: #fff;
}

a.acf-icon {
  color: #555d66;
  border-color: #b5bcc2;
  background-color: #fff;
  position: relative;
  transition: none;
  cursor: pointer;
}
a.acf-icon:hover {
  background: #f3f5f6;
  border-color: #0071a1;
  color: #0071a1;
}
a.acf-icon.-minus:hover, a.acf-icon.-cancel:hover {
  background: #f7efef;
  border-color: #a10000;
  color: #dc3232;
}
a.acf-icon:active, a.acf-icon:focus {
  outline: none;
  box-shadow: none;
}

.acf-icon.-clear {
  border-color: transparent;
  background: transparent;
  color: #444;
}

.acf-icon.light {
  border-color: transparent;
  background: #f5f5f5;
  color: #23282d;
}

.acf-icon.dark {
  border-color: transparent !important;
  background: #23282d;
  color: #eee;
}

a.acf-icon.dark:hover {
  background: #191e23;
  color: #00b9eb;
}
a.acf-icon.dark.-minus:hover, a.acf-icon.dark.-cancel:hover {
  color: #d54e21;
}

.acf-icon.grey {
  border-color: transparent !important;
  background: #b4b9be;
  color: #fff !important;
}
.acf-icon.grey:hover {
  background: #00a0d2;
  color: #fff;
}
.acf-icon.grey.-minus:hover, .acf-icon.grey.-cancel:hover {
  background: #32373c;
}

.acf-icon.small,
.acf-icon.-small {
  width: 20px;
  height: 20px;
  line-height: 14px;
  font-size: 14px;
}
.acf-icon.small.-duplicate:before, .acf-icon.small.-duplicate:after,
.acf-icon.-small.-duplicate:before,
.acf-icon.-small.-duplicate:after {
  opacity: 0.8;
}

/*--------------------------------------------------------------------------------------------
*
*	acf-box
*
*--------------------------------------------------------------------------------------------*/
.acf-box {
  background: #ffffff;
  border: 1px solid #ccd0d4;
  position: relative;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.04);
  /* title */
  /* footer */
}
.acf-box .title {
  border-bottom: 1px solid #ccd0d4;
  margin: 0;
  padding: 15px;
}
.acf-box .title h3 {
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 1em;
  margin: 0;
  padding: 0;
}
.acf-box .inner {
  padding: 15px;
}
.acf-box h2 {
  color: #333333;
  font-size: 26px;
  line-height: 1.25em;
  margin: 0.25em 0 0.75em;
  padding: 0;
}
.acf-box h3 {
  margin: 1.5em 0 0;
}
.acf-box p {
  margin-top: 0.5em;
}
.acf-box a {
  text-decoration: none;
}
.acf-box i.dashicons-external {
  margin-top: -1px;
}
.acf-box .footer {
  border-top: 1px solid #ccd0d4;
  padding: 12px;
  font-size: 13px;
  line-height: 1.5;
}
.acf-box .footer p {
  margin: 0;
}
.acf-admin-3-8 .acf-box {
  border-color: #E5E5E5;
}
.acf-admin-3-8 .acf-box .title,
.acf-admin-3-8 .acf-box .footer {
  border-color: #E5E5E5;
}

/*--------------------------------------------------------------------------------------------
*
*	acf-notice
*
*--------------------------------------------------------------------------------------------*/
.acf-notice {
  position: relative;
  display: block;
  color: #fff;
  margin: 5px 0 15px;
  padding: 3px 12px;
  background: #2a9bd9;
  border-left: #1f7db1 solid 3px;
}
.acf-notice p {
  font-size: 13px;
  line-height: 1.5;
  margin: 0.5em 0;
  text-shadow: none;
  color: inherit;
}
.acf-notice .acf-notice-dismiss {
  position: absolute;
  top: 9px;
  right: 12px;
  background: transparent !important;
  color: inherit !important;
  border-color: #fff !important;
  opacity: 0.75;
}
.acf-notice .acf-notice-dismiss:hover {
  opacity: 1;
}
.acf-notice.-dismiss {
  padding-right: 40px;
}
.acf-notice.-error {
  background: #d94f4f;
  border-color: #c92c2c;
}
.acf-notice.-success {
  background: #49ad52;
  border-color: #3a8941;
}
.acf-notice.-warning {
  background: #fd8d3b;
  border-color: #fc7009;
}

/*--------------------------------------------------------------------------------------------
*
*	acf-table
*
*--------------------------------------------------------------------------------------------*/
.acf-table {
  border: #ccd0d4 solid 1px;
  background: #fff;
  border-spacing: 0;
  border-radius: 0;
  table-layout: auto;
  padding: 0;
  margin: 0;
  width: 100%;
  clear: both;
  box-sizing: content-box;
  /* defaults */
  /* thead */
  /* tbody */
  /* -clear */
}
.acf-table > tbody > tr > th,
.acf-table > tbody > tr > td,
.acf-table > thead > tr > th,
.acf-table > thead > tr > td {
  padding: 8px;
  vertical-align: top;
  background: #fff;
  text-align: left;
  border-style: solid;
  font-weight: normal;
}
.acf-table > tbody > tr > th,
.acf-table > thead > tr > th {
  position: relative;
  color: #333333;
}
.acf-table > thead > tr > th {
  border-color: #d5d9dd;
  border-width: 0 0 1px 1px;
}
.acf-table > thead > tr > th:first-child {
  border-left-width: 0;
}
.acf-table > tbody > tr {
  z-index: 1;
}
.acf-table > tbody > tr > td {
  border-color: #eeeeee;
  border-width: 1px 0 0 1px;
}
.acf-table > tbody > tr > td:first-child {
  border-left-width: 0;
}
.acf-table > tbody > tr:first-child > td {
  border-top-width: 0;
}
.acf-table.-clear {
  border: 0 none;
}
.acf-table.-clear > tbody > tr > td,
.acf-table.-clear > tbody > tr > th,
.acf-table.-clear > thead > tr > td,
.acf-table.-clear > thead > tr > th {
  border: 0 none;
  padding: 4px;
}

/* remove tr */
.acf-remove-element {
  -webkit-transition: all 0.25s ease-out;
  -moz-transition: all 0.25s ease-out;
  -o-transition: all 0.25s ease-out;
  transition: all 0.25s ease-out;
  transform: translate(50px, 0);
  opacity: 0;
}

/* fade-up */
.acf-fade-up {
  -webkit-transition: all 0.25s ease-out;
  -moz-transition: all 0.25s ease-out;
  -o-transition: all 0.25s ease-out;
  transition: all 0.25s ease-out;
  transform: translate(0, -10px);
  opacity: 0;
}

/*---------------------------------------------------------------------------------------------
*
*  Fake table
*
*---------------------------------------------------------------------------------------------*/
.acf-thead,
.acf-tbody,
.acf-tfoot {
  width: 100%;
  padding: 0;
  margin: 0;
}
.acf-thead > li,
.acf-tbody > li,
.acf-tfoot > li {
  box-sizing: border-box;
  padding-top: 14px;
  font-size: 12px;
  line-height: 14px;
}

.acf-thead {
  border-bottom: #ccd0d4 solid 1px;
  color: #23282d;
}
.acf-thead > li {
  font-size: 14px;
  line-height: 1.4;
  font-weight: bold;
}
.acf-admin-3-8 .acf-thead {
  border-color: #dfdfdf;
}

.acf-tfoot {
  background: #f5f5f5;
  border-top: #d5d9dd solid 1px;
}

/*--------------------------------------------------------------------------------------------
*
*	Settings
*
*--------------------------------------------------------------------------------------------*/
.acf-settings-wrap #poststuff {
  padding-top: 15px;
}
.acf-settings-wrap .acf-box {
  margin: 20px 0;
}
.acf-settings-wrap table {
  margin: 0;
}
.acf-settings-wrap table .button {
  vertical-align: middle;
}

/*--------------------------------------------------------------------------------------------
*
*	acf-popup
*
*--------------------------------------------------------------------------------------------*/
#acf-popup {
  position: fixed;
  z-index: 900000;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  text-align: center;
}
#acf-popup .bg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
  background: rgba(0, 0, 0, 0.25);
}
#acf-popup:before {
  content: "";
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}
#acf-popup .acf-popup-box {
  display: inline-block;
  vertical-align: middle;
  z-index: 1;
  min-width: 300px;
  min-height: 160px;
  border-color: #aaaaaa;
  box-shadow: 0 5px 30px -5px rgba(0, 0, 0, 0.25);
  text-align: left;
}
html[dir=rtl] #acf-popup .acf-popup-box {
  text-align: right;
}
#acf-popup .acf-popup-box .title {
  min-height: 15px;
  line-height: 15px;
}
#acf-popup .acf-popup-box .title .acf-icon {
  position: absolute;
  top: 10px;
  right: 10px;
}
html[dir=rtl] #acf-popup .acf-popup-box .title .acf-icon {
  right: auto;
  left: 10px;
}
#acf-popup .acf-popup-box .inner {
  min-height: 50px;
  padding: 0;
  margin: 15px;
}
#acf-popup .acf-popup-box .loading {
  position: absolute;
  top: 45px;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  background: rgba(0, 0, 0, 0.1);
  display: none;
}
#acf-popup .acf-popup-box .loading i {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.acf-submit {
  margin-bottom: 0;
  line-height: 28px;
}
.acf-submit span {
  float: right;
  color: #999;
}
.acf-submit span.-error {
  color: #dd4232;
}
.acf-submit .button {
  margin-right: 5px;
}

/*--------------------------------------------------------------------------------------------
*
*	upgrade notice
*
*--------------------------------------------------------------------------------------------*/
#acf-upgrade-notice {
  position: relative;
  background: #fff;
  padding: 20px;
}
#acf-upgrade-notice:after {
  display: block;
  clear: both;
  content: "";
}
#acf-upgrade-notice .col-content {
  float: left;
  width: 55%;
  padding-left: 90px;
}
#acf-upgrade-notice .notice-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  align-content: flex-start;
}
#acf-upgrade-notice .col-actions {
  float: right;
  text-align: center;
}
#acf-upgrade-notice img {
  float: left;
  width: 64px;
  height: 64px;
  margin: 0 0 0 -90px;
}
#acf-upgrade-notice h2 {
  display: inline-block;
  font-size: 16px;
  margin: 2px 0 6.5px;
}
#acf-upgrade-notice p {
  padding: 0;
  margin: 0;
}
#acf-upgrade-notice .button:before {
  margin-top: 11px;
}
@media screen and (max-width: 640px) {
  #acf-upgrade-notice .col-content,
  #acf-upgrade-notice .col-actions {
    float: none;
    padding-left: 90px;
    width: auto;
    text-align: left;
  }
}

#acf-upgrade-notice:has(.notice-container)::before,
#acf-upgrade-notice:has(.notice-container)::after {
  display: none;
}

#acf-upgrade-notice:has(.notice-container) {
  padding-left: 20px !important;
}

/*--------------------------------------------------------------------------------------------
*
*	Welcome
*
*--------------------------------------------------------------------------------------------*/
.acf-wrap h1 {
  margin-top: 0;
  padding-top: 20px;
}
.acf-wrap .about-text {
  margin-top: 0.5em;
  min-height: 50px;
}
.acf-wrap .about-headline-callout {
  font-size: 2.4em;
  font-weight: 300;
  line-height: 1.3;
  margin: 1.1em 0 0.2em;
  text-align: center;
}
.acf-wrap .feature-section {
  padding: 40px 0;
}
.acf-wrap .feature-section h2 {
  margin-top: 20px;
}
.acf-wrap .changelog {
  list-style: disc;
  padding-left: 15px;
}
.acf-wrap .changelog li {
  margin: 0 0 0.75em;
}
.acf-wrap .acf-three-col {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.acf-wrap .acf-three-col > div {
  flex: 1;
  align-self: flex-start;
  min-width: 31%;
  max-width: 31%;
}
@media screen and (max-width: 880px) {
  .acf-wrap .acf-three-col > div {
    min-width: 48%;
  }
}
@media screen and (max-width: 640px) {
  .acf-wrap .acf-three-col > div {
    min-width: 100%;
  }
}
.acf-wrap .acf-three-col h3 .badge {
  display: inline-block;
  vertical-align: top;
  border-radius: 5px;
  background: #fc9700;
  color: #fff;
  font-weight: normal;
  font-size: 12px;
  padding: 2px 5px;
}
.acf-wrap .acf-three-col img + h3 {
  margin-top: 0.5em;
}

/*--------------------------------------------------------------------------------------------
*
*	acf-hl cols
*
*--------------------------------------------------------------------------------------------*/
.acf-hl[data-cols] {
  margin-left: -10px;
  margin-right: -10px;
}
.acf-hl[data-cols] > li {
  padding: 0 6px 0 10px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

/* sizes */
.acf-hl[data-cols="2"] > li {
  width: 50%;
}

.acf-hl[data-cols="3"] > li {
  width: 33.333%;
}

.acf-hl[data-cols="4"] > li {
  width: 25%;
}

/* mobile */
@media screen and (max-width: 640px) {
  .acf-hl[data-cols] {
    flex-wrap: wrap;
    justify-content: flex-start;
    align-content: flex-start;
    align-items: flex-start;
    margin-left: 0;
    margin-right: 0;
    margin-top: -10px;
  }
  .acf-hl[data-cols] > li {
    flex: 1 1 100%;
    width: 100% !important;
    padding: 10px 0 0;
  }
}
/*--------------------------------------------------------------------------------------------
*
*	misc
*
*--------------------------------------------------------------------------------------------*/
.acf-actions {
  text-align: right;
  z-index: 1;
  /* hover */
  /* rtl */
}
.acf-actions.-hover {
  position: absolute;
  display: none;
  top: 0;
  right: 0;
  padding: 5px;
  z-index: 1050;
}
html[dir=rtl] .acf-actions.-hover {
  right: auto;
  left: 0;
}

/* ul compatibility */
ul.acf-actions li {
  float: right;
  margin-left: 4px;
}

/*--------------------------------------------------------------------------------------------
*
*	RTL
*
*--------------------------------------------------------------------------------------------*/
html[dir=rtl] .acf-fl {
  float: right;
}

html[dir=rtl] .acf-fr {
  float: left;
}

html[dir=rtl] .acf-hl > li {
  float: right;
}

html[dir=rtl] .acf-hl > li.acf-fr {
  float: left;
}

html[dir=rtl] .acf-icon.logo {
  left: 0;
  right: auto;
}

html[dir=rtl] .acf-table thead th {
  text-align: right;
  border-right-width: 1px;
  border-left-width: 0px;
}

html[dir=rtl] .acf-table > tbody > tr > td {
  text-align: right;
  border-right-width: 1px;
  border-left-width: 0px;
}

html[dir=rtl] .acf-table > thead > tr > th:first-child,
html[dir=rtl] .acf-table > tbody > tr > td:first-child {
  border-right-width: 0;
}

html[dir=rtl] .acf-table > tbody > tr > td.order + td {
  border-right-color: #e1e1e1;
}

/*---------------------------------------------------------------------------------------------
*
*  acf-postbox-columns
*
*---------------------------------------------------------------------------------------------*/
.acf-postbox-columns {
  position: relative;
  margin-top: -11px;
  margin-bottom: -12px;
  margin-left: -12px;
  margin-right: 268px;
}
.acf-postbox-columns:after {
  display: block;
  clear: both;
  content: "";
}
.acf-postbox-columns .acf-postbox-main,
.acf-postbox-columns .acf-postbox-side {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0 12px 12px;
}
.acf-postbox-columns .acf-postbox-main {
  float: left;
  width: 100%;
}
.acf-postbox-columns .acf-postbox-side {
  float: right;
  width: 280px;
  margin-right: -280px;
}
.acf-postbox-columns .acf-postbox-side:before {
  content: "";
  display: block;
  position: absolute;
  width: 1px;
  height: 100%;
  top: 0;
  right: 0;
  background: #d5d9dd;
}
.acf-admin-3-8 .acf-postbox-columns .acf-postbox-side:before {
  background: #dfdfdf;
}

/* mobile */
@media only screen and (max-width: 850px) {
  .acf-postbox-columns {
    margin: 0;
  }
  .acf-postbox-columns .acf-postbox-main,
  .acf-postbox-columns .acf-postbox-side {
    float: none;
    width: auto;
    margin: 0;
    padding: 0;
  }
  .acf-postbox-columns .acf-postbox-side {
    margin-top: 1em;
  }
  .acf-postbox-columns .acf-postbox-side:before {
    display: none;
  }
}
/*---------------------------------------------------------------------------------------------
*
*  acf-panel
*
*---------------------------------------------------------------------------------------------*/
.acf-panel {
  margin-top: -1px;
  border-top: 1px solid #d5d9dd;
  border-bottom: 1px solid #d5d9dd;
  /* open */
  /* inside postbox */
  /* fields */
}
.acf-panel .acf-panel-title {
  margin: 0;
  padding: 12px;
  font-weight: bold;
  cursor: pointer;
  font-size: inherit;
}
.acf-panel .acf-panel-title i {
  float: right;
}
.acf-panel .acf-panel-inside {
  margin: 0;
  padding: 0 12px 12px;
  display: none;
}
.acf-panel.-open .acf-panel-inside {
  display: block;
}
.postbox .acf-panel {
  margin-left: -12px;
  margin-right: -12px;
}
.acf-panel .acf-field {
  margin: 20px 0 0;
}
.acf-panel .acf-field .acf-label label {
  color: #555d66;
  font-weight: normal;
}
.acf-panel .acf-field:first-child {
  margin-top: 0;
}
.acf-admin-3-8 .acf-panel {
  border-color: #dfdfdf;
}

/*---------------------------------------------------------------------------------------------
*
*  Admin Tools
*
*---------------------------------------------------------------------------------------------*/
#acf-admin-tools .notice {
  margin-top: 10px;
}
#acf-admin-tools .acf-meta-box-wrap {
  /* acf-fields */
}
#acf-admin-tools .acf-meta-box-wrap .inside {
  border-top: none;
}
#acf-admin-tools .acf-meta-box-wrap .acf-fields {
  margin-bottom: 24px;
  border: none;
  background: #fff;
  border-radius: 0;
}
#acf-admin-tools .acf-meta-box-wrap .acf-fields .acf-field {
  padding: 0;
  margin-bottom: 19px;
  border-top: none;
}
#acf-admin-tools .acf-meta-box-wrap .acf-fields .acf-label {
  margin-bottom: 16px;
}
#acf-admin-tools .acf-meta-box-wrap .acf-fields .acf-input {
  padding-top: 16px;
  padding-right: 16px;
  padding-bottom: 16px;
  padding-left: 16px;
  border-width: 1px;
  border-style: solid;
  border-color: #D0D5DD;
  border-radius: 6px;
}
#acf-admin-tools .acf-meta-box-wrap .acf-fields.import-cptui {
  margin-top: 19px;
}

.acf-meta-box-wrap .postbox {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.acf-meta-box-wrap .postbox .inside {
  margin-bottom: 0;
}
.acf-meta-box-wrap .postbox .hndle {
  font-size: 14px;
  padding: 8px 12px;
  margin: 0;
  line-height: 1.4;
  position: relative;
  z-index: 1;
  cursor: default;
}
.acf-meta-box-wrap .postbox .handlediv,
.acf-meta-box-wrap .postbox .handle-order-higher,
.acf-meta-box-wrap .postbox .handle-order-lower {
  display: none;
}

/* grid */
.acf-meta-box-wrap.-grid {
  margin-left: 8px;
  margin-right: 8px;
}
.acf-meta-box-wrap.-grid .postbox {
  float: left;
  clear: left;
  width: 50%;
  margin: 0 0 16px;
}
.acf-meta-box-wrap.-grid .postbox:nth-child(odd) {
  margin-left: -8px;
}
.acf-meta-box-wrap.-grid .postbox:nth-child(even) {
  float: right;
  clear: right;
  margin-right: -8px;
}

/* mobile */
@media only screen and (max-width: 850px) {
  .acf-meta-box-wrap.-grid {
    margin-left: 0;
    margin-right: 0;
  }
  .acf-meta-box-wrap.-grid .postbox {
    margin-left: 0 !important;
    margin-right: 0 !important;
    width: 100%;
  }
}
/* export tool */
#acf-admin-tool-export {
  /* panel: selection */
}
#acf-admin-tool-export p {
  max-width: 800px;
}
#acf-admin-tool-export ul {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}
#acf-admin-tool-export ul li {
  flex: 0 1 33.33%;
}
@media screen and (max-width: 1600px) {
  #acf-admin-tool-export ul li {
    flex: 0 1 50%;
  }
}
@media screen and (max-width: 1200px) {
  #acf-admin-tool-export ul li {
    flex: 0 1 100%;
  }
}
#acf-admin-tool-export .acf-postbox-side ul {
  display: block;
}
#acf-admin-tool-export .acf-postbox-side .button {
  margin: 0;
  width: 100%;
}
#acf-admin-tool-export textarea {
  display: block;
  width: 100%;
  min-height: 500px;
  background: #F9FAFB;
  border-color: #D0D5DD;
  box-shadow: none;
  padding: 7px;
  border-radius: 6px;
}
#acf-admin-tool-export .acf-panel-selection .acf-label label {
  font-weight: bold;
  color: #344054;
}

#acf-admin-tool-import ul {
  column-width: 200px;
}

.acf-css-tooltip {
  position: relative;
}
.acf-css-tooltip:before {
  content: attr(aria-label);
  display: none;
  position: absolute;
  z-index: 999;
  bottom: 100%;
  left: 50%;
  transform: translate(-50%, -8px);
  background: #191e23;
  border-radius: 2px;
  padding: 5px 10px;
  color: #fff;
  font-size: 12px;
  line-height: 1.4em;
  white-space: pre;
}
.acf-css-tooltip:after {
  content: "";
  display: none;
  position: absolute;
  z-index: 998;
  bottom: 100%;
  left: 50%;
  transform: translate(-50%, 4px);
  border: solid 6px transparent;
  border-top-color: #191e23;
}
.acf-css-tooltip:hover:before, .acf-css-tooltip:hover:after, .acf-css-tooltip:focus:before, .acf-css-tooltip:focus:after {
  display: block;
}

.acf-diff .acf-diff-title {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 40px;
  padding: 14px 16px;
  background: #f3f3f3;
  border-bottom: #dddddd solid 1px;
}
.acf-diff .acf-diff-title strong {
  font-size: 14px;
  display: block;
}
.acf-diff .acf-diff-title .acf-diff-title-left,
.acf-diff .acf-diff-title .acf-diff-title-right {
  width: 50%;
  float: left;
}
.acf-diff .acf-diff-content {
  position: absolute;
  top: 70px;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: auto;
}
.acf-diff table.diff {
  border-spacing: 0;
}
.acf-diff table.diff col.diffsplit.middle {
  width: 0;
}
.acf-diff table.diff td,
.acf-diff table.diff th {
  padding-top: 0.25em;
  padding-bottom: 0.25em;
}
.acf-diff table.diff tr td:nth-child(2) {
  width: auto;
}
.acf-diff table.diff td:nth-child(3) {
  border-left: #dddddd solid 1px;
}
@media screen and (max-width: 600px) {
  .acf-diff .acf-diff-title {
    height: 70px;
  }
  .acf-diff .acf-diff-content {
    top: 100px;
  }
}

/*---------------------------------------------------------------------------------------------
*
*  Modal
*
*---------------------------------------------------------------------------------------------*/
.acf-modal {
  position: fixed;
  top: 30px;
  left: 30px;
  right: 30px;
  bottom: 30px;
  z-index: 160000;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.7);
  background: #fcfcfc;
}
.acf-modal .acf-modal-title,
.acf-modal .acf-modal-content,
.acf-modal .acf-modal-toolbar {
  box-sizing: border-box;
  position: absolute;
  left: 0;
  right: 0;
}
.acf-modal .acf-modal-title {
  height: 50px;
  top: 0;
  border-bottom: 1px solid #ddd;
}
.acf-modal .acf-modal-title h2 {
  margin: 0;
  padding: 0 16px;
  line-height: 50px;
}
.acf-modal .acf-modal-title .acf-modal-close {
  position: absolute;
  top: 0;
  right: 0;
  height: 50px;
  width: 50px;
  border: none;
  border-left: 1px solid #ddd;
  background: transparent;
  cursor: pointer;
  color: #666;
}
.acf-modal .acf-modal-title .acf-modal-close:hover {
  color: #00a0d2;
}
.acf-modal .acf-modal-content {
  top: 50px;
  bottom: 60px;
  background: #fff;
  overflow: auto;
  padding: 16px;
}
.acf-modal .acf-modal-feedback {
  position: absolute;
  top: 50%;
  margin: -10px 0;
  left: 0;
  right: 0;
  text-align: center;
  opacity: 0.75;
}
.acf-modal .acf-modal-feedback.error {
  opacity: 1;
  color: #b52727;
}
.acf-modal .acf-modal-toolbar {
  height: 60px;
  bottom: 0;
  padding: 15px 16px;
  border-top: 1px solid #ddd;
}
.acf-modal .acf-modal-toolbar .button {
  float: right;
}
@media only screen and (max-width: 640px) {
  .acf-modal {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

.acf-modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #101828;
  opacity: 0.8;
  z-index: 159900;
}

/*---------------------------------------------------------------------------------------------
*
*  Retina
*
*---------------------------------------------------------------------------------------------*/
@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2/1), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
  .acf-loading,
  .acf-spinner {
    background-image: url(../../images/spinner@2x.gif);
    background-size: 20px 20px;
  }
}
/*--------------------------------------------------------------------------------------------
*
*  Wrap
*
*--------------------------------------------------------------------------------------------*/
.acf-admin-page .wrap {
  margin-top: 48px;
  margin-right: 32px;
  margin-bottom: 0;
  margin-left: 12px;
}
@media screen and (max-width: 768px) {
  .acf-admin-page .wrap {
    margin-right: 8px;
    margin-left: 8px;
  }
}
.acf-admin-page.rtl .wrap {
  margin-right: 12px;
  margin-left: 32px;
}
@media screen and (max-width: 768px) {
  .acf-admin-page.rtl .wrap {
    margin-right: 8px;
    margin-left: 8px;
  }
}
@media screen and (max-width: 768px) {
  .acf-admin-page #wpcontent {
    padding-left: 0;
  }
}

/*-------------------------------------------------------------------
*
*  ACF Admin Page Footer Styles
*
*------------------------------------------------------------------*/
.acf-admin-page #wpfooter {
  font-style: italic;
}

/*---------------------------------------------------------------------------------------------
*
*  Admin Postbox & ACF Postbox
*
*---------------------------------------------------------------------------------------------*/
.acf-admin-page .postbox,
.acf-admin-page .acf-box {
  border: none;
  border-radius: 8px;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.1);
}
.acf-admin-page .postbox .inside,
.acf-admin-page .acf-box .inside {
  padding-top: 24px;
  padding-right: 24px;
  padding-bottom: 24px;
  padding-left: 24px;
}
.acf-admin-page .postbox .acf-postbox-inner,
.acf-admin-page .acf-box .acf-postbox-inner {
  margin-top: 0;
  margin-right: 0;
  margin-bottom: 0;
  margin-left: 0;
  padding-top: 24px;
  padding-right: 0;
  padding-bottom: 0;
  padding-left: 0;
}
.acf-admin-page .postbox .inner,
.acf-admin-page .postbox .inside,
.acf-admin-page .acf-box .inner,
.acf-admin-page .acf-box .inside {
  margin-top: 0 !important;
  margin-right: 0 !important;
  margin-bottom: 0 !important;
  margin-left: 0 !important;
  border-top-width: 1px;
  border-top-style: solid;
  border-top-color: #EAECF0;
}
.acf-admin-page .postbox .postbox-header,
.acf-admin-page .postbox .title,
.acf-admin-page .acf-box .postbox-header,
.acf-admin-page .acf-box .title {
  display: flex;
  align-items: center;
  box-sizing: border-box;
  min-height: 64px;
  margin-top: 0;
  margin-right: 0;
  margin-bottom: 0;
  margin-left: 0;
  padding-top: 0;
  padding-right: 24px;
  padding-bottom: 0;
  padding-left: 24px;
  border-bottom-width: 0;
  border-bottom-style: none;
}
.acf-admin-page .postbox .postbox-header h2,
.acf-admin-page .postbox .postbox-header h3,
.acf-admin-page .postbox .title h2,
.acf-admin-page .postbox .title h3,
.acf-admin-page .acf-box .postbox-header h2,
.acf-admin-page .acf-box .postbox-header h3,
.acf-admin-page .acf-box .title h2,
.acf-admin-page .acf-box .title h3 {
  margin-top: 0;
  margin-right: 0;
  margin-bottom: 0;
  margin-left: 0;
  padding-top: 0;
  padding-right: 0;
  padding-bottom: 0;
  padding-left: 0;
  color: #344054;
}
.acf-admin-page .postbox .hndle,
.acf-admin-page .acf-box .hndle {
  padding-top: 0;
  padding-right: 24px;
  padding-bottom: 0;
  padding-left: 24px;
}

/*---------------------------------------------------------------------------------------------
*
*  Custom ACF postbox header
*
*---------------------------------------------------------------------------------------------*/
.acf-postbox-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  min-height: 64px;
  margin-top: -24px;
  margin-right: -24px;
  margin-bottom: 0;
  margin-left: -24px;
  padding-top: 0;
  padding-right: 24px;
  padding-bottom: 0;
  padding-left: 24px;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: #EAECF0;
}
.acf-postbox-header h2.acf-postbox-title {
  margin-top: 0;
  margin-right: 0;
  margin-bottom: 0;
  margin-left: 0;
  padding-top: 0;
  padding-right: 24px;
  padding-bottom: 0;
  padding-left: 0;
  color: #344054;
}
.rtl .acf-postbox-header h2.acf-postbox-title {
  padding-right: 0;
  padding-left: 24px;
}
.acf-postbox-header .acf-icon {
  background-color: #98A2B3;
}

/*---------------------------------------------------------------------------------------------
*
*  Screen options button & screen meta container
*
*---------------------------------------------------------------------------------------------*/
.acf-admin-page #screen-meta-links {
  margin-right: 32px;
}
.acf-admin-page #screen-meta-links .show-settings {
  border-color: #D0D5DD;
}
@media screen and (max-width: 768px) {
  .acf-admin-page #screen-meta-links {
    margin-right: 16px;
    margin-bottom: 0;
  }
}
.acf-admin-page.rtl #screen-meta-links {
  margin-right: 0;
  margin-left: 32px;
}
@media screen and (max-width: 768px) {
  .acf-admin-page.rtl #screen-meta-links {
    margin-right: 0;
    margin-left: 16px;
  }
}
.acf-admin-page #screen-meta {
  border-color: #D0D5DD;
}

/*---------------------------------------------------------------------------------------------
*
*  Postbox headings
*
*---------------------------------------------------------------------------------------------*/
.acf-admin-page #poststuff .postbox-header h2,
.acf-admin-page #poststuff .postbox-header h3 {
  justify-content: flex-start;
  margin-top: 0;
  margin-right: 0;
  margin-bottom: 0;
  margin-left: 0;
  padding-top: 0;
  padding-right: 0;
  padding-bottom: 0;
  padding-left: 0;
  color: #344054 !important;
}

/*---------------------------------------------------------------------------------------------
*
* Postbox drag state
*
*---------------------------------------------------------------------------------------------*/
.acf-admin-page.is-dragging-metaboxes .metabox-holder .postbox-container .meta-box-sortables {
  box-sizing: border-box;
  padding: 2px;
  outline: none;
  background-image: repeating-linear-gradient(0deg, #667085, #667085 5px, transparent 5px, transparent 10px, #667085 10px), repeating-linear-gradient(90deg, #667085, #667085 5px, transparent 5px, transparent 10px, #667085 10px), repeating-linear-gradient(180deg, #667085, #667085 5px, transparent 5px, transparent 10px, #667085 10px), repeating-linear-gradient(270deg, #667085, #667085 5px, transparent 5px, transparent 10px, #667085 10px);
  background-size: 1.5px 100%, 100% 1.5px, 1.5px 100%, 100% 1.5px;
  background-position: 0 0, 0 0, 100% 0, 0 100%;
  background-repeat: no-repeat;
  border-radius: 8px;
}
.acf-admin-page .ui-sortable-placeholder {
  border: none;
}

/*--------------------------------------------------------------------------------------------
*
*  Search summary
*
*--------------------------------------------------------------------------------------------*/
.acf-admin-page .subtitle {
  display: inline-flex;
  align-items: center;
  height: 24px;
  margin: 0;
  padding-top: 4px;
  padding-right: 12px;
  padding-bottom: 4px;
  padding-left: 12px;
  background-color: #EBF5FA;
  border-width: 1px;
  border-style: solid;
  border-color: #A5D2E7;
  border-radius: 6px;
}
.acf-admin-page .subtitle strong {
  margin-left: 5px;
}

/*--------------------------------------------------------------------------------------------
*
*  Action strip
*
*--------------------------------------------------------------------------------------------*/
.acf-actions-strip {
  display: flex;
}
.acf-actions-strip .acf-btn {
  margin-right: 8px;
}

/*--------------------------------------------------------------------------------------------
*
*  Notices
*
*--------------------------------------------------------------------------------------------*/
.acf-admin-page .acf-notice,
.acf-admin-page .notice,
.acf-admin-page #lost-connection-notice {
  position: relative;
  box-sizing: border-box;
  min-height: 48px;
  margin-top: 0 !important;
  margin-right: 0 !important;
  margin-bottom: 16px !important;
  margin-left: 0 !important;
  padding-top: 13px !important;
  padding-right: 16px !important;
  padding-bottom: 12px !important;
  padding-left: 50px !important;
  background-color: #e7eff9;
  border-width: 1px;
  border-style: solid;
  border-color: #9dbaee;
  border-radius: 8px;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.1);
  color: #344054;
}
.acf-admin-page .acf-notice.update-nag,
.acf-admin-page .notice.update-nag,
.acf-admin-page #lost-connection-notice.update-nag {
  display: block;
  position: relative;
  width: calc(100% - 44px);
  margin-top: 48px !important;
  margin-right: 44px !important;
  margin-bottom: -32px !important;
  margin-left: 12px !important;
}
.acf-admin-page .acf-notice .button,
.acf-admin-page .notice .button,
.acf-admin-page #lost-connection-notice .button {
  height: auto;
  margin-left: 8px;
  padding: 0;
  border: none;
}
.acf-admin-page .acf-notice > div,
.acf-admin-page .notice > div,
.acf-admin-page #lost-connection-notice > div {
  margin-top: 0;
  margin-bottom: 0;
}
.acf-admin-page .acf-notice p,
.acf-admin-page .notice p,
.acf-admin-page #lost-connection-notice p {
  flex: 1 0 auto;
  max-width: 100%;
  line-height: 18px;
  margin: 0;
  padding: 0;
}
.acf-admin-page .acf-notice p.help,
.acf-admin-page .notice p.help,
.acf-admin-page #lost-connection-notice p.help {
  margin-top: 0;
  padding-top: 0;
  color: rgba(52, 64, 84, 0.7);
}
.acf-admin-page .acf-notice .acf-notice-dismiss,
.acf-admin-page .acf-notice .notice-dismiss,
.acf-admin-page .notice .acf-notice-dismiss,
.acf-admin-page .notice .notice-dismiss,
.acf-admin-page #lost-connection-notice .acf-notice-dismiss,
.acf-admin-page #lost-connection-notice .notice-dismiss {
  position: absolute;
  top: 4px;
  right: 8px;
  padding: 9px;
  border: none;
}
.acf-admin-page .acf-notice .acf-notice-dismiss:before,
.acf-admin-page .acf-notice .notice-dismiss:before,
.acf-admin-page .notice .acf-notice-dismiss:before,
.acf-admin-page .notice .notice-dismiss:before,
.acf-admin-page #lost-connection-notice .acf-notice-dismiss:before,
.acf-admin-page #lost-connection-notice .notice-dismiss:before {
  content: "";
  display: block;
  position: relative;
  z-index: 600;
  width: 20px;
  height: 20px;
  background-color: #667085;
  border: none;
  border-radius: 0;
  -webkit-mask-size: contain;
  mask-size: contain;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  -webkit-mask-image: url("../../images/icons/icon-close.svg");
  mask-image: url("../../images/icons/icon-close.svg");
}
.acf-admin-page .acf-notice .acf-notice-dismiss:hover::before,
.acf-admin-page .acf-notice .notice-dismiss:hover::before,
.acf-admin-page .notice .acf-notice-dismiss:hover::before,
.acf-admin-page .notice .notice-dismiss:hover::before,
.acf-admin-page #lost-connection-notice .acf-notice-dismiss:hover::before,
.acf-admin-page #lost-connection-notice .notice-dismiss:hover::before {
  background-color: #344054;
}
.acf-admin-page .acf-notice a.acf-notice-dismiss,
.acf-admin-page .notice a.acf-notice-dismiss,
.acf-admin-page #lost-connection-notice a.acf-notice-dismiss {
  position: absolute;
  top: 5px;
  right: 24px;
}
.acf-admin-page .acf-notice a.acf-notice-dismiss:before,
.acf-admin-page .notice a.acf-notice-dismiss:before,
.acf-admin-page #lost-connection-notice a.acf-notice-dismiss:before {
  background-color: #475467;
}
.acf-admin-page .acf-notice:before,
.acf-admin-page .notice:before,
.acf-admin-page #lost-connection-notice:before {
  content: "";
  display: block;
  position: absolute;
  top: 15px;
  left: 18px;
  z-index: 600;
  width: 16px;
  height: 16px;
  margin-right: 8px;
  background-color: #fff;
  border: none;
  border-radius: 0;
  -webkit-mask-size: contain;
  mask-size: contain;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  -webkit-mask-image: url("../../images/icons/icon-info-solid.svg");
  mask-image: url("../../images/icons/icon-info-solid.svg");
}
.acf-admin-page .acf-notice:after,
.acf-admin-page .notice:after,
.acf-admin-page #lost-connection-notice:after {
  content: "";
  display: block;
  position: absolute;
  top: 9px;
  left: 12px;
  z-index: 500;
  width: 28px;
  height: 28px;
  background-color: #2D69DA;
  border-radius: 6px;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.1);
}
.acf-admin-page .acf-notice .local-restore,
.acf-admin-page .notice .local-restore,
.acf-admin-page #lost-connection-notice .local-restore {
  align-items: center;
  margin-top: -6px;
  margin-bottom: 0;
}
.acf-admin-page .notice[data-persisted=true] {
  display: none;
}
.acf-admin-page .notice.is-dismissible {
  padding-right: 56px;
}
.acf-admin-page .notice.notice-success {
  background-color: #edf7ef;
  border-color: #b6deb9;
}
.acf-admin-page .notice.notice-success:before {
  -webkit-mask-image: url("../../images/icons/icon-check-circle-solid.svg");
  mask-image: url("../../images/icons/icon-check-circle-solid.svg");
}
.acf-admin-page .notice.notice-success:after {
  background-color: #52AA59;
}
.acf-admin-page .acf-notice.acf-error-message,
.acf-admin-page .notice.notice-error,
.acf-admin-page #lost-connection-notice {
  background-color: #f7eeeb;
  border-color: #f1b6b3;
}
.acf-admin-page .acf-notice.acf-error-message:before,
.acf-admin-page .notice.notice-error:before,
.acf-admin-page #lost-connection-notice:before {
  -webkit-mask-image: url("../../images/icons/icon-warning.svg");
  mask-image: url("../../images/icons/icon-warning.svg");
}
.acf-admin-page .acf-notice.acf-error-message:after,
.acf-admin-page .notice.notice-error:after,
.acf-admin-page #lost-connection-notice:after {
  background-color: #D13737;
}

.acf-admin-single-taxonomy .notice-success .acf-item-saved-text,
.acf-admin-single-post-type .notice-success .acf-item-saved-text,
.acf-admin-single-options-page .notice-success .acf-item-saved-text {
  font-weight: 600;
}
.acf-admin-single-taxonomy .notice-success .acf-item-saved-links,
.acf-admin-single-post-type .notice-success .acf-item-saved-links,
.acf-admin-single-options-page .notice-success .acf-item-saved-links {
  display: flex;
  gap: 12px;
}
.acf-admin-single-taxonomy .notice-success .acf-item-saved-links a,
.acf-admin-single-post-type .notice-success .acf-item-saved-links a,
.acf-admin-single-options-page .notice-success .acf-item-saved-links a {
  text-decoration: none;
  opacity: 1;
}
.acf-admin-single-taxonomy .notice-success .acf-item-saved-links a:after,
.acf-admin-single-post-type .notice-success .acf-item-saved-links a:after,
.acf-admin-single-options-page .notice-success .acf-item-saved-links a:after {
  content: "";
  width: 1px;
  height: 13px;
  display: inline-flex;
  position: relative;
  top: 2px;
  left: 6px;
  background-color: #475467;
  opacity: 0.3;
}
.acf-admin-single-taxonomy .notice-success .acf-item-saved-links a:last-child:after,
.acf-admin-single-post-type .notice-success .acf-item-saved-links a:last-child:after,
.acf-admin-single-options-page .notice-success .acf-item-saved-links a:last-child:after {
  content: none;
}

.rtl.acf-field-group .notice,
.rtl.acf-internal-post-type .notice {
  padding-right: 50px !important;
}
.rtl.acf-field-group .notice .notice-dismiss,
.rtl.acf-internal-post-type .notice .notice-dismiss {
  left: 8px;
  right: unset;
}
.rtl.acf-field-group .notice:before,
.rtl.acf-internal-post-type .notice:before {
  left: unset;
  right: 10px;
}
.rtl.acf-field-group .notice:after,
.rtl.acf-internal-post-type .notice:after {
  left: unset;
  right: 12px;
}
.rtl.acf-field-group.acf-admin-single-taxonomy .notice-success .acf-item-saved-links a:after, .rtl.acf-field-group.acf-admin-single-post-type .notice-success .acf-item-saved-links a:after, .rtl.acf-field-group.acf-admin-single-options-page .notice-success .acf-item-saved-links a:after,
.rtl.acf-internal-post-type.acf-admin-single-taxonomy .notice-success .acf-item-saved-links a:after,
.rtl.acf-internal-post-type.acf-admin-single-post-type .notice-success .acf-item-saved-links a:after,
.rtl.acf-internal-post-type.acf-admin-single-options-page .notice-success .acf-item-saved-links a:after {
  left: unset;
  right: 6px;
}

/*--------------------------------------------------------------------------------------------
*
*  ACF PRO label
*
*--------------------------------------------------------------------------------------------*/
.acf-pro-label {
  display: inline-flex;
  align-items: center;
  min-height: 22px;
  padding-right: 8px;
  padding-left: 8px;
  background: linear-gradient(90.52deg, #3E8BFF 0.44%, #A45CFF 113.3%);
  box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.16);
  border: none;
  border-radius: 100px;
  font-size: 11px;
  text-transform: uppercase;
  text-decoration: none;
  color: #fff;
}

/*--------------------------------------------------------------------------------------------
*
*  Inline notice overrides
*
*--------------------------------------------------------------------------------------------*/
.acf-admin-page .acf-field .acf-notice {
  display: flex;
  align-items: center;
  min-height: 40px !important;
  margin-bottom: 6px !important;
  padding-top: 6px !important;
  padding-left: 40px !important;
  padding-bottom: 6px !important;
  margin: 0 0 15px;
  background: #edf2ff;
  color: #344054 !important;
  border-color: #2183b9;
  border-radius: 6px;
}
.acf-admin-page .acf-field .acf-notice:after {
  top: 8px;
  left: 8px;
  width: 22px;
  height: 22px;
}
.acf-admin-page .acf-field .acf-notice:before {
  top: 12px;
  left: 12px;
  width: 14px;
  height: 14px;
}
.acf-admin-page .acf-field .acf-notice.-error {
  background: #f7eeeb;
  border-color: #f1b6b3;
}
.acf-admin-page .acf-field .acf-notice.-success {
  background: #edf7ef;
  border-color: #b6deb9;
}
.acf-admin-page .acf-field .acf-notice.-warning {
  background: #fdf8eb;
  border-color: #f4dbb4;
}

/*---------------------------------------------------------------------------------------------
*
*  Global
*
*---------------------------------------------------------------------------------------------*/
.acf-admin-page #wpcontent {
  line-height: 140%;
}

/*---------------------------------------------------------------------------------------------
*
*  Links
*
*---------------------------------------------------------------------------------------------*/
.acf-admin-page a {
  color: #0783BE;
}

/*---------------------------------------------------------------------------------------------
*
*  Headings
*
*---------------------------------------------------------------------------------------------*/
.acf-h1, .acf-admin-page #tmpl-acf-field-group-pro-features h1,
.acf-admin-page #acf-field-group-pro-features h1, .acf-admin-page h1,
.acf-headerbar h1 {
  font-size: 21px;
  font-weight: 400;
}

.acf-h2, .acf-no-field-groups-wrapper .acf-no-field-groups-inner h2,
.acf-no-field-groups-wrapper .acf-no-taxonomies-inner h2,
.acf-no-field-groups-wrapper .acf-no-post-types-inner h2,
.acf-no-field-groups-wrapper .acf-no-options-pages-inner h2,
.acf-no-taxonomies-wrapper .acf-no-field-groups-inner h2,
.acf-no-taxonomies-wrapper .acf-no-taxonomies-inner h2,
.acf-no-taxonomies-wrapper .acf-no-post-types-inner h2,
.acf-no-taxonomies-wrapper .acf-no-options-pages-inner h2,
.acf-no-post-types-wrapper .acf-no-field-groups-inner h2,
.acf-no-post-types-wrapper .acf-no-taxonomies-inner h2,
.acf-no-post-types-wrapper .acf-no-post-types-inner h2,
.acf-no-post-types-wrapper .acf-no-options-pages-inner h2,
.acf-no-options-pages-wrapper .acf-no-field-groups-inner h2,
.acf-no-options-pages-wrapper .acf-no-taxonomies-inner h2,
.acf-no-options-pages-wrapper .acf-no-post-types-inner h2,
.acf-no-options-pages-wrapper .acf-no-options-pages-inner h2, .acf-page-title, .acf-admin-page h2,
.acf-headerbar h2 {
  font-size: 18px;
  font-weight: 400;
}

.acf-h3, .acf-admin-page h3,
.acf-headerbar h3, .acf-admin-page .postbox .postbox-header h2,
.acf-admin-page .postbox .postbox-header h3,
.acf-admin-page .postbox .title h2,
.acf-admin-page .postbox .title h3,
.acf-admin-page .acf-box .postbox-header h2,
.acf-admin-page .acf-box .postbox-header h3,
.acf-admin-page .acf-box .title h2,
.acf-admin-page .acf-box .title h3, .acf-postbox-header h2.acf-postbox-title, .acf-admin-page #poststuff .postbox-header h2,
.acf-admin-page #poststuff .postbox-header h3 {
  font-size: 16px;
  font-weight: 400;
}

/*---------------------------------------------------------------------------------------------
*
*  Paragraphs
*
*---------------------------------------------------------------------------------------------*/
.acf-admin-page .p1 {
  font-size: 15px;
}
.acf-admin-page .p2, .acf-admin-page .acf-no-field-groups-wrapper .acf-no-field-groups-inner p, .acf-no-field-groups-wrapper .acf-no-field-groups-inner .acf-admin-page p,
.acf-admin-page .acf-no-field-groups-wrapper .acf-no-taxonomies-inner p,
.acf-no-field-groups-wrapper .acf-no-taxonomies-inner .acf-admin-page p,
.acf-admin-page .acf-no-field-groups-wrapper .acf-no-post-types-inner p,
.acf-no-field-groups-wrapper .acf-no-post-types-inner .acf-admin-page p,
.acf-admin-page .acf-no-field-groups-wrapper .acf-no-options-pages-inner p,
.acf-no-field-groups-wrapper .acf-no-options-pages-inner .acf-admin-page p,
.acf-admin-page .acf-no-taxonomies-wrapper .acf-no-field-groups-inner p,
.acf-no-taxonomies-wrapper .acf-no-field-groups-inner .acf-admin-page p,
.acf-admin-page .acf-no-taxonomies-wrapper .acf-no-taxonomies-inner p,
.acf-no-taxonomies-wrapper .acf-no-taxonomies-inner .acf-admin-page p,
.acf-admin-page .acf-no-taxonomies-wrapper .acf-no-post-types-inner p,
.acf-no-taxonomies-wrapper .acf-no-post-types-inner .acf-admin-page p,
.acf-admin-page .acf-no-taxonomies-wrapper .acf-no-options-pages-inner p,
.acf-no-taxonomies-wrapper .acf-no-options-pages-inner .acf-admin-page p,
.acf-admin-page .acf-no-post-types-wrapper .acf-no-field-groups-inner p,
.acf-no-post-types-wrapper .acf-no-field-groups-inner .acf-admin-page p,
.acf-admin-page .acf-no-post-types-wrapper .acf-no-taxonomies-inner p,
.acf-no-post-types-wrapper .acf-no-taxonomies-inner .acf-admin-page p,
.acf-admin-page .acf-no-post-types-wrapper .acf-no-post-types-inner p,
.acf-no-post-types-wrapper .acf-no-post-types-inner .acf-admin-page p,
.acf-admin-page .acf-no-post-types-wrapper .acf-no-options-pages-inner p,
.acf-no-post-types-wrapper .acf-no-options-pages-inner .acf-admin-page p,
.acf-admin-page .acf-no-options-pages-wrapper .acf-no-field-groups-inner p,
.acf-no-options-pages-wrapper .acf-no-field-groups-inner .acf-admin-page p,
.acf-admin-page .acf-no-options-pages-wrapper .acf-no-taxonomies-inner p,
.acf-no-options-pages-wrapper .acf-no-taxonomies-inner .acf-admin-page p,
.acf-admin-page .acf-no-options-pages-wrapper .acf-no-post-types-inner p,
.acf-no-options-pages-wrapper .acf-no-post-types-inner .acf-admin-page p,
.acf-admin-page .acf-no-options-pages-wrapper .acf-no-options-pages-inner p,
.acf-no-options-pages-wrapper .acf-no-options-pages-inner .acf-admin-page p, .acf-admin-page #acf-admin-tools .acf-meta-box-wrap .acf-fields .acf-label, #acf-admin-tools .acf-meta-box-wrap .acf-fields .acf-admin-page .acf-label {
  font-size: 14px;
}
.acf-admin-page .p3, .acf-admin-page .acf-internal-post-type .wp-list-table .post-state, .acf-internal-post-type .wp-list-table .acf-admin-page .post-state, .acf-admin-page .subtitle {
  font-size: 13.5px;
}
.acf-admin-page .p4, .acf-admin-page .acf-admin-toolbar a.acf-admin-toolbar-upgrade-btn p, .acf-admin-toolbar a.acf-admin-toolbar-upgrade-btn .acf-admin-page p, .acf-admin-page #acf-update-information .form-table th, #acf-update-information .form-table .acf-admin-page th,
.acf-admin-page #acf-update-information .form-table td,
#acf-update-information .form-table .acf-admin-page td, .acf-admin-page #acf-admin-tools.tool-export .acf-panel h3, #acf-admin-tools.tool-export .acf-panel .acf-admin-page h3, .acf-admin-page .acf-btn.acf-btn-sm, .acf-admin-page .acf-admin-toolbar .acf-tab, .acf-admin-toolbar .acf-admin-page .acf-tab, .acf-admin-page .acf-internal-post-type .subsubsub li, .acf-internal-post-type .subsubsub .acf-admin-page li, .acf-admin-page .acf-internal-post-type .wp-list-table tbody th, .acf-internal-post-type .wp-list-table tbody .acf-admin-page th,
.acf-admin-page .acf-internal-post-type .wp-list-table tbody td,
.acf-internal-post-type .wp-list-table tbody .acf-admin-page td, .acf-admin-page .acf-internal-post-type .wp-list-table thead th, .acf-internal-post-type .wp-list-table thead .acf-admin-page th, .acf-admin-page .acf-internal-post-type .wp-list-table thead td, .acf-internal-post-type .wp-list-table thead .acf-admin-page td,
.acf-admin-page .acf-internal-post-type .wp-list-table tfoot th,
.acf-internal-post-type .wp-list-table tfoot .acf-admin-page th, .acf-admin-page .acf-internal-post-type .wp-list-table tfoot td, .acf-internal-post-type .wp-list-table tfoot .acf-admin-page td, .acf-admin-page .acf-input .select2-container.-acf .select2-selection__rendered, .acf-admin-page .button, .acf-admin-page input[type=text],
.acf-admin-page input[type=search],
.acf-admin-page input[type=number],
.acf-admin-page textarea,
.acf-admin-page select {
  font-size: 13px;
}
.acf-admin-page .p5, .acf-admin-page .acf-modal.acf-browse-fields-modal .acf-field-picker .acf-modal-content .acf-field-types-tab .acf-field-type .field-type-label, .acf-modal.acf-browse-fields-modal .acf-field-picker .acf-modal-content .acf-field-types-tab .acf-field-type .acf-admin-page .field-type-label,
.acf-admin-page .acf-modal.acf-browse-fields-modal .acf-field-picker .acf-modal-content .acf-field-type-search-results .acf-field-type .field-type-label,
.acf-modal.acf-browse-fields-modal .acf-field-picker .acf-modal-content .acf-field-type-search-results .acf-field-type .acf-admin-page .field-type-label, .acf-admin-page .acf-internal-post-type .row-actions, .acf-internal-post-type .acf-admin-page .row-actions, .acf-admin-page .acf-notice .button,
.acf-admin-page .notice .button,
.acf-admin-page #lost-connection-notice .button {
  font-size: 12.5px;
}
.acf-admin-page .p6, .acf-admin-page #acf-update-information .acf-update-changelog p em, #acf-update-information .acf-update-changelog p .acf-admin-page em, .acf-admin-page .acf-no-field-groups-wrapper .acf-no-field-groups-inner p.acf-small, .acf-no-field-groups-wrapper .acf-no-field-groups-inner .acf-admin-page p.acf-small,
.acf-admin-page .acf-no-field-groups-wrapper .acf-no-taxonomies-inner p.acf-small,
.acf-no-field-groups-wrapper .acf-no-taxonomies-inner .acf-admin-page p.acf-small,
.acf-admin-page .acf-no-field-groups-wrapper .acf-no-post-types-inner p.acf-small,
.acf-no-field-groups-wrapper .acf-no-post-types-inner .acf-admin-page p.acf-small,
.acf-admin-page .acf-no-field-groups-wrapper .acf-no-options-pages-inner p.acf-small,
.acf-no-field-groups-wrapper .acf-no-options-pages-inner .acf-admin-page p.acf-small,
.acf-admin-page .acf-no-taxonomies-wrapper .acf-no-field-groups-inner p.acf-small,
.acf-no-taxonomies-wrapper .acf-no-field-groups-inner .acf-admin-page p.acf-small,
.acf-admin-page .acf-no-taxonomies-wrapper .acf-no-taxonomies-inner p.acf-small,
.acf-no-taxonomies-wrapper .acf-no-taxonomies-inner .acf-admin-page p.acf-small,
.acf-admin-page .acf-no-taxonomies-wrapper .acf-no-post-types-inner p.acf-small,
.acf-no-taxonomies-wrapper .acf-no-post-types-inner .acf-admin-page p.acf-small,
.acf-admin-page .acf-no-taxonomies-wrapper .acf-no-options-pages-inner p.acf-small,
.acf-no-taxonomies-wrapper .acf-no-options-pages-inner .acf-admin-page p.acf-small,
.acf-admin-page .acf-no-post-types-wrapper .acf-no-field-groups-inner p.acf-small,
.acf-no-post-types-wrapper .acf-no-field-groups-inner .acf-admin-page p.acf-small,
.acf-admin-page .acf-no-post-types-wrapper .acf-no-taxonomies-inner p.acf-small,
.acf-no-post-types-wrapper .acf-no-taxonomies-inner .acf-admin-page p.acf-small,
.acf-admin-page .acf-no-post-types-wrapper .acf-no-post-types-inner p.acf-small,
.acf-no-post-types-wrapper .acf-no-post-types-inner .acf-admin-page p.acf-small,
.acf-admin-page .acf-no-post-types-wrapper .acf-no-options-pages-inner p.acf-small,
.acf-no-post-types-wrapper .acf-no-options-pages-inner .acf-admin-page p.acf-small,
.acf-admin-page .acf-no-options-pages-wrapper .acf-no-field-groups-inner p.acf-small,
.acf-no-options-pages-wrapper .acf-no-field-groups-inner .acf-admin-page p.acf-small,
.acf-admin-page .acf-no-options-pages-wrapper .acf-no-taxonomies-inner p.acf-small,
.acf-no-options-pages-wrapper .acf-no-taxonomies-inner .acf-admin-page p.acf-small,
.acf-admin-page .acf-no-options-pages-wrapper .acf-no-post-types-inner p.acf-small,
.acf-no-options-pages-wrapper .acf-no-post-types-inner .acf-admin-page p.acf-small,
.acf-admin-page .acf-no-options-pages-wrapper .acf-no-options-pages-inner p.acf-small,
.acf-no-options-pages-wrapper .acf-no-options-pages-inner .acf-admin-page p.acf-small, .acf-admin-page .acf-internal-post-type .row-actions, .acf-internal-post-type .acf-admin-page .row-actions, .acf-admin-page .acf-small {
  font-size: 12px;
}
.acf-admin-page .p7, .acf-admin-page .acf-tooltip, .acf-admin-page .acf-notice p.help,
.acf-admin-page .notice p.help,
.acf-admin-page #lost-connection-notice p.help {
  font-size: 11.5px;
}
.acf-admin-page .p8 {
  font-size: 11px;
}

/*---------------------------------------------------------------------------------------------
*
*  Page titles
*
*---------------------------------------------------------------------------------------------*/
.acf-page-title {
  color: #344054;
}

/*---------------------------------------------------------------------------------------------
*
*  Hide old / native WP titles from pages
*
*---------------------------------------------------------------------------------------------*/
.acf-admin-page .acf-settings-wrap h1 {
  display: none !important;
}
.acf-admin-page #acf-admin-tools h1:not(.acf-field-group-pro-features-title, .acf-field-group-pro-features-title-sm) {
  display: none !important;
}

/*---------------------------------------------------------------------------------------------
*
*  Small
*
*---------------------------------------------------------------------------------------------*/
/*---------------------------------------------------------------------------------------------
*
*  Link focus style
*
*---------------------------------------------------------------------------------------------*/
.acf-admin-page a:focus {
  box-shadow: none;
  outline: none;
}
.acf-admin-page a:focus-visible {
  box-shadow: 0 0 0 1px #4f94d4, 0 0 2px 1px rgba(79, 148, 212, 0.8);
  outline: 1px solid transparent;
}

.acf-admin-page {
  /*---------------------------------------------------------------------------------------------
  *
  *  All Inputs
  *
  *---------------------------------------------------------------------------------------------*/
  /*---------------------------------------------------------------------------------------------
  *
  *  Read only text inputs
  *
  *---------------------------------------------------------------------------------------------*/
  /*---------------------------------------------------------------------------------------------
  *
  *  Number fields
  *
  *---------------------------------------------------------------------------------------------*/
  /*---------------------------------------------------------------------------------------------
  *
  *  Textarea
  *
  *---------------------------------------------------------------------------------------------*/
  /*---------------------------------------------------------------------------------------------
  *
  *  Select
  *
  *---------------------------------------------------------------------------------------------*/
  /*---------------------------------------------------------------------------------------------
  *
  *  Radio Button & Checkbox base styling
  *
  *---------------------------------------------------------------------------------------------*/
  /*---------------------------------------------------------------------------------------------
  *
  *  Radio Buttons
  *
  *---------------------------------------------------------------------------------------------*/
  /*---------------------------------------------------------------------------------------------
  *
  *  Checkboxes
  *
  *---------------------------------------------------------------------------------------------*/
  /*---------------------------------------------------------------------------------------------
  *
  *  Radio Buttons & Checkbox lists
  *
  *---------------------------------------------------------------------------------------------*/
  /*---------------------------------------------------------------------------------------------
  *
  *  ACF Switch
  *
  *---------------------------------------------------------------------------------------------*/
  /*---------------------------------------------------------------------------------------------
  *
  *  File input button
  *
  *---------------------------------------------------------------------------------------------*/
  /*---------------------------------------------------------------------------------------------
  *
  *  Action Buttons
  *
  *---------------------------------------------------------------------------------------------*/
  /*---------------------------------------------------------------------------------------------
  *
  *  Edit field group header
  *
  *---------------------------------------------------------------------------------------------*/
  /*---------------------------------------------------------------------------------------------
  *
  *  Select2 inputs
  *
  *---------------------------------------------------------------------------------------------*/
  /*---------------------------------------------------------------------------------------------
  *
  *  ACF label
  *
  *---------------------------------------------------------------------------------------------*/
  /*---------------------------------------------------------------------------------------------
  *
  *  Tooltip for field name field setting (result of a fix for keyboard navigation)
  *
  *---------------------------------------------------------------------------------------------*/
  /* Field Type Selection select2 */
  /*---------------------------------------------------------------------------------------------
  *
  *  RTL arrow position
  *
  *---------------------------------------------------------------------------------------------*/
}
.acf-admin-page input[type=text],
.acf-admin-page input[type=search],
.acf-admin-page input[type=number],
.acf-admin-page textarea,
.acf-admin-page select {
  box-sizing: border-box;
  height: 40px;
  padding-right: 12px;
  padding-left: 12px;
  background-color: #fff;
  border-color: #D0D5DD;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.1);
  border-radius: 6px;
  color: #344054;
}
.acf-admin-page input[type=text]:focus,
.acf-admin-page input[type=search]:focus,
.acf-admin-page input[type=number]:focus,
.acf-admin-page textarea:focus,
.acf-admin-page select:focus {
  outline: 3px solid #EBF5FA;
  border-color: #399CCB;
}
.acf-admin-page input[type=text]:disabled,
.acf-admin-page input[type=search]:disabled,
.acf-admin-page input[type=number]:disabled,
.acf-admin-page textarea:disabled,
.acf-admin-page select:disabled {
  background-color: #F9FAFB;
  color: #808a9e;
}
.acf-admin-page input[type=text]::placeholder,
.acf-admin-page input[type=search]::placeholder,
.acf-admin-page input[type=number]::placeholder,
.acf-admin-page textarea::placeholder,
.acf-admin-page select::placeholder {
  color: #98A2B3;
}
.acf-admin-page input[type=text]:read-only {
  background-color: #F9FAFB;
  color: #98A2B3;
}
.acf-admin-page .acf-field.acf-field-number .acf-label,
.acf-admin-page .acf-field.acf-field-number .acf-input input[type=number] {
  max-width: 180px;
}
.acf-admin-page textarea {
  box-sizing: border-box;
  padding-top: 10px;
  padding-bottom: 10px;
  height: 80px;
  min-height: 56px;
}
.acf-admin-page select {
  min-width: 160px;
  max-width: 100%;
  padding-right: 40px;
  padding-left: 12px;
  background-image: url("../../images/icons/icon-chevron-down.svg");
  background-position: right 10px top 50%;
  background-size: 20px;
}
.acf-admin-page select:hover, .acf-admin-page select:focus {
  color: #0783BE;
}
.acf-admin-page select::before {
  content: "";
  display: block;
  position: absolute;
  top: 5px;
  left: 5px;
  width: 20px;
  height: 20px;
}
.acf-admin-page.rtl select {
  padding-right: 12px;
  padding-left: 40px;
  background-position: left 10px top 50%;
}
.acf-admin-page input[type=radio],
.acf-admin-page input[type=checkbox] {
  box-sizing: border-box;
  width: 16px;
  height: 16px;
  padding: 0;
  border-width: 1px;
  border-style: solid;
  border-color: #98A2B3;
  background: #fff;
  box-shadow: none;
}
.acf-admin-page input[type=radio]:hover,
.acf-admin-page input[type=checkbox]:hover {
  background-color: #EBF5FA;
  border-color: #0783BE;
}
.acf-admin-page input[type=radio]:checked, .acf-admin-page input[type=radio]:focus-visible,
.acf-admin-page input[type=checkbox]:checked,
.acf-admin-page input[type=checkbox]:focus-visible {
  background-color: #EBF5FA;
  border-color: #0783BE;
}
.acf-admin-page input[type=radio]:checked:before, .acf-admin-page input[type=radio]:focus-visible:before,
.acf-admin-page input[type=checkbox]:checked:before,
.acf-admin-page input[type=checkbox]:focus-visible:before {
  content: "";
  position: relative;
  top: -1px;
  left: -1px;
  width: 16px;
  height: 16px;
  margin: 0;
  padding: 0;
  background-color: transparent;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.acf-admin-page input[type=radio]:active,
.acf-admin-page input[type=checkbox]:active {
  box-shadow: 0px 0px 0px 3px #EBF5FA, 0px 0px 0px rgba(255, 54, 54, 0.25);
}
.acf-admin-page input[type=radio]:disabled,
.acf-admin-page input[type=checkbox]:disabled {
  background-color: #F9FAFB;
  border-color: #D0D5DD;
}
.acf-admin-page.rtl input[type=radio]:checked:before, .acf-admin-page.rtl input[type=radio]:focus-visible:before,
.acf-admin-page.rtl input[type=checkbox]:checked:before,
.acf-admin-page.rtl input[type=checkbox]:focus-visible:before {
  left: 1px;
}
.acf-admin-page input[type=radio]:checked:before, .acf-admin-page input[type=radio]:focus:before {
  background-image: url("../../images/field-states/radio-active.svg");
}
.acf-admin-page input[type=checkbox]:checked:before, .acf-admin-page input[type=checkbox]:focus:before {
  background-image: url("../../images/field-states/checkbox-active.svg");
}
.acf-admin-page .acf-radio-list li input[type=radio],
.acf-admin-page .acf-radio-list li input[type=checkbox],
.acf-admin-page .acf-checkbox-list li input[type=radio],
.acf-admin-page .acf-checkbox-list li input[type=checkbox] {
  margin-right: 6px;
}
.acf-admin-page .acf-radio-list.acf-bl li,
.acf-admin-page .acf-checkbox-list.acf-bl li {
  margin-bottom: 8px;
}
.acf-admin-page .acf-radio-list.acf-bl li:last-of-type,
.acf-admin-page .acf-checkbox-list.acf-bl li:last-of-type {
  margin-bottom: 0;
}
.acf-admin-page .acf-radio-list label,
.acf-admin-page .acf-checkbox-list label {
  display: flex;
  align-items: center;
  align-content: center;
}
.acf-admin-page .acf-switch {
  width: 42px;
  height: 24px;
  border: none;
  background-color: #D0D5DD;
  border-radius: 12px;
}
.acf-admin-page .acf-switch:hover {
  background-color: #98A2B3;
}
.acf-admin-page .acf-switch:active {
  box-shadow: 0px 0px 0px 3px #EBF5FA, 0px 0px 0px rgba(255, 54, 54, 0.25);
}
.acf-admin-page .acf-switch.-on {
  background-color: #0783BE;
}
.acf-admin-page .acf-switch.-on:hover {
  background-color: #066998;
}
.acf-admin-page .acf-switch.-on .acf-switch-slider {
  left: 20px;
}
.acf-admin-page .acf-switch .acf-switch-off,
.acf-admin-page .acf-switch .acf-switch-on {
  visibility: hidden;
}
.acf-admin-page .acf-switch .acf-switch-slider {
  width: 20px;
  height: 20px;
  border: none;
  border-radius: 100px;
  box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06);
}
.acf-admin-page .acf-field-true-false {
  display: flex;
  align-items: flex-start;
}
.acf-admin-page .acf-field-true-false .acf-label {
  order: 2;
  display: block;
  align-items: center;
  margin-top: 2px;
  margin-bottom: 0;
  margin-left: 12px;
}
.acf-admin-page .acf-field-true-false .acf-label label {
  margin-bottom: 0;
}
.acf-admin-page .acf-field-true-false .acf-label .acf-tip {
  margin-left: 12px;
}
.acf-admin-page .acf-field-true-false .acf-label .description {
  display: block;
  margin-top: 2px;
  margin-left: 0;
}
.acf-admin-page.rtl .acf-field-true-false .acf-label {
  margin-right: 12px;
  margin-left: 0;
}
.acf-admin-page.rtl .acf-field-true-false .acf-tip {
  margin-right: 12px;
  margin-left: 0;
}
.acf-admin-page input::file-selector-button {
  box-sizing: border-box;
  min-height: 40px;
  margin-right: 16px;
  padding-top: 8px;
  padding-right: 16px;
  padding-bottom: 8px;
  padding-left: 16px;
  background-color: transparent;
  color: #0783BE !important;
  border-radius: 6px;
  border-width: 1px;
  border-style: solid;
  border-color: #0783BE;
  text-decoration: none;
}
.acf-admin-page input::file-selector-button:hover {
  border-color: #066998;
  cursor: pointer;
  color: #066998 !important;
}
.acf-admin-page .button {
  display: inline-flex;
  align-items: center;
  height: 40px;
  padding-right: 16px;
  padding-left: 16px;
  background-color: transparent;
  border-width: 1px;
  border-style: solid;
  border-color: #0783BE;
  border-radius: 6px;
  color: #0783BE;
}
.acf-admin-page .button:hover {
  background-color: #f3f9fc;
  border-color: #0783BE;
  color: #0783BE;
}
.acf-admin-page .button:focus {
  background-color: #f3f9fc;
  outline: 3px solid #EBF5FA;
  color: #0783BE;
}
.acf-admin-page .edit-field-group-header {
  display: block !important;
}
.acf-admin-page .acf-input .select2-container.-acf .select2-selection {
  border: none;
  line-height: 1;
}
.acf-admin-page .acf-input .select2-container.-acf .select2-selection__rendered {
  box-sizing: border-box;
  padding-right: 0;
  padding-left: 0;
  background-color: #fff;
  border-width: 1px;
  border-style: solid;
  border-color: #D0D5DD;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.1);
  border-radius: 6px;
  color: #344054;
}
.acf-admin-page .acf-input .select2-container--focus {
  outline: 3px solid #EBF5FA;
  border-color: #399CCB;
  border-radius: 6px;
}
.acf-admin-page .acf-input .select2-container--focus .select2-selection__rendered {
  border-color: #399CCB !important;
}
.acf-admin-page .acf-input .select2-container--focus.select2-container--below.select2-container--open .select2-selection__rendered {
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}
.acf-admin-page .acf-input .select2-container--focus.select2-container--above.select2-container--open .select2-selection__rendered {
  border-top-right-radius: 0 !important;
  border-top-left-radius: 0 !important;
}
.acf-admin-page .acf-input .select2-container .select2-search--inline .select2-search__field {
  margin: 0;
  padding-left: 6px;
}
.acf-admin-page .acf-input .select2-container .select2-search--inline .select2-search__field:focus {
  outline: none;
  border: none;
}
.acf-admin-page .acf-input .select2-container--default .select2-selection--multiple .select2-selection__rendered {
  padding-top: 0;
  padding-right: 6px;
  padding-bottom: 0;
  padding-left: 6px;
}
.acf-admin-page .acf-input .select2-selection__clear {
  width: 18px;
  height: 18px;
  margin-top: 12px;
  margin-right: 1px;
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
  color: #fff;
}
.acf-admin-page .acf-input .select2-selection__clear:before {
  content: "";
  display: block;
  width: 16px;
  height: 16px;
  top: 0;
  left: 0;
  border: none;
  border-radius: 0;
  -webkit-mask-size: contain;
  mask-size: contain;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  -webkit-mask-image: url("../../images/icons/icon-close.svg");
  mask-image: url("../../images/icons/icon-close.svg");
  background-color: #98A2B3;
}
.acf-admin-page .acf-input .select2-selection__clear:hover::before {
  background-color: #0783BE;
}
.acf-admin-page .acf-label {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.acf-admin-page .acf-label .acf-icon-help {
  width: 18px;
  height: 18px;
  background-color: #98A2B3;
}
.acf-admin-page .acf-label label {
  margin-bottom: 0;
}
.acf-admin-page .acf-label .description {
  margin-top: 2px;
}
.acf-admin-page .acf-field-setting-name .acf-tip {
  position: absolute;
  top: 0;
  left: 654px;
  color: #98A2B3;
}
.rtl.acf-admin-page .acf-field-setting-name .acf-tip {
  left: auto;
  right: 654px;
}

.acf-admin-page .acf-field-setting-name .acf-tip .acf-icon-help {
  width: 18px;
  height: 18px;
}
.acf-admin-page .acf-field-setting-type .select2-container.-acf,
.acf-admin-page .acf-field-permalink-rewrite .select2-container.-acf,
.acf-admin-page .acf-field-query-var .select2-container.-acf,
.acf-admin-page .acf-field-capability .select2-container.-acf,
.acf-admin-page .acf-field-parent-slug .select2-container.-acf,
.acf-admin-page .acf-field-data-storage .select2-container.-acf,
.acf-admin-page .acf-field-manage-terms .select2-container.-acf,
.acf-admin-page .acf-field-edit-terms .select2-container.-acf,
.acf-admin-page .acf-field-delete-terms .select2-container.-acf,
.acf-admin-page .acf-field-assign-terms .select2-container.-acf,
.acf-admin-page .acf-field-meta-box .select2-container.-acf {
  min-height: 40px;
}
.acf-admin-page .acf-field-setting-type .select2-container--default .select2-selection--single .select2-selection__rendered,
.acf-admin-page .acf-field-permalink-rewrite .select2-container--default .select2-selection--single .select2-selection__rendered,
.acf-admin-page .acf-field-query-var .select2-container--default .select2-selection--single .select2-selection__rendered,
.acf-admin-page .acf-field-capability .select2-container--default .select2-selection--single .select2-selection__rendered,
.acf-admin-page .acf-field-parent-slug .select2-container--default .select2-selection--single .select2-selection__rendered,
.acf-admin-page .acf-field-data-storage .select2-container--default .select2-selection--single .select2-selection__rendered,
.acf-admin-page .acf-field-manage-terms .select2-container--default .select2-selection--single .select2-selection__rendered,
.acf-admin-page .acf-field-edit-terms .select2-container--default .select2-selection--single .select2-selection__rendered,
.acf-admin-page .acf-field-delete-terms .select2-container--default .select2-selection--single .select2-selection__rendered,
.acf-admin-page .acf-field-assign-terms .select2-container--default .select2-selection--single .select2-selection__rendered,
.acf-admin-page .acf-field-meta-box .select2-container--default .select2-selection--single .select2-selection__rendered {
  display: flex;
  align-items: center;
  position: relative;
  z-index: 800;
  min-height: 40px;
  padding-top: 0;
  padding-right: 12px;
  padding-bottom: 0;
  padding-left: 12px;
}
.acf-admin-page .acf-field-setting-type .select2-container--default .select2-selection--single .field-type-icon,
.acf-admin-page .acf-field-permalink-rewrite .select2-container--default .select2-selection--single .field-type-icon,
.acf-admin-page .acf-field-query-var .select2-container--default .select2-selection--single .field-type-icon,
.acf-admin-page .acf-field-capability .select2-container--default .select2-selection--single .field-type-icon,
.acf-admin-page .acf-field-parent-slug .select2-container--default .select2-selection--single .field-type-icon,
.acf-admin-page .acf-field-data-storage .select2-container--default .select2-selection--single .field-type-icon,
.acf-admin-page .acf-field-manage-terms .select2-container--default .select2-selection--single .field-type-icon,
.acf-admin-page .acf-field-edit-terms .select2-container--default .select2-selection--single .field-type-icon,
.acf-admin-page .acf-field-delete-terms .select2-container--default .select2-selection--single .field-type-icon,
.acf-admin-page .acf-field-assign-terms .select2-container--default .select2-selection--single .field-type-icon,
.acf-admin-page .acf-field-meta-box .select2-container--default .select2-selection--single .field-type-icon {
  top: auto;
  width: 18px;
  height: 18px;
  margin-right: 2px;
}
.acf-admin-page .acf-field-setting-type .select2-container--default .select2-selection--single .field-type-icon:before,
.acf-admin-page .acf-field-permalink-rewrite .select2-container--default .select2-selection--single .field-type-icon:before,
.acf-admin-page .acf-field-query-var .select2-container--default .select2-selection--single .field-type-icon:before,
.acf-admin-page .acf-field-capability .select2-container--default .select2-selection--single .field-type-icon:before,
.acf-admin-page .acf-field-parent-slug .select2-container--default .select2-selection--single .field-type-icon:before,
.acf-admin-page .acf-field-data-storage .select2-container--default .select2-selection--single .field-type-icon:before,
.acf-admin-page .acf-field-manage-terms .select2-container--default .select2-selection--single .field-type-icon:before,
.acf-admin-page .acf-field-edit-terms .select2-container--default .select2-selection--single .field-type-icon:before,
.acf-admin-page .acf-field-delete-terms .select2-container--default .select2-selection--single .field-type-icon:before,
.acf-admin-page .acf-field-assign-terms .select2-container--default .select2-selection--single .field-type-icon:before,
.acf-admin-page .acf-field-meta-box .select2-container--default .select2-selection--single .field-type-icon:before {
  width: 9px;
  height: 9px;
}
.acf-admin-page .acf-field-setting-type .select2-container--open .select2-selection__rendered,
.acf-admin-page .acf-field-permalink-rewrite .select2-container--open .select2-selection__rendered,
.acf-admin-page .acf-field-query-var .select2-container--open .select2-selection__rendered,
.acf-admin-page .acf-field-capability .select2-container--open .select2-selection__rendered,
.acf-admin-page .acf-field-parent-slug .select2-container--open .select2-selection__rendered,
.acf-admin-page .acf-field-data-storage .select2-container--open .select2-selection__rendered,
.acf-admin-page .acf-field-manage-terms .select2-container--open .select2-selection__rendered,
.acf-admin-page .acf-field-edit-terms .select2-container--open .select2-selection__rendered,
.acf-admin-page .acf-field-delete-terms .select2-container--open .select2-selection__rendered,
.acf-admin-page .acf-field-assign-terms .select2-container--open .select2-selection__rendered,
.acf-admin-page .acf-field-meta-box .select2-container--open .select2-selection__rendered {
  border-color: #6BB5D8 !important;
  border-bottom-color: #D0D5DD !important;
}
.acf-admin-page .acf-field-setting-type .select2-container--open.select2-container--below .select2-selection__rendered,
.acf-admin-page .acf-field-permalink-rewrite .select2-container--open.select2-container--below .select2-selection__rendered,
.acf-admin-page .acf-field-query-var .select2-container--open.select2-container--below .select2-selection__rendered,
.acf-admin-page .acf-field-capability .select2-container--open.select2-container--below .select2-selection__rendered,
.acf-admin-page .acf-field-parent-slug .select2-container--open.select2-container--below .select2-selection__rendered,
.acf-admin-page .acf-field-data-storage .select2-container--open.select2-container--below .select2-selection__rendered,
.acf-admin-page .acf-field-manage-terms .select2-container--open.select2-container--below .select2-selection__rendered,
.acf-admin-page .acf-field-edit-terms .select2-container--open.select2-container--below .select2-selection__rendered,
.acf-admin-page .acf-field-delete-terms .select2-container--open.select2-container--below .select2-selection__rendered,
.acf-admin-page .acf-field-assign-terms .select2-container--open.select2-container--below .select2-selection__rendered,
.acf-admin-page .acf-field-meta-box .select2-container--open.select2-container--below .select2-selection__rendered {
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}
.acf-admin-page .acf-field-setting-type .select2-container--open.select2-container--above .select2-selection__rendered,
.acf-admin-page .acf-field-permalink-rewrite .select2-container--open.select2-container--above .select2-selection__rendered,
.acf-admin-page .acf-field-query-var .select2-container--open.select2-container--above .select2-selection__rendered,
.acf-admin-page .acf-field-capability .select2-container--open.select2-container--above .select2-selection__rendered,
.acf-admin-page .acf-field-parent-slug .select2-container--open.select2-container--above .select2-selection__rendered,
.acf-admin-page .acf-field-data-storage .select2-container--open.select2-container--above .select2-selection__rendered,
.acf-admin-page .acf-field-manage-terms .select2-container--open.select2-container--above .select2-selection__rendered,
.acf-admin-page .acf-field-edit-terms .select2-container--open.select2-container--above .select2-selection__rendered,
.acf-admin-page .acf-field-delete-terms .select2-container--open.select2-container--above .select2-selection__rendered,
.acf-admin-page .acf-field-assign-terms .select2-container--open.select2-container--above .select2-selection__rendered,
.acf-admin-page .acf-field-meta-box .select2-container--open.select2-container--above .select2-selection__rendered {
  border-top-right-radius: 0 !important;
  border-top-left-radius: 0 !important;
  border-bottom-color: #6BB5D8 !important;
  border-top-color: #D0D5DD !important;
}
.acf-admin-page .acf-field-setting-type .acf-selection.has-icon,
.acf-admin-page .acf-field-permalink-rewrite .acf-selection.has-icon,
.acf-admin-page .acf-field-query-var .acf-selection.has-icon,
.acf-admin-page .acf-field-capability .acf-selection.has-icon,
.acf-admin-page .acf-field-parent-slug .acf-selection.has-icon,
.acf-admin-page .acf-field-data-storage .acf-selection.has-icon,
.acf-admin-page .acf-field-manage-terms .acf-selection.has-icon,
.acf-admin-page .acf-field-edit-terms .acf-selection.has-icon,
.acf-admin-page .acf-field-delete-terms .acf-selection.has-icon,
.acf-admin-page .acf-field-assign-terms .acf-selection.has-icon,
.acf-admin-page .acf-field-meta-box .acf-selection.has-icon {
  margin-left: 6px;
}
.rtl.acf-admin-page .acf-field-setting-type .acf-selection.has-icon, .acf-admin-page .acf-field-permalink-rewrite .acf-selection.has-icon, .acf-admin-page .acf-field-query-var .acf-selection.has-icon, .acf-admin-page .acf-field-capability .acf-selection.has-icon, .acf-admin-page .acf-field-parent-slug .acf-selection.has-icon, .acf-admin-page .acf-field-data-storage .acf-selection.has-icon, .acf-admin-page .acf-field-manage-terms .acf-selection.has-icon, .acf-admin-page .acf-field-edit-terms .acf-selection.has-icon, .acf-admin-page .acf-field-delete-terms .acf-selection.has-icon, .acf-admin-page .acf-field-assign-terms .acf-selection.has-icon, .acf-admin-page .acf-field-meta-box .acf-selection.has-icon {
  margin-right: 6px;
}

.acf-admin-page .acf-field-setting-type .select2-selection__arrow,
.acf-admin-page .acf-field-permalink-rewrite .select2-selection__arrow,
.acf-admin-page .acf-field-query-var .select2-selection__arrow,
.acf-admin-page .acf-field-capability .select2-selection__arrow,
.acf-admin-page .acf-field-parent-slug .select2-selection__arrow,
.acf-admin-page .acf-field-data-storage .select2-selection__arrow,
.acf-admin-page .acf-field-manage-terms .select2-selection__arrow,
.acf-admin-page .acf-field-edit-terms .select2-selection__arrow,
.acf-admin-page .acf-field-delete-terms .select2-selection__arrow,
.acf-admin-page .acf-field-assign-terms .select2-selection__arrow,
.acf-admin-page .acf-field-meta-box .select2-selection__arrow {
  width: 20px;
  height: 20px;
  top: calc(50% - 10px);
  right: 12px;
  background-color: transparent;
}
.acf-admin-page .acf-field-setting-type .select2-selection__arrow:after,
.acf-admin-page .acf-field-permalink-rewrite .select2-selection__arrow:after,
.acf-admin-page .acf-field-query-var .select2-selection__arrow:after,
.acf-admin-page .acf-field-capability .select2-selection__arrow:after,
.acf-admin-page .acf-field-parent-slug .select2-selection__arrow:after,
.acf-admin-page .acf-field-data-storage .select2-selection__arrow:after,
.acf-admin-page .acf-field-manage-terms .select2-selection__arrow:after,
.acf-admin-page .acf-field-edit-terms .select2-selection__arrow:after,
.acf-admin-page .acf-field-delete-terms .select2-selection__arrow:after,
.acf-admin-page .acf-field-assign-terms .select2-selection__arrow:after,
.acf-admin-page .acf-field-meta-box .select2-selection__arrow:after {
  content: "";
  display: block;
  position: absolute;
  z-index: 850;
  top: 1px;
  left: 0;
  width: 20px;
  height: 20px;
  -webkit-mask-image: url("../../images/icons/icon-chevron-down.svg");
  mask-image: url("../../images/icons/icon-chevron-down.svg");
  background-color: #667085;
  border: none;
  border-radius: 0;
  -webkit-mask-size: contain;
  mask-size: contain;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  text-indent: 500%;
  white-space: nowrap;
  overflow: hidden;
}
.acf-admin-page .acf-field-setting-type .select2-selection__arrow b[role=presentation],
.acf-admin-page .acf-field-permalink-rewrite .select2-selection__arrow b[role=presentation],
.acf-admin-page .acf-field-query-var .select2-selection__arrow b[role=presentation],
.acf-admin-page .acf-field-capability .select2-selection__arrow b[role=presentation],
.acf-admin-page .acf-field-parent-slug .select2-selection__arrow b[role=presentation],
.acf-admin-page .acf-field-data-storage .select2-selection__arrow b[role=presentation],
.acf-admin-page .acf-field-manage-terms .select2-selection__arrow b[role=presentation],
.acf-admin-page .acf-field-edit-terms .select2-selection__arrow b[role=presentation],
.acf-admin-page .acf-field-delete-terms .select2-selection__arrow b[role=presentation],
.acf-admin-page .acf-field-assign-terms .select2-selection__arrow b[role=presentation],
.acf-admin-page .acf-field-meta-box .select2-selection__arrow b[role=presentation] {
  display: none;
}
.acf-admin-page .acf-field-setting-type .select2-container--open .select2-selection__arrow:after,
.acf-admin-page .acf-field-permalink-rewrite .select2-container--open .select2-selection__arrow:after,
.acf-admin-page .acf-field-query-var .select2-container--open .select2-selection__arrow:after,
.acf-admin-page .acf-field-capability .select2-container--open .select2-selection__arrow:after,
.acf-admin-page .acf-field-parent-slug .select2-container--open .select2-selection__arrow:after,
.acf-admin-page .acf-field-data-storage .select2-container--open .select2-selection__arrow:after,
.acf-admin-page .acf-field-manage-terms .select2-container--open .select2-selection__arrow:after,
.acf-admin-page .acf-field-edit-terms .select2-container--open .select2-selection__arrow:after,
.acf-admin-page .acf-field-delete-terms .select2-container--open .select2-selection__arrow:after,
.acf-admin-page .acf-field-assign-terms .select2-container--open .select2-selection__arrow:after,
.acf-admin-page .acf-field-meta-box .select2-container--open .select2-selection__arrow:after {
  -webkit-mask-image: url("../../images/icons/icon-chevron-up.svg");
  mask-image: url("../../images/icons/icon-chevron-up.svg");
}
.acf-admin-page .field-type-select-results {
  position: relative;
  top: 4px;
  z-index: 1002;
  border-radius: 0 0 6px 6px;
  box-shadow: 0px 8px 24px 4px rgba(16, 24, 40, 0.12);
}
.acf-admin-page .field-type-select-results.select2-dropdown--above {
  display: flex;
  flex-direction: column-reverse;
  top: 0;
  border-radius: 6px 6px 0 0;
  z-index: 99999;
}
.select2-container.select2-container--open.acf-admin-page .field-type-select-results {
  box-shadow: 0px 0px 0px 3px #EBF5FA, 0px 8px 24px 4px rgba(16, 24, 40, 0.12);
}

.acf-admin-page .field-type-select-results .acf-selection.has-icon {
  margin-left: 6px;
}
.rtl.acf-admin-page .field-type-select-results .acf-selection.has-icon {
  margin-right: 6px;
}

.acf-admin-page .field-type-select-results .select2-search {
  position: relative;
  margin: 0;
  padding: 0;
}
.acf-admin-page .field-type-select-results .select2-search--dropdown:after {
  content: "";
  display: block;
  position: absolute;
  top: 12px;
  left: 13px;
  width: 16px;
  height: 16px;
  -webkit-mask-image: url("../../images/icons/icon-search.svg");
  mask-image: url("../../images/icons/icon-search.svg");
  background-color: #98A2B3;
  border: none;
  border-radius: 0;
  -webkit-mask-size: contain;
  mask-size: contain;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  text-indent: 500%;
  white-space: nowrap;
  overflow: hidden;
}
.rtl.acf-admin-page .field-type-select-results .select2-search--dropdown:after {
  right: 12px;
  left: auto;
}

.acf-admin-page .field-type-select-results .select2-search .select2-search__field {
  padding-left: 38px;
  border-right: 0;
  border-bottom: 0;
  border-left: 0;
  border-radius: 0;
}
.rtl.acf-admin-page .field-type-select-results .select2-search .select2-search__field {
  padding-right: 38px;
  padding-left: 0;
}

.acf-admin-page .field-type-select-results .select2-search .select2-search__field:focus {
  border-top-color: #D0D5DD;
  outline: 0;
}
.acf-admin-page .field-type-select-results .select2-results__options {
  max-height: 440px;
}
.acf-admin-page .field-type-select-results .select2-results__option .select2-results__option--highlighted {
  background-color: #0783BE !important;
  color: #F9FAFB !important;
}
.acf-admin-page .field-type-select-results .select2-results__option .select2-results__option {
  display: inline-flex;
  position: relative;
  width: calc(100% - 24px);
  min-height: 32px;
  padding-top: 0;
  padding-right: 12px;
  padding-bottom: 0;
  padding-left: 12px;
  align-items: center;
}
.acf-admin-page .field-type-select-results .select2-results__option .select2-results__option .field-type-icon {
  top: auto;
  width: 18px;
  height: 18px;
  margin-right: 2px;
  box-shadow: 0 0 0 1px #F9FAFB;
}
.acf-admin-page .field-type-select-results .select2-results__option .select2-results__option .field-type-icon:before {
  width: 9px;
  height: 9px;
}
.acf-admin-page .field-type-select-results .select2-results__option[aria-selected=true] {
  background-color: #EBF5FA !important;
  color: #344054 !important;
}
.acf-admin-page .field-type-select-results .select2-results__option[aria-selected=true]:after {
  content: "";
  right: 13px;
  position: absolute;
  width: 16px;
  height: 16px;
  -webkit-mask-image: url("../../images/icons/icon-check.svg");
  mask-image: url("../../images/icons/icon-check.svg");
  background-color: #0783BE;
  border: none;
  border-radius: 0;
  -webkit-mask-size: contain;
  mask-size: contain;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  text-indent: 500%;
  white-space: nowrap;
  overflow: hidden;
}
.rtl.acf-admin-page .field-type-select-results .select2-results__option[aria-selected=true]:after {
  left: 13px;
  right: auto;
}

.acf-admin-page .field-type-select-results .select2-results__group {
  display: inline-flex;
  align-items: center;
  width: calc(100% - 24px);
  min-height: 25px;
  background-color: #F9FAFB;
  border-top-width: 1px;
  border-top-style: solid;
  border-top-color: #EAECF0;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: #EAECF0;
  color: #98A2B3;
  font-size: 11px;
  margin-bottom: 0;
  padding-top: 0;
  padding-right: 12px;
  padding-bottom: 0;
  padding-left: 12px;
  font-weight: normal;
}
.acf-admin-page.rtl .acf-field-setting-type .select2-selection__arrow:after,
.acf-admin-page.rtl .acf-field-permalink-rewrite .select2-selection__arrow:after,
.acf-admin-page.rtl .acf-field-query-var .select2-selection__arrow:after {
  right: auto;
  left: 10px;
}

.rtl.post-type-acf-field-group .acf-field-setting-name .acf-tip,
.rtl.acf-internal-post-type .acf-field-setting-name .acf-tip {
  left: auto;
  right: 654px;
}

/*---------------------------------------------------------------------------------------------
*
*  Field Groups
*
*---------------------------------------------------------------------------------------------*/
.acf-internal-post-type .tablenav.top {
  display: none;
}
.acf-internal-post-type .subsubsub {
  margin-bottom: 3px;
}
.acf-internal-post-type .wp-list-table {
  margin-top: 0;
  margin-right: 0;
  margin-bottom: 0;
  margin-left: 0;
  border-radius: 8px;
  border: none;
  overflow: hidden;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.1);
}
.acf-internal-post-type .wp-list-table strong {
  color: #98A2B3;
  margin: 0;
}
.acf-internal-post-type .wp-list-table a.row-title {
  font-size: 13px !important;
  font-weight: 500;
}
.acf-internal-post-type .wp-list-table th,
.acf-internal-post-type .wp-list-table td {
  color: #344054;
}
.acf-internal-post-type .wp-list-table th.sortable a,
.acf-internal-post-type .wp-list-table td.sortable a {
  padding: 0;
}
.acf-internal-post-type .wp-list-table th.check-column,
.acf-internal-post-type .wp-list-table td.check-column {
  padding-top: 12px;
  padding-right: 16px;
  padding-left: 16px;
}
@media screen and (max-width: 880px) {
  .acf-internal-post-type .wp-list-table th.check-column,
  .acf-internal-post-type .wp-list-table td.check-column {
    vertical-align: top;
    padding-right: 2px;
    padding-left: 10px;
  }
}
.acf-internal-post-type .wp-list-table th input,
.acf-internal-post-type .wp-list-table td input {
  margin: 0;
  padding: 0;
}
.acf-internal-post-type .wp-list-table th .acf-more-items,
.acf-internal-post-type .wp-list-table td .acf-more-items {
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px 6px 1px;
  gap: 8px;
  width: 25px;
  height: 16px;
  background: #EAECF0;
  border-radius: 100px;
  font-weight: 400;
  font-size: 10px;
  color: #475467;
}
.acf-internal-post-type .wp-list-table th .acf-emdash,
.acf-internal-post-type .wp-list-table td .acf-emdash {
  color: #D0D5DD;
}
.acf-internal-post-type .wp-list-table thead th, .acf-internal-post-type .wp-list-table thead td,
.acf-internal-post-type .wp-list-table tfoot th, .acf-internal-post-type .wp-list-table tfoot td {
  height: 48px;
  padding-right: 24px;
  padding-left: 24px;
  box-sizing: border-box;
  background-color: #F9FAFB;
  border-color: #EAECF0;
  font-weight: 500;
}
@media screen and (max-width: 880px) {
  .acf-internal-post-type .wp-list-table thead th, .acf-internal-post-type .wp-list-table thead td,
  .acf-internal-post-type .wp-list-table tfoot th, .acf-internal-post-type .wp-list-table tfoot td {
    padding-right: 16px;
    padding-left: 8px;
  }
}
@media screen and (max-width: 880px) {
  .acf-internal-post-type .wp-list-table thead th.check-column, .acf-internal-post-type .wp-list-table thead td.check-column,
  .acf-internal-post-type .wp-list-table tfoot th.check-column, .acf-internal-post-type .wp-list-table tfoot td.check-column {
    vertical-align: middle;
  }
}
.acf-internal-post-type .wp-list-table tbody th,
.acf-internal-post-type .wp-list-table tbody td {
  box-sizing: border-box;
  height: 60px;
  padding-top: 10px;
  padding-right: 24px;
  padding-bottom: 10px;
  padding-left: 24px;
  vertical-align: top;
  background-color: #fff;
  border-bottom-width: 1px;
  border-bottom-color: #EAECF0;
  border-bottom-style: solid;
}
@media screen and (max-width: 880px) {
  .acf-internal-post-type .wp-list-table tbody th,
  .acf-internal-post-type .wp-list-table tbody td {
    padding-right: 16px;
    padding-left: 8px;
  }
}
.acf-internal-post-type .wp-list-table .column-acf-key {
  white-space: nowrap;
}
.acf-internal-post-type .wp-list-table .column-acf-key .acf-icon-key-solid {
  display: inline-block;
  position: relative;
  bottom: -2px;
  width: 15px;
  height: 15px;
  margin-right: 4px;
  color: #98A2B3;
}
.acf-internal-post-type .wp-list-table .acf-location .dashicons {
  position: relative;
  bottom: -2px;
  width: 16px;
  height: 16px;
  margin-right: 6px;
  font-size: 16px;
  color: #98A2B3;
}
.acf-internal-post-type .wp-list-table .post-state {
  color: #667085;
}
.acf-internal-post-type .wp-list-table tr:hover,
.acf-internal-post-type .wp-list-table tr:focus-within {
  background: #f7f7f7;
}
.acf-internal-post-type .wp-list-table tr:hover .row-actions,
.acf-internal-post-type .wp-list-table tr:focus-within .row-actions {
  margin-bottom: 0;
}
@media screen and (min-width: 782px) {
  .acf-internal-post-type .wp-list-table .column-acf-count {
    width: 10%;
  }
}
.acf-internal-post-type .wp-list-table .row-actions span.file {
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.acf-internal-post-type.rtl .wp-list-table .column-acf-key .acf-icon-key-solid {
  margin-left: 4px;
  margin-right: 0;
}
.acf-internal-post-type.rtl .wp-list-table .acf-location .dashicons {
  margin-left: 6px;
  margin-right: 0;
}
.acf-internal-post-type .row-actions {
  margin-top: 2px;
  padding-top: 0;
  padding-right: 0;
  padding-bottom: 0;
  padding-left: 0;
  line-height: 14px;
  color: #D0D5DD;
}
.acf-internal-post-type .row-actions .trash a {
  color: #d94f4f;
}
.acf-internal-post-type .widefat thead td.check-column,
.acf-internal-post-type .widefat tfoot td.check-column {
  padding-top: 0;
}

/*--------------------------------------------------------------------------------------------
*
*	Row actions
*
*--------------------------------------------------------------------------------------------*/
.acf-internal-post-type .row-actions a:hover {
  color: #044767;
}
.acf-internal-post-type .row-actions .trash a {
  color: #a00;
}
.acf-internal-post-type .row-actions .trash a:hover {
  color: #f00;
}
.acf-internal-post-type .row-actions.visible {
  margin-bottom: 0;
  opacity: 1;
}

/*--------------------------------------------------------------------------------------------
*
*	Row hover
*
*--------------------------------------------------------------------------------------------*/
.acf-internal-post-type #the-list tr:hover td,
.acf-internal-post-type #the-list tr:hover th {
  background-color: #f7fbfd;
}

/*---------------------------------------------------------------------------------------------
*
*  Table Nav
*
*---------------------------------------------------------------------------------------------*/
.acf-internal-post-type .tablenav {
  margin-top: 24px;
  margin-right: 0;
  margin-bottom: 0;
  margin-left: 0;
  padding-top: 0;
  padding-right: 0;
  padding-bottom: 0;
  padding-left: 0;
  color: #667085;
}

/*--------------------------------------------------------------------------------------------
*
*	Search box
*
*--------------------------------------------------------------------------------------------*/
.acf-internal-post-type #posts-filter p.search-box {
  margin-top: 5px;
  margin-right: 0;
  margin-bottom: 24px;
  margin-left: 0;
}
.acf-internal-post-type #posts-filter p.search-box #post-search-input {
  min-width: 280px;
  margin-top: 0;
  margin-right: 8px;
  margin-bottom: 0;
  margin-left: 0;
}
@media screen and (max-width: 768px) {
  .acf-internal-post-type #posts-filter p.search-box {
    display: flex;
    box-sizing: border-box;
    padding-right: 24px;
    margin-right: 16px;
    position: inherit;
  }
  .acf-internal-post-type #posts-filter p.search-box #post-search-input {
    min-width: auto;
  }
}

.rtl.acf-internal-post-type #posts-filter p.search-box #post-search-input {
  margin-right: 0;
  margin-left: 8px;
}
@media screen and (max-width: 768px) {
  .rtl.acf-internal-post-type #posts-filter p.search-box {
    padding-left: 24px;
    padding-right: 0;
    margin-left: 16px;
    margin-right: 0;
  }
}

/*--------------------------------------------------------------------------------------------
*
*	Status tabs
*
*--------------------------------------------------------------------------------------------*/
.acf-internal-post-type .subsubsub {
  display: flex;
  align-items: flex-end;
  height: 40px;
  margin-bottom: 16px;
}
.acf-internal-post-type .subsubsub li {
  margin-top: 0;
  margin-right: 4px;
  color: #98A2B3;
}
.acf-internal-post-type .subsubsub li .count {
  color: #667085;
}

/*--------------------------------------------------------------------------------------------
*
*	Pagination
*
*--------------------------------------------------------------------------------------------*/
.acf-internal-post-type .tablenav-pages {
  display: flex;
  align-items: center;
}
.acf-internal-post-type .tablenav-pages.no-pages {
  display: none;
}
.acf-internal-post-type .tablenav-pages .displaying-num {
  margin-top: 0;
  margin-right: 16px;
  margin-bottom: 0;
  margin-left: 0;
}
.acf-internal-post-type .tablenav-pages .pagination-links {
  display: flex;
  align-items: center;
}
.acf-internal-post-type .tablenav-pages .pagination-links #table-paging {
  margin-top: 0;
  margin-right: 4px;
  margin-bottom: 0;
  margin-left: 8px;
}
.acf-internal-post-type .tablenav-pages .pagination-links #table-paging .total-pages {
  margin-right: 0;
}
.acf-internal-post-type .tablenav-pages.one-page .pagination-links {
  display: none;
}

/*--------------------------------------------------------------------------------------------
*
*	Pagination buttons & icons
*
*--------------------------------------------------------------------------------------------*/
.acf-internal-post-type .tablenav-pages .pagination-links .button {
  display: inline-flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  min-width: 40px;
  margin-top: 0;
  margin-right: 0;
  margin-bottom: 0;
  margin-left: 0;
  padding-top: 0;
  padding-right: 0;
  padding-bottom: 0;
  padding-left: 0;
  background-color: transparent;
}
.acf-internal-post-type .tablenav-pages .pagination-links .button:nth-child(1), .acf-internal-post-type .tablenav-pages .pagination-links .button:nth-child(2), .acf-internal-post-type .tablenav-pages .pagination-links .button:last-child, .acf-internal-post-type .tablenav-pages .pagination-links .button:nth-last-child(2) {
  display: inline-block;
  position: relative;
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
  margin-left: 4px;
}
.acf-internal-post-type .tablenav-pages .pagination-links .button:nth-child(1):before, .acf-internal-post-type .tablenav-pages .pagination-links .button:nth-child(2):before, .acf-internal-post-type .tablenav-pages .pagination-links .button:last-child:before, .acf-internal-post-type .tablenav-pages .pagination-links .button:nth-last-child(2):before {
  content: "";
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: #0783BE;
  border-radius: 0;
  -webkit-mask-size: 20px;
  mask-size: 20px;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
}
.acf-internal-post-type .tablenav-pages .pagination-links .button:nth-child(1):before {
  -webkit-mask-image: url("../../images/icons/icon-chevron-left-double.svg");
  mask-image: url("../../images/icons/icon-chevron-left-double.svg");
}
.acf-internal-post-type .tablenav-pages .pagination-links .button:nth-child(2):before {
  -webkit-mask-image: url("../../images/icons/icon-chevron-left.svg");
  mask-image: url("../../images/icons/icon-chevron-left.svg");
}
.acf-internal-post-type .tablenav-pages .pagination-links .button:nth-last-child(2):before {
  -webkit-mask-image: url("../../images/icons/icon-chevron-right.svg");
  mask-image: url("../../images/icons/icon-chevron-right.svg");
}
.acf-internal-post-type .tablenav-pages .pagination-links .button:last-child:before {
  -webkit-mask-image: url("../../images/icons/icon-chevron-right-double.svg");
  mask-image: url("../../images/icons/icon-chevron-right-double.svg");
}
.acf-internal-post-type .tablenav-pages .pagination-links .button:hover {
  border-color: #066998;
  background-color: rgba(7, 131, 190, 0.05);
}
.acf-internal-post-type .tablenav-pages .pagination-links .button:hover:before {
  background-color: #066998;
}
.acf-internal-post-type .tablenav-pages .pagination-links .button.disabled {
  background-color: transparent !important;
}
.acf-internal-post-type .tablenav-pages .pagination-links .button.disabled.disabled:before {
  background-color: #D0D5DD;
}

/*---------------------------------------------------------------------------------------------
*
*  Empty state
*
*---------------------------------------------------------------------------------------------*/
.acf-no-field-groups-wrapper,
.acf-no-taxonomies-wrapper,
.acf-no-post-types-wrapper,
.acf-no-options-pages-wrapper {
  display: flex;
  justify-content: center;
  padding-top: 48px;
  padding-bottom: 48px;
}
.acf-no-field-groups-wrapper .acf-no-field-groups-inner,
.acf-no-field-groups-wrapper .acf-no-taxonomies-inner,
.acf-no-field-groups-wrapper .acf-no-post-types-inner,
.acf-no-field-groups-wrapper .acf-no-options-pages-inner,
.acf-no-taxonomies-wrapper .acf-no-field-groups-inner,
.acf-no-taxonomies-wrapper .acf-no-taxonomies-inner,
.acf-no-taxonomies-wrapper .acf-no-post-types-inner,
.acf-no-taxonomies-wrapper .acf-no-options-pages-inner,
.acf-no-post-types-wrapper .acf-no-field-groups-inner,
.acf-no-post-types-wrapper .acf-no-taxonomies-inner,
.acf-no-post-types-wrapper .acf-no-post-types-inner,
.acf-no-post-types-wrapper .acf-no-options-pages-inner,
.acf-no-options-pages-wrapper .acf-no-field-groups-inner,
.acf-no-options-pages-wrapper .acf-no-taxonomies-inner,
.acf-no-options-pages-wrapper .acf-no-post-types-inner,
.acf-no-options-pages-wrapper .acf-no-options-pages-inner {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  align-items: flex-start;
  text-align: center;
  max-width: 380px;
  min-height: 320px;
}
.acf-no-field-groups-wrapper .acf-no-field-groups-inner img,
.acf-no-field-groups-wrapper .acf-no-field-groups-inner h2,
.acf-no-field-groups-wrapper .acf-no-field-groups-inner p,
.acf-no-field-groups-wrapper .acf-no-taxonomies-inner img,
.acf-no-field-groups-wrapper .acf-no-taxonomies-inner h2,
.acf-no-field-groups-wrapper .acf-no-taxonomies-inner p,
.acf-no-field-groups-wrapper .acf-no-post-types-inner img,
.acf-no-field-groups-wrapper .acf-no-post-types-inner h2,
.acf-no-field-groups-wrapper .acf-no-post-types-inner p,
.acf-no-field-groups-wrapper .acf-no-options-pages-inner img,
.acf-no-field-groups-wrapper .acf-no-options-pages-inner h2,
.acf-no-field-groups-wrapper .acf-no-options-pages-inner p,
.acf-no-taxonomies-wrapper .acf-no-field-groups-inner img,
.acf-no-taxonomies-wrapper .acf-no-field-groups-inner h2,
.acf-no-taxonomies-wrapper .acf-no-field-groups-inner p,
.acf-no-taxonomies-wrapper .acf-no-taxonomies-inner img,
.acf-no-taxonomies-wrapper .acf-no-taxonomies-inner h2,
.acf-no-taxonomies-wrapper .acf-no-taxonomies-inner p,
.acf-no-taxonomies-wrapper .acf-no-post-types-inner img,
.acf-no-taxonomies-wrapper .acf-no-post-types-inner h2,
.acf-no-taxonomies-wrapper .acf-no-post-types-inner p,
.acf-no-taxonomies-wrapper .acf-no-options-pages-inner img,
.acf-no-taxonomies-wrapper .acf-no-options-pages-inner h2,
.acf-no-taxonomies-wrapper .acf-no-options-pages-inner p,
.acf-no-post-types-wrapper .acf-no-field-groups-inner img,
.acf-no-post-types-wrapper .acf-no-field-groups-inner h2,
.acf-no-post-types-wrapper .acf-no-field-groups-inner p,
.acf-no-post-types-wrapper .acf-no-taxonomies-inner img,
.acf-no-post-types-wrapper .acf-no-taxonomies-inner h2,
.acf-no-post-types-wrapper .acf-no-taxonomies-inner p,
.acf-no-post-types-wrapper .acf-no-post-types-inner img,
.acf-no-post-types-wrapper .acf-no-post-types-inner h2,
.acf-no-post-types-wrapper .acf-no-post-types-inner p,
.acf-no-post-types-wrapper .acf-no-options-pages-inner img,
.acf-no-post-types-wrapper .acf-no-options-pages-inner h2,
.acf-no-post-types-wrapper .acf-no-options-pages-inner p,
.acf-no-options-pages-wrapper .acf-no-field-groups-inner img,
.acf-no-options-pages-wrapper .acf-no-field-groups-inner h2,
.acf-no-options-pages-wrapper .acf-no-field-groups-inner p,
.acf-no-options-pages-wrapper .acf-no-taxonomies-inner img,
.acf-no-options-pages-wrapper .acf-no-taxonomies-inner h2,
.acf-no-options-pages-wrapper .acf-no-taxonomies-inner p,
.acf-no-options-pages-wrapper .acf-no-post-types-inner img,
.acf-no-options-pages-wrapper .acf-no-post-types-inner h2,
.acf-no-options-pages-wrapper .acf-no-post-types-inner p,
.acf-no-options-pages-wrapper .acf-no-options-pages-inner img,
.acf-no-options-pages-wrapper .acf-no-options-pages-inner h2,
.acf-no-options-pages-wrapper .acf-no-options-pages-inner p {
  flex: 1 0 100%;
}
.acf-no-field-groups-wrapper .acf-no-field-groups-inner h2,
.acf-no-field-groups-wrapper .acf-no-taxonomies-inner h2,
.acf-no-field-groups-wrapper .acf-no-post-types-inner h2,
.acf-no-field-groups-wrapper .acf-no-options-pages-inner h2,
.acf-no-taxonomies-wrapper .acf-no-field-groups-inner h2,
.acf-no-taxonomies-wrapper .acf-no-taxonomies-inner h2,
.acf-no-taxonomies-wrapper .acf-no-post-types-inner h2,
.acf-no-taxonomies-wrapper .acf-no-options-pages-inner h2,
.acf-no-post-types-wrapper .acf-no-field-groups-inner h2,
.acf-no-post-types-wrapper .acf-no-taxonomies-inner h2,
.acf-no-post-types-wrapper .acf-no-post-types-inner h2,
.acf-no-post-types-wrapper .acf-no-options-pages-inner h2,
.acf-no-options-pages-wrapper .acf-no-field-groups-inner h2,
.acf-no-options-pages-wrapper .acf-no-taxonomies-inner h2,
.acf-no-options-pages-wrapper .acf-no-post-types-inner h2,
.acf-no-options-pages-wrapper .acf-no-options-pages-inner h2 {
  margin-top: 32px;
  margin-bottom: 0;
  padding: 0;
  color: #344054;
}
.acf-no-field-groups-wrapper .acf-no-field-groups-inner p,
.acf-no-field-groups-wrapper .acf-no-taxonomies-inner p,
.acf-no-field-groups-wrapper .acf-no-post-types-inner p,
.acf-no-field-groups-wrapper .acf-no-options-pages-inner p,
.acf-no-taxonomies-wrapper .acf-no-field-groups-inner p,
.acf-no-taxonomies-wrapper .acf-no-taxonomies-inner p,
.acf-no-taxonomies-wrapper .acf-no-post-types-inner p,
.acf-no-taxonomies-wrapper .acf-no-options-pages-inner p,
.acf-no-post-types-wrapper .acf-no-field-groups-inner p,
.acf-no-post-types-wrapper .acf-no-taxonomies-inner p,
.acf-no-post-types-wrapper .acf-no-post-types-inner p,
.acf-no-post-types-wrapper .acf-no-options-pages-inner p,
.acf-no-options-pages-wrapper .acf-no-field-groups-inner p,
.acf-no-options-pages-wrapper .acf-no-taxonomies-inner p,
.acf-no-options-pages-wrapper .acf-no-post-types-inner p,
.acf-no-options-pages-wrapper .acf-no-options-pages-inner p {
  margin-top: 12px;
  margin-bottom: 0;
  padding: 0;
  color: #667085;
}
.acf-no-field-groups-wrapper .acf-no-field-groups-inner p.acf-small,
.acf-no-field-groups-wrapper .acf-no-taxonomies-inner p.acf-small,
.acf-no-field-groups-wrapper .acf-no-post-types-inner p.acf-small,
.acf-no-field-groups-wrapper .acf-no-options-pages-inner p.acf-small,
.acf-no-taxonomies-wrapper .acf-no-field-groups-inner p.acf-small,
.acf-no-taxonomies-wrapper .acf-no-taxonomies-inner p.acf-small,
.acf-no-taxonomies-wrapper .acf-no-post-types-inner p.acf-small,
.acf-no-taxonomies-wrapper .acf-no-options-pages-inner p.acf-small,
.acf-no-post-types-wrapper .acf-no-field-groups-inner p.acf-small,
.acf-no-post-types-wrapper .acf-no-taxonomies-inner p.acf-small,
.acf-no-post-types-wrapper .acf-no-post-types-inner p.acf-small,
.acf-no-post-types-wrapper .acf-no-options-pages-inner p.acf-small,
.acf-no-options-pages-wrapper .acf-no-field-groups-inner p.acf-small,
.acf-no-options-pages-wrapper .acf-no-taxonomies-inner p.acf-small,
.acf-no-options-pages-wrapper .acf-no-post-types-inner p.acf-small,
.acf-no-options-pages-wrapper .acf-no-options-pages-inner p.acf-small {
  display: block;
  position: relative;
  margin-top: 32px;
}
.acf-no-field-groups-wrapper .acf-no-field-groups-inner img,
.acf-no-field-groups-wrapper .acf-no-taxonomies-inner img,
.acf-no-field-groups-wrapper .acf-no-post-types-inner img,
.acf-no-field-groups-wrapper .acf-no-options-pages-inner img,
.acf-no-taxonomies-wrapper .acf-no-field-groups-inner img,
.acf-no-taxonomies-wrapper .acf-no-taxonomies-inner img,
.acf-no-taxonomies-wrapper .acf-no-post-types-inner img,
.acf-no-taxonomies-wrapper .acf-no-options-pages-inner img,
.acf-no-post-types-wrapper .acf-no-field-groups-inner img,
.acf-no-post-types-wrapper .acf-no-taxonomies-inner img,
.acf-no-post-types-wrapper .acf-no-post-types-inner img,
.acf-no-post-types-wrapper .acf-no-options-pages-inner img,
.acf-no-options-pages-wrapper .acf-no-field-groups-inner img,
.acf-no-options-pages-wrapper .acf-no-taxonomies-inner img,
.acf-no-options-pages-wrapper .acf-no-post-types-inner img,
.acf-no-options-pages-wrapper .acf-no-options-pages-inner img {
  max-width: 284px;
  margin-bottom: 0;
}
.acf-no-field-groups-wrapper .acf-no-field-groups-inner .acf-btn,
.acf-no-field-groups-wrapper .acf-no-taxonomies-inner .acf-btn,
.acf-no-field-groups-wrapper .acf-no-post-types-inner .acf-btn,
.acf-no-field-groups-wrapper .acf-no-options-pages-inner .acf-btn,
.acf-no-taxonomies-wrapper .acf-no-field-groups-inner .acf-btn,
.acf-no-taxonomies-wrapper .acf-no-taxonomies-inner .acf-btn,
.acf-no-taxonomies-wrapper .acf-no-post-types-inner .acf-btn,
.acf-no-taxonomies-wrapper .acf-no-options-pages-inner .acf-btn,
.acf-no-post-types-wrapper .acf-no-field-groups-inner .acf-btn,
.acf-no-post-types-wrapper .acf-no-taxonomies-inner .acf-btn,
.acf-no-post-types-wrapper .acf-no-post-types-inner .acf-btn,
.acf-no-post-types-wrapper .acf-no-options-pages-inner .acf-btn,
.acf-no-options-pages-wrapper .acf-no-field-groups-inner .acf-btn,
.acf-no-options-pages-wrapper .acf-no-taxonomies-inner .acf-btn,
.acf-no-options-pages-wrapper .acf-no-post-types-inner .acf-btn,
.acf-no-options-pages-wrapper .acf-no-options-pages-inner .acf-btn {
  margin-top: 32px;
}
.acf-no-field-groups-wrapper .acf-no-post-types-inner img,
.acf-no-field-groups-wrapper .acf-no-options-pages-inner img,
.acf-no-taxonomies-wrapper .acf-no-post-types-inner img,
.acf-no-taxonomies-wrapper .acf-no-options-pages-inner img,
.acf-no-post-types-wrapper .acf-no-post-types-inner img,
.acf-no-post-types-wrapper .acf-no-options-pages-inner img,
.acf-no-options-pages-wrapper .acf-no-post-types-inner img,
.acf-no-options-pages-wrapper .acf-no-options-pages-inner img {
  width: 106px;
  height: 88px;
}
.acf-no-field-groups-wrapper .acf-no-taxonomies-inner img,
.acf-no-taxonomies-wrapper .acf-no-taxonomies-inner img,
.acf-no-post-types-wrapper .acf-no-taxonomies-inner img,
.acf-no-options-pages-wrapper .acf-no-taxonomies-inner img {
  width: 98px;
  height: 88px;
}

.acf-no-field-groups #the-list tr:hover td,
.acf-no-field-groups #the-list tr:hover th,
.acf-no-field-groups .acf-admin-field-groups .wp-list-table tr:hover,
.acf-no-field-groups .striped > tbody > :nth-child(odd), .acf-no-field-groups ul.striped > :nth-child(odd), .acf-no-field-groups .alternate,
.acf-no-post-types #the-list tr:hover td,
.acf-no-post-types #the-list tr:hover th,
.acf-no-post-types .acf-admin-field-groups .wp-list-table tr:hover,
.acf-no-post-types .striped > tbody > :nth-child(odd),
.acf-no-post-types ul.striped > :nth-child(odd),
.acf-no-post-types .alternate,
.acf-no-taxonomies #the-list tr:hover td,
.acf-no-taxonomies #the-list tr:hover th,
.acf-no-taxonomies .acf-admin-field-groups .wp-list-table tr:hover,
.acf-no-taxonomies .striped > tbody > :nth-child(odd),
.acf-no-taxonomies ul.striped > :nth-child(odd),
.acf-no-taxonomies .alternate,
.acf-no-options-pages #the-list tr:hover td,
.acf-no-options-pages #the-list tr:hover th,
.acf-no-options-pages .acf-admin-field-groups .wp-list-table tr:hover,
.acf-no-options-pages .striped > tbody > :nth-child(odd),
.acf-no-options-pages ul.striped > :nth-child(odd),
.acf-no-options-pages .alternate {
  background-color: transparent !important;
}
.acf-no-field-groups .wp-list-table thead,
.acf-no-field-groups .wp-list-table tfoot,
.acf-no-post-types .wp-list-table thead,
.acf-no-post-types .wp-list-table tfoot,
.acf-no-taxonomies .wp-list-table thead,
.acf-no-taxonomies .wp-list-table tfoot,
.acf-no-options-pages .wp-list-table thead,
.acf-no-options-pages .wp-list-table tfoot {
  display: none;
}

.acf-internal-post-type #the-list .no-items td {
  vertical-align: middle;
}

/*---------------------------------------------------------------------------------------------
*
*  Small screen list table info toggle
*
*---------------------------------------------------------------------------------------------*/
.acf-internal-post-type .wp-list-table .toggle-row:before {
  top: 4px;
  left: 16px;
  border-radius: 0;
  content: "";
  display: block;
  position: absolute;
  width: 16px;
  height: 16px;
  background-color: #0783BE;
  border-radius: 0;
  -webkit-mask-size: 20px;
  mask-size: 20px;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  -webkit-mask-image: url("../../images/icons/icon-chevron-down.svg");
  mask-image: url("../../images/icons/icon-chevron-down.svg");
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
}
.acf-internal-post-type .wp-list-table .is-expanded .toggle-row:before {
  -webkit-mask-image: url("../../images/icons/icon-chevron-up.svg");
  mask-image: url("../../images/icons/icon-chevron-up.svg");
}

/*---------------------------------------------------------------------------------------------
*
*  Small screen checkbox
*
*---------------------------------------------------------------------------------------------*/
@media screen and (max-width: 880px) {
  .acf-internal-post-type .widefat th input[type=checkbox],
  .acf-internal-post-type .widefat thead td input[type=checkbox],
  .acf-internal-post-type .widefat tfoot td input[type=checkbox] {
    margin-bottom: 0;
  }
}

/*---------------------------------------------------------------------------------------------
*
*  Admin Navigation
*
*---------------------------------------------------------------------------------------------*/
.acf-admin-toolbar {
  position: unset;
  top: 32px;
  height: 72px;
  z-index: 800;
  background: #344054;
  color: #98A2B3;
}
.acf-admin-toolbar .acf-admin-toolbar-inner {
  display: flex;
  justify-content: space-between;
  align-content: center;
  align-items: center;
  max-width: 100%;
}
.acf-admin-toolbar .acf-admin-toolbar-inner .acf-nav-wrap {
  display: flex;
  align-items: center;
}
@media screen and (max-width: 1250px) {
  .acf-admin-toolbar .acf-admin-toolbar-inner .acf-nav-wrap .acf-header-tab-acf-post-type,
  .acf-admin-toolbar .acf-admin-toolbar-inner .acf-nav-wrap .acf-header-tab-acf-taxonomy {
    display: none;
  }
  .acf-admin-toolbar .acf-admin-toolbar-inner .acf-nav-wrap .acf-more .acf-header-tab-acf-post-type,
  .acf-admin-toolbar .acf-admin-toolbar-inner .acf-nav-wrap .acf-more .acf-header-tab-acf-taxonomy {
    display: flex;
  }
}
.acf-admin-toolbar .acf-admin-toolbar-inner .acf-nav-upgrade-wrap {
  display: flex;
  align-items: center;
}
.acf-admin-toolbar .acf-admin-toolbar-inner .acf-nav-wpengine-logo {
  display: inline-flex;
  margin-left: 24px;
}
@media screen and (max-width: 1000px) {
  .acf-admin-toolbar .acf-admin-toolbar-inner .acf-nav-wpengine-logo {
    display: none;
  }
}
@media screen and (max-width: 880px) {
  .acf-admin-toolbar {
    position: static;
  }
}
.acf-admin-toolbar .acf-logo {
  display: flex;
  margin-right: 24px;
  text-decoration: none;
}
.acf-admin-toolbar .acf-logo .acf-pro-label {
  margin-left: 8px;
}
.acf-admin-toolbar .acf-logo img {
  display: block;
  max-width: 55px;
  line-height: 0%;
}
.acf-admin-toolbar h2 {
  display: none;
  color: #F9FAFB;
}
.acf-admin-toolbar .acf-tab {
  display: flex;
  align-items: center;
  box-sizing: border-box;
  min-height: 40px;
  margin-right: 8px;
  padding-top: 8px;
  padding-right: 16px;
  padding-bottom: 8px;
  padding-left: 16px;
  border-width: 1px;
  border-style: solid;
  border-color: transparent;
  border-radius: 6px;
  color: #98A2B3;
  text-decoration: none;
}
.acf-admin-toolbar .acf-tab.is-active {
  background-color: #475467;
  color: #fff;
}
.acf-admin-toolbar .acf-tab:hover {
  background-color: #475467;
  color: #F9FAFB;
}
.acf-admin-toolbar .acf-tab:focus-visible {
  border-width: 1px;
  border-style: solid;
  border-color: #667085;
}
.acf-admin-toolbar .acf-tab:focus {
  box-shadow: none;
}
.acf-admin-toolbar .acf-more:hover .acf-tab.acf-more-tab {
  background-color: #475467;
  color: #F9FAFB;
}
.acf-admin-toolbar .acf-more ul {
  display: none;
  position: absolute;
  box-sizing: border-box;
  background: #fff;
  z-index: 1051;
  overflow: hidden;
  min-width: 280px;
  margin-top: 0;
  margin-right: 0;
  margin-bottom: 0;
  margin-left: 0;
  padding: 0;
  border-radius: 8px;
  box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.04), 0px 8px 23px rgba(0, 0, 0, 0.12);
}
.acf-admin-toolbar .acf-more ul .acf-wp-engine {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 48px;
  border-top: 1px solid rgba(0, 0, 0, 0.08);
  background: #ECFBFC;
}
.acf-admin-toolbar .acf-more ul .acf-wp-engine a {
  display: flex;
  width: 100%;
  justify-content: space-between;
  border-top: none;
}
.acf-admin-toolbar .acf-more ul li {
  margin: 0;
  padding: 0 16px;
}
.acf-admin-toolbar .acf-more ul li .acf-header-tab-acf-post-type,
.acf-admin-toolbar .acf-more ul li .acf-header-tab-acf-taxonomy {
  display: none;
}
.acf-admin-toolbar .acf-more ul li.acf-more-section-header {
  background: #F9FAFB;
  padding: 1px 0 0 0;
  margin-top: -1px;
  border-top: 1px solid #EAECF0;
  border-bottom: 1px solid #EAECF0;
}
.acf-admin-toolbar .acf-more ul li.acf-more-section-header span {
  color: #475467;
  font-size: 12px;
  font-weight: bold;
}
.acf-admin-toolbar .acf-more ul li.acf-more-section-header span:hover {
  background: #F9FAFB;
}
.acf-admin-toolbar .acf-more ul li a {
  margin: 0;
  padding: 0;
  color: #1D2939;
  border-radius: 0;
  border-top-width: 1px;
  border-top-style: solid;
  border-top-color: #F2F4F7;
}
.acf-admin-toolbar .acf-more ul li a:hover, .acf-admin-toolbar .acf-more ul li a.acf-tab.is-active {
  background-color: unset;
  color: #0783BE;
}
.acf-admin-toolbar .acf-more ul li a i.acf-icon {
  display: none !important;
  width: 16px;
  height: 16px;
  -webkit-mask-size: 16px;
  mask-size: 16px;
  background-color: #98A2B3 !important;
}
.acf-admin-toolbar .acf-more ul li a .acf-requires-pro {
  justify-content: center;
  align-items: center;
  color: white;
  background: linear-gradient(90.52deg, #3E8BFF 0.44%, #A45CFF 113.3%);
  background-size: 140% 20%;
  background-position: 100% 0;
  border-radius: 100px;
  font-size: 11px;
  position: absolute;
  right: 16px;
  padding-right: 6px;
  padding-left: 6px;
}
.acf-admin-toolbar .acf-more ul li a img.acf-wp-engine-pro {
  display: block;
  height: 16px;
  width: auto;
}
.acf-admin-toolbar .acf-more ul li a .acf-wp-engine-upsell-pill {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  min-height: 22px;
  border-radius: 100px;
  font-size: 11px;
  padding-right: 8px;
  padding-left: 8px;
  background: #0ECAD4;
  color: #FFFFFF;
  text-shadow: 0px 1px 0 rgba(0, 0, 0, 0.12);
  text-transform: uppercase;
}
.acf-admin-toolbar .acf-more ul li:first-child a {
  border-bottom: none;
}
.acf-admin-toolbar .acf-more ul:hover, .acf-admin-toolbar .acf-more ul:focus {
  display: block;
}
.acf-admin-toolbar .acf-more:hover ul, .acf-admin-toolbar .acf-more:focus ul {
  display: block;
}
#wpcontent .acf-admin-toolbar {
  box-sizing: border-box;
  margin-left: -20px;
  padding-top: 16px;
  padding-right: 32px;
  padding-bottom: 16px;
  padding-left: 32px;
}
@media screen and (max-width: 600px) {
  .acf-admin-toolbar {
    display: none;
  }
}

.rtl #wpcontent .acf-admin-toolbar {
  margin-left: 0;
  margin-right: -20px;
}
.rtl #wpcontent .acf-admin-toolbar .acf-tab {
  margin-left: 8px;
  margin-right: 0;
}
.rtl .acf-logo {
  margin-right: 0;
  margin-left: 32px;
}

/*---------------------------------------------------------------------------------------------
*
*  Admin Toolbar Icons
*
*---------------------------------------------------------------------------------------------*/
.acf-admin-toolbar .acf-tab i.acf-icon,
.acf-admin-toolbar .acf-more i.acf-icon {
  display: none;
  margin-right: 8px;
  margin-left: -2px;
}
.acf-admin-toolbar .acf-tab i.acf-icon.acf-icon-dropdown,
.acf-admin-toolbar .acf-more i.acf-icon.acf-icon-dropdown {
  -webkit-mask-image: url("../../images/icons/icon-chevron-down.svg");
  mask-image: url("../../images/icons/icon-chevron-down.svg");
  width: 16px;
  height: 16px;
  -webkit-mask-size: 16px;
  mask-size: 16px;
  margin-right: -6px;
  margin-left: 6px;
}
.acf-admin-toolbar .acf-tab.acf-header-tab-acf-field-group i.acf-icon, .acf-admin-toolbar .acf-tab.acf-header-tab-acf-post-type i.acf-icon, .acf-admin-toolbar .acf-tab.acf-header-tab-acf-taxonomy i.acf-icon, .acf-admin-toolbar .acf-tab.acf-header-tab-acf-tools i.acf-icon, .acf-admin-toolbar .acf-tab.acf-header-tab-acf-settings-updates i.acf-icon, .acf-admin-toolbar .acf-tab.acf-header-tab-acf-more i.acf-icon,
.acf-admin-toolbar .acf-more.acf-header-tab-acf-field-group i.acf-icon,
.acf-admin-toolbar .acf-more.acf-header-tab-acf-post-type i.acf-icon,
.acf-admin-toolbar .acf-more.acf-header-tab-acf-taxonomy i.acf-icon,
.acf-admin-toolbar .acf-more.acf-header-tab-acf-tools i.acf-icon,
.acf-admin-toolbar .acf-more.acf-header-tab-acf-settings-updates i.acf-icon,
.acf-admin-toolbar .acf-more.acf-header-tab-acf-more i.acf-icon {
  display: inline-flex;
}
.acf-admin-toolbar .acf-tab.is-active i.acf-icon, .acf-admin-toolbar .acf-tab:hover i.acf-icon,
.acf-admin-toolbar .acf-more.is-active i.acf-icon,
.acf-admin-toolbar .acf-more:hover i.acf-icon {
  background-color: #EAECF0;
}
.rtl .acf-admin-toolbar .acf-tab i.acf-icon {
  margin-right: -2px;
  margin-left: 8px;
}
.acf-admin-toolbar .acf-header-tab-acf-field-group i.acf-icon {
  -webkit-mask-image: url("../../images/icons/icon-field-groups.svg");
  mask-image: url("../../images/icons/icon-field-groups.svg");
}
.acf-admin-toolbar .acf-header-tab-acf-post-type i.acf-icon {
  -webkit-mask-image: url("../../images/icons/icon-post-type.svg");
  mask-image: url("../../images/icons/icon-post-type.svg");
}
.acf-admin-toolbar .acf-header-tab-acf-taxonomy i.acf-icon {
  -webkit-mask-image: url("../../images/icons/icon-taxonomies.svg");
  mask-image: url("../../images/icons/icon-taxonomies.svg");
}
.acf-admin-toolbar .acf-header-tab-acf-tools i.acf-icon {
  -webkit-mask-image: url("../../images/icons/icon-tools.svg");
  mask-image: url("../../images/icons/icon-tools.svg");
}
.acf-admin-toolbar .acf-header-tab-acf-settings-updates i.acf-icon {
  -webkit-mask-image: url("../../images/icons/icon-updates.svg");
  mask-image: url("../../images/icons/icon-updates.svg");
}
.acf-admin-toolbar .acf-header-tab-acf-more i.acf-icon-more {
  -webkit-mask-image: url("../../images/icons/icon-extended-menu.svg");
  mask-image: url("../../images/icons/icon-extended-menu.svg");
}

/*---------------------------------------------------------------------------------------------
*
*  Hide WP default controls
*
*---------------------------------------------------------------------------------------------*/
.acf-admin-page h1.wp-heading-inline {
  display: none;
}
.acf-admin-page .wrap .wp-heading-inline + .page-title-action {
  display: none;
}

/*---------------------------------------------------------------------------------------------
*
*  Headerbar
*
*---------------------------------------------------------------------------------------------*/
.acf-headerbar {
  display: flex;
  align-items: center;
  position: sticky;
  top: 32px;
  z-index: 700;
  box-sizing: border-box;
  min-height: 72px;
  margin-left: -20px;
  padding-top: 8px;
  padding-right: 32px;
  padding-bottom: 8px;
  padding-left: 32px;
  background-color: #fff;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.1);
}
.acf-headerbar .acf-headerbar-inner {
  flex: 1 1 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1440px;
}
.acf-headerbar .acf-page-title {
  margin-top: 0;
  margin-right: 16px;
  margin-bottom: 0;
  margin-left: 0;
  padding-top: 0;
  padding-right: 0;
  padding-bottom: 0;
  padding-left: 0;
}
.acf-headerbar .acf-page-title .acf-duplicated-from {
  color: #98A2B3;
}
@media screen and (max-width: 880px) {
  .acf-headerbar {
    position: static;
  }
}
@media screen and (max-width: 600px) {
  .acf-headerbar {
    justify-content: space-between;
    position: relative;
    top: 46px;
    min-height: 64px;
    padding-right: 12px;
  }
}
.acf-headerbar .acf-headerbar-content {
  flex: 1 1 auto;
  display: flex;
  align-items: center;
}
@media screen and (max-width: 880px) {
  .acf-headerbar .acf-headerbar-content {
    flex-wrap: wrap;
  }
  .acf-headerbar .acf-headerbar-content .acf-headerbar-title,
  .acf-headerbar .acf-headerbar-content .acf-title-wrap {
    flex: 1 1 100%;
  }
  .acf-headerbar .acf-headerbar-content .acf-title-wrap {
    margin-top: 8px;
  }
}
.acf-headerbar .acf-input-error {
  border: 1px rgba(209, 55, 55, 0.5) solid !important;
  box-shadow: 0px 0px 0px 3px rgba(209, 55, 55, 0.12), 0px 0px 0px rgba(255, 54, 54, 0.25) !important;
  background-image: url("../../images/icons/icon-warning-alt-red.svg");
  background-position: right 10px top 50%;
  background-size: 20px;
  background-repeat: no-repeat;
}
.acf-headerbar .acf-input-error:focus {
  outline: none !important;
  border: 1px rgba(209, 55, 55, 0.8) solid !important;
  box-shadow: 0px 0px 0px 3px rgba(209, 55, 55, 0.16), 0px 0px 0px rgba(255, 54, 54, 0.25) !important;
}
.acf-headerbar .acf-headerbar-title-field {
  min-width: 320px;
}
@media screen and (max-width: 880px) {
  .acf-headerbar .acf-headerbar-title-field {
    min-width: 100%;
  }
}
.acf-headerbar .acf-headerbar-actions {
  display: flex;
}
.acf-headerbar .acf-headerbar-actions .acf-btn {
  margin-left: 8px;
}
.acf-headerbar .acf-headerbar-actions .disabled {
  background-color: #F2F4F7;
  color: #98A2B3 !important;
  border: 1px #D0D5DD solid;
  cursor: default;
}

/*---------------------------------------------------------------------------------------------
*
*  Edit Field Group Headerbar
*
*---------------------------------------------------------------------------------------------*/
.acf-headerbar-field-editor {
  position: sticky;
  top: 32px;
  z-index: 1020;
  margin-left: -20px;
  width: calc(100% + 20px);
}
@media screen and (max-width: 880px) {
  .acf-headerbar-field-editor {
    position: relative;
    top: 0;
    width: 100%;
    margin-left: 0;
    padding-right: 8px;
    padding-left: 8px;
  }
}
@media screen and (max-width: 640px) {
  .acf-headerbar-field-editor {
    position: relative;
    top: 46px;
  }
}
@media screen and (max-width: 880px) {
  .acf-headerbar-field-editor .acf-headerbar-inner {
    flex-wrap: wrap;
    justify-content: flex-start;
    align-content: flex-start;
    align-items: flex-start;
    width: 100%;
  }
  .acf-headerbar-field-editor .acf-headerbar-inner .acf-page-title {
    flex: 1 1 auto;
  }
  .acf-headerbar-field-editor .acf-headerbar-inner .acf-headerbar-actions {
    flex: 1 1 100%;
    margin-top: 8px;
    gap: 8px;
  }
  .acf-headerbar-field-editor .acf-headerbar-inner .acf-headerbar-actions .acf-btn {
    width: 100%;
    display: inline-flex;
    justify-content: center;
    margin: 0;
  }
}
.acf-headerbar-field-editor .acf-page-title {
  margin-right: 16px;
}

.rtl .acf-headerbar,
.rtl .acf-headerbar-field-editor {
  margin-left: 0;
  margin-right: -20px;
}
.rtl .acf-headerbar .acf-page-title,
.rtl .acf-headerbar-field-editor .acf-page-title {
  margin-left: 16px;
  margin-right: 0;
}
.rtl .acf-headerbar .acf-headerbar-actions .acf-btn,
.rtl .acf-headerbar-field-editor .acf-headerbar-actions .acf-btn {
  margin-left: 0;
  margin-right: 8px;
}

/*---------------------------------------------------------------------------------------------
*
*  ACF Buttons
*
*---------------------------------------------------------------------------------------------*/
.acf-btn {
  display: inline-flex;
  align-items: center;
  box-sizing: border-box;
  min-height: 40px;
  padding-top: 8px;
  padding-right: 16px;
  padding-bottom: 8px;
  padding-left: 16px;
  background-color: #0783BE;
  border-radius: 6px;
  border-width: 1px;
  border-style: solid;
  border-color: rgba(16, 24, 40, 0.2);
  text-decoration: none;
  color: #fff !important;
  transition: all 0.2s ease-in-out;
  transition-property: background, border, box-shadow;
}
.acf-btn:disabled {
  background-color: red;
}
.acf-btn:hover {
  background-color: #066998;
  color: #fff;
  cursor: pointer;
}
.acf-btn.acf-btn-sm {
  min-height: 32px;
  padding-top: 4px;
  padding-right: 12px;
  padding-bottom: 4px;
  padding-left: 12px;
}
.acf-btn.acf-btn-secondary {
  background-color: transparent;
  color: #0783BE !important;
  border-color: #0783BE;
}
.acf-btn.acf-btn-secondary:hover {
  background-color: #f3f9fc;
}
.acf-btn.acf-btn-tertiary {
  background-color: transparent;
  color: #667085 !important;
  border-color: #D0D5DD;
}
.acf-btn.acf-btn-tertiary:hover {
  color: #667085 !important;
  border-color: #98A2B3;
}
.acf-btn.acf-btn-clear {
  background-color: transparent;
  color: #667085 !important;
  border-color: transparent;
}
.acf-btn.acf-btn-clear:hover {
  color: #0783BE !important;
}
.acf-btn.acf-btn-pro {
  background: linear-gradient(90.52deg, #3E8BFF 0.44%, #A45CFF 113.3%);
  background-size: 180% 80%;
  background-position: 100% 0;
  transition: background-position 0.5s;
}
.acf-btn.acf-btn-pro:hover {
  background-position: 0 0;
}

/*---------------------------------------------------------------------------------------------
*
*  Button icons
*
*---------------------------------------------------------------------------------------------*/
.acf-btn i.acf-icon {
  width: 20px;
  height: 20px;
  -webkit-mask-size: 20px;
  mask-size: 20px;
  margin-right: 6px;
  margin-left: -4px;
}
.acf-btn.acf-btn-sm i.acf-icon {
  width: 16px;
  height: 16px;
  -webkit-mask-size: 16px;
  mask-size: 16px;
  margin-right: 6px;
  margin-left: -2px;
}

.rtl .acf-btn i.acf-icon {
  margin-right: -4px;
  margin-left: 6px;
}
.rtl .acf-btn.acf-btn-sm i.acf-icon {
  margin-right: -4px;
  margin-left: 2px;
}

/*---------------------------------------------------------------------------------------------
*
*  Delete field group button
*
*---------------------------------------------------------------------------------------------*/
.acf-btn.acf-delete-field-group:hover {
  background-color: #fbeded;
  border-color: #D13737 !important;
  color: #D13737 !important;
}

/*--------------------------------------------------------------------------------------------
*
*	Icon base styling
*
*--------------------------------------------------------------------------------------------*/
.acf-internal-post-type i.acf-icon,
.post-type-acf-field-group i.acf-icon {
  display: inline-flex;
  width: 20px;
  height: 20px;
  background-color: currentColor;
  border: none;
  border-radius: 0;
  -webkit-mask-size: contain;
  mask-size: contain;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  text-indent: 500%;
  white-space: nowrap;
  overflow: hidden;
}

/*--------------------------------------------------------------------------------------------
*
*	Icons
*
*--------------------------------------------------------------------------------------------*/
.acf-admin-page {
  /*--------------------------------------------------------------------------------------------
  *
  *	Inactive group icon
  *
  *--------------------------------------------------------------------------------------------*/
}
.acf-admin-page i.acf-field-setting-fc-delete, .acf-admin-page i.acf-field-setting-fc-duplicate {
  box-sizing: border-box;
  /* Auto layout */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px;
  cursor: pointer;
  width: 32px;
  height: 32px;
  /* Base / White */
  background: #FFFFFF;
  /* Gray/300 */
  border: 1px solid #D0D5DD;
  /* Elevation/01 */
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.1);
  border-radius: 6px;
  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 0;
}
.acf-admin-page i.acf-icon-plus {
  -webkit-mask-image: url("../../images/icons/icon-add.svg");
  mask-image: url("../../images/icons/icon-add.svg");
}
.acf-admin-page i.acf-icon-stars {
  -webkit-mask-image: url("../../images/icons/icon-stars.svg");
  mask-image: url("../../images/icons/icon-stars.svg");
}
.acf-admin-page i.acf-icon-help {
  -webkit-mask-image: url("../../images/icons/icon-help.svg");
  mask-image: url("../../images/icons/icon-help.svg");
}
.acf-admin-page i.acf-icon-key {
  -webkit-mask-image: url("../../images/icons/icon-key.svg");
  mask-image: url("../../images/icons/icon-key.svg");
}
.acf-admin-page i.acf-icon-regenerate {
  -webkit-mask-image: url("../../images/icons/icon-regenerate.svg");
  mask-image: url("../../images/icons/icon-regenerate.svg");
}
.acf-admin-page i.acf-icon-trash, .acf-admin-page button.acf-icon-trash {
  -webkit-mask-image: url("../../images/icons/icon-trash.svg");
  mask-image: url("../../images/icons/icon-trash.svg");
}
.acf-admin-page i.acf-icon-extended-menu, .acf-admin-page button.acf-icon-extended-menu {
  -webkit-mask-image: url("../../images/icons/icon-extended-menu.svg");
  mask-image: url("../../images/icons/icon-extended-menu.svg");
}
.acf-admin-page i.acf-icon.-duplicate, .acf-admin-page button.acf-icon-duplicate {
  -webkit-mask-image: url("../../images/field-type-icons/icon-field-clone.svg");
  mask-image: url("../../images/field-type-icons/icon-field-clone.svg");
}
.acf-admin-page i.acf-icon.-duplicate:before, .acf-admin-page i.acf-icon.-duplicate:after, .acf-admin-page button.acf-icon-duplicate:before, .acf-admin-page button.acf-icon-duplicate:after {
  content: none;
}
.acf-admin-page i.acf-icon-arrow-right {
  -webkit-mask-image: url("../../images/icons/icon-arrow-right.svg");
  mask-image: url("../../images/icons/icon-arrow-right.svg");
}
.acf-admin-page i.acf-icon-arrow-up-right {
  -webkit-mask-image: url("../../images/icons/icon-arrow-up-right.svg");
  mask-image: url("../../images/icons/icon-arrow-up-right.svg");
}
.acf-admin-page i.acf-icon-arrow-left {
  -webkit-mask-image: url("../../images/icons/icon-arrow-left.svg");
  mask-image: url("../../images/icons/icon-arrow-left.svg");
}
.acf-admin-page i.acf-icon-chevron-right,
.acf-admin-page .acf-icon.-right {
  -webkit-mask-image: url("../../images/icons/icon-chevron-right.svg");
  mask-image: url("../../images/icons/icon-chevron-right.svg");
}
.acf-admin-page i.acf-icon-chevron-left,
.acf-admin-page .acf-icon.-left {
  -webkit-mask-image: url("../../images/icons/icon-chevron-left.svg");
  mask-image: url("../../images/icons/icon-chevron-left.svg");
}
.acf-admin-page i.acf-icon-key-solid {
  -webkit-mask-image: url("../../images/icons/icon-key-solid.svg");
  mask-image: url("../../images/icons/icon-key-solid.svg");
}
.acf-admin-page i.acf-icon-globe,
.acf-admin-page .acf-icon.-globe {
  -webkit-mask-image: url("../../images/icons/icon-globe.svg");
  mask-image: url("../../images/icons/icon-globe.svg");
}
.acf-admin-page i.acf-icon-image,
.acf-admin-page .acf-icon.-picture {
  -webkit-mask-image: url("../../images/field-type-icons/icon-field-image.svg");
  mask-image: url("../../images/field-type-icons/icon-field-image.svg");
}
.acf-admin-page i.acf-icon-warning {
  -webkit-mask-image: url("../../images/icons/icon-warning-alt.svg");
  mask-image: url("../../images/icons/icon-warning-alt.svg");
}
.acf-admin-page i.acf-icon-warning-red {
  -webkit-mask-image: url("../../images/icons/icon-warning-alt-red.svg");
  mask-image: url("../../images/icons/icon-warning-alt-red.svg");
}
.acf-admin-page i.acf-icon-dots-grid {
  -webkit-mask-image: url("../../images/icons/icon-dots-grid.svg");
  mask-image: url("../../images/icons/icon-dots-grid.svg");
}
.acf-admin-page i.acf-icon-play {
  -webkit-mask-image: url("../../images/icons/icon-play.svg");
  mask-image: url("../../images/icons/icon-play.svg");
}
.acf-admin-page i.acf-icon-lock {
  -webkit-mask-image: url("../../images/icons/icon-lock.svg");
  mask-image: url("../../images/icons/icon-lock.svg");
}
.acf-admin-page i.acf-icon-document {
  -webkit-mask-image: url("../../images/icons/icon-document.svg");
  mask-image: url("../../images/icons/icon-document.svg");
}
.acf-admin-page .post-type-acf-field-group .post-state,
.acf-admin-page .acf-internal-post-type .post-state {
  font-weight: normal;
}
.acf-admin-page .post-type-acf-field-group .post-state .dashicons.dashicons-hidden,
.acf-admin-page .acf-internal-post-type .post-state .dashicons.dashicons-hidden {
  display: inline-flex;
  width: 18px;
  height: 18px;
  background-color: #98A2B3;
  border: none;
  border-radius: 0;
  -webkit-mask-size: 18px;
  mask-size: 18px;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  -webkit-mask-image: url("../../images/icons/icon-hidden.svg");
  mask-image: url("../../images/icons/icon-hidden.svg");
}
.acf-admin-page .post-type-acf-field-group .post-state .dashicons.dashicons-hidden:before,
.acf-admin-page .acf-internal-post-type .post-state .dashicons.dashicons-hidden:before {
  display: none;
}

/*--------------------------------------------------------------------------------------------
*
*	Edit field group page postbox header icons
*
*--------------------------------------------------------------------------------------------*/
#acf-field-group-fields .postbox-header h2,
#acf-field-group-fields .postbox-header h3,
#acf-field-group-fields .acf-sub-field-list-header h2,
#acf-field-group-fields .acf-sub-field-list-header h3,
#acf-field-group-options .postbox-header h2,
#acf-field-group-options .postbox-header h3,
#acf-field-group-options .acf-sub-field-list-header h2,
#acf-field-group-options .acf-sub-field-list-header h3,
#acf-advanced-settings .postbox-header h2,
#acf-advanced-settings .postbox-header h3,
#acf-advanced-settings .acf-sub-field-list-header h2,
#acf-advanced-settings .acf-sub-field-list-header h3 {
  display: inline-flex;
  justify-content: flex-start;
  align-content: stretch;
  align-items: center;
}
#acf-field-group-fields .postbox-header h2:before,
#acf-field-group-fields .postbox-header h3:before,
#acf-field-group-fields .acf-sub-field-list-header h2:before,
#acf-field-group-fields .acf-sub-field-list-header h3:before,
#acf-field-group-options .postbox-header h2:before,
#acf-field-group-options .postbox-header h3:before,
#acf-field-group-options .acf-sub-field-list-header h2:before,
#acf-field-group-options .acf-sub-field-list-header h3:before,
#acf-advanced-settings .postbox-header h2:before,
#acf-advanced-settings .postbox-header h3:before,
#acf-advanced-settings .acf-sub-field-list-header h2:before,
#acf-advanced-settings .acf-sub-field-list-header h3:before {
  content: "";
  display: inline-block;
  width: 20px;
  height: 20px;
  margin-right: 8px;
  background-color: #98A2B3;
  border: none;
  border-radius: 0;
  -webkit-mask-size: contain;
  mask-size: contain;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
}

.rtl #acf-field-group-fields .postbox-header h2:before,
.rtl #acf-field-group-fields .postbox-header h3:before,
.rtl #acf-field-group-fields .acf-sub-field-list-header h2:before,
.rtl #acf-field-group-fields .acf-sub-field-list-header h3:before,
.rtl #acf-field-group-options .postbox-header h2:before,
.rtl #acf-field-group-options .postbox-header h3:before,
.rtl #acf-field-group-options .acf-sub-field-list-header h2:before,
.rtl #acf-field-group-options .acf-sub-field-list-header h3:before {
  margin-right: 0;
  margin-left: 8px;
}

#acf-field-group-fields .postbox-header h2:before,
h3.acf-sub-field-list-title:before,
.acf-link-field-groups-popup h3:before {
  -webkit-mask-image: url("../../images/icons/icon-fields.svg");
  mask-image: url("../../images/icons/icon-fields.svg");
}

.acf-create-options-page-popup h3:before {
  -webkit-mask-image: url("../../images/icons/icon-sliders.svg");
  mask-image: url("../../images/icons/icon-sliders.svg");
}

#acf-field-group-options .postbox-header h2:before {
  -webkit-mask-image: url("../../images/icons/icon-settings.svg");
  mask-image: url("../../images/icons/icon-settings.svg");
}

.acf-field-setting-fc_layout .acf-field-settings-fc_head label:before {
  -webkit-mask-image: url("../../images/icons/icon-layout.svg");
  mask-image: url("../../images/icons/icon-layout.svg");
}

.acf-admin-single-post-type #acf-advanced-settings .postbox-header h2:before,
.acf-admin-single-taxonomy #acf-advanced-settings .postbox-header h2:before,
.acf-admin-single-options-page #acf-advanced-settings .postbox-header h2:before {
  -webkit-mask-image: url("../../images/icons/icon-post-type.svg");
  mask-image: url("../../images/icons/icon-post-type.svg");
}

.acf-field-setting-fc_layout .acf-field-settings-fc_head:hover .reorder-layout:before {
  width: 20px;
  height: 11px;
  background-color: #475467 !important;
  -webkit-mask-image: url("../../images/icons/icon-draggable.svg");
  mask-image: url("../../images/icons/icon-draggable.svg");
}

/*--------------------------------------------------------------------------------------------
*
*	Postbox expand / collapse icon
*
*--------------------------------------------------------------------------------------------*/
.post-type-acf-field-group .postbox-header .handle-actions,
.post-type-acf-field-group #acf-field-group-fields .postbox-header .handle-actions,
.post-type-acf-field-group #acf-field-group-options .postbox-header .handle-actions,
.post-type-acf-field-group .postbox .postbox-header .handle-actions,
.acf-admin-single-post-type #acf-advanced-settings .postbox-header .handle-actions,
.acf-admin-single-taxonomy #acf-advanced-settings .postbox-header .handle-actions,
.acf-admin-single-options-page #acf-advanced-settings .postbox-header .handle-actions {
  display: flex;
}
.post-type-acf-field-group .postbox-header .handle-actions .toggle-indicator:before,
.post-type-acf-field-group #acf-field-group-fields .postbox-header .handle-actions .toggle-indicator:before,
.post-type-acf-field-group #acf-field-group-options .postbox-header .handle-actions .toggle-indicator:before,
.post-type-acf-field-group .postbox .postbox-header .handle-actions .toggle-indicator:before,
.acf-admin-single-post-type #acf-advanced-settings .postbox-header .handle-actions .toggle-indicator:before,
.acf-admin-single-taxonomy #acf-advanced-settings .postbox-header .handle-actions .toggle-indicator:before,
.acf-admin-single-options-page #acf-advanced-settings .postbox-header .handle-actions .toggle-indicator:before {
  content: "";
  display: inline-flex;
  width: 20px;
  height: 20px;
  background-color: currentColor;
  border: none;
  border-radius: 0;
  -webkit-mask-size: contain;
  mask-size: contain;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  -webkit-mask-image: url("../../images/icons/icon-chevron-up.svg");
  mask-image: url("../../images/icons/icon-chevron-up.svg");
}
.post-type-acf-field-group.closed .postbox-header .handle-actions .toggle-indicator:before,
.post-type-acf-field-group #acf-field-group-fields.closed .postbox-header .handle-actions .toggle-indicator:before,
.post-type-acf-field-group #acf-field-group-options.closed .postbox-header .handle-actions .toggle-indicator:before,
.post-type-acf-field-group .postbox.closed .postbox-header .handle-actions .toggle-indicator:before,
.acf-admin-single-post-type #acf-advanced-settings.closed .postbox-header .handle-actions .toggle-indicator:before,
.acf-admin-single-taxonomy #acf-advanced-settings.closed .postbox-header .handle-actions .toggle-indicator:before,
.acf-admin-single-options-page #acf-advanced-settings.closed .postbox-header .handle-actions .toggle-indicator:before {
  -webkit-mask-image: url("../../images/icons/icon-chevron-down.svg");
  mask-image: url("../../images/icons/icon-chevron-down.svg");
}

/*---------------------------------------------------------------------------------------------
*
* Tools & updates page heading icons
*
*---------------------------------------------------------------------------------------------*/
.post-type-acf-field-group #acf-admin-tool-export h2,
.post-type-acf-field-group #acf-admin-tool-export h3,
.post-type-acf-field-group #acf-admin-tool-import h2,
.post-type-acf-field-group #acf-admin-tool-import h3,
.post-type-acf-field-group #acf-license-information h2,
.post-type-acf-field-group #acf-license-information h3,
.post-type-acf-field-group #acf-update-information h2,
.post-type-acf-field-group #acf-update-information h3 {
  display: inline-flex;
  justify-content: flex-start;
  align-content: stretch;
  align-items: center;
}
.post-type-acf-field-group #acf-admin-tool-export h2:before,
.post-type-acf-field-group #acf-admin-tool-export h3:before,
.post-type-acf-field-group #acf-admin-tool-import h2:before,
.post-type-acf-field-group #acf-admin-tool-import h3:before,
.post-type-acf-field-group #acf-license-information h2:before,
.post-type-acf-field-group #acf-license-information h3:before,
.post-type-acf-field-group #acf-update-information h2:before,
.post-type-acf-field-group #acf-update-information h3:before {
  content: "";
  display: inline-block;
  width: 20px;
  height: 20px;
  margin-right: 8px;
  background-color: #98A2B3;
  border: none;
  border-radius: 0;
  -webkit-mask-size: contain;
  mask-size: contain;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
}
.post-type-acf-field-group.rtl #acf-admin-tool-export h2:before,
.post-type-acf-field-group.rtl #acf-admin-tool-export h3:before,
.post-type-acf-field-group.rtl #acf-admin-tool-import h2:before,
.post-type-acf-field-group.rtl #acf-admin-tool-import h3:before,
.post-type-acf-field-group.rtl #acf-license-information h2:before,
.post-type-acf-field-group.rtl #acf-license-information h3:before,
.post-type-acf-field-group.rtl #acf-update-information h2:before,
.post-type-acf-field-group.rtl #acf-update-information h3:before {
  margin-right: 0;
  margin-left: 8px;
}

.post-type-acf-field-group #acf-admin-tool-export h2:before {
  -webkit-mask-image: url("../../images/icons/icon-export.svg");
  mask-image: url("../../images/icons/icon-export.svg");
}

.post-type-acf-field-group #acf-admin-tool-import h2:before {
  -webkit-mask-image: url("../../images/icons/icon-import.svg");
  mask-image: url("../../images/icons/icon-import.svg");
}

.post-type-acf-field-group #acf-license-information h3:before {
  -webkit-mask-image: url("../../images/icons/icon-key.svg");
  mask-image: url("../../images/icons/icon-key.svg");
}

.post-type-acf-field-group #acf-update-information h3:before {
  -webkit-mask-image: url("../../images/icons/icon-info.svg");
  mask-image: url("../../images/icons/icon-info.svg");
}

/*--------------------------------------------------------------------------------------------
*
*	Admin field icons
*
*--------------------------------------------------------------------------------------------*/
.acf-admin-single-field-group .acf-input .acf-icon {
  width: 18px;
  height: 18px;
}

/*--------------------------------------------------------------------------------------------
*
*	Field type icon base styling
*
*--------------------------------------------------------------------------------------------*/
.field-type-icon {
  box-sizing: border-box;
  display: inline-flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 24px;
  height: 24px;
  top: -4px;
  background-color: #EBF5FA;
  border-width: 1px;
  border-style: solid;
  border-color: #A5D2E7;
  border-radius: 100%;
}
.field-type-icon:before {
  content: "";
  width: 14px;
  height: 14px;
  position: relative;
  background-color: #0783BE;
  -webkit-mask-size: cover;
  mask-size: cover;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  -webkit-mask-image: url("../../images/field-type-icons/icon-field-default.svg");
  mask-image: url("../../images/field-type-icons/icon-field-default.svg");
}

/*--------------------------------------------------------------------------------------------
*
*	Field type icons
*
*--------------------------------------------------------------------------------------------*/
.field-type-icon.field-type-icon-text:before {
  -webkit-mask-image: url("../../images/field-type-icons/icon-field-text.svg");
  mask-image: url("../../images/field-type-icons/icon-field-text.svg");
}

.field-type-icon.field-type-icon-textarea:before {
  -webkit-mask-image: url("../../images/field-type-icons/icon-field-textarea.svg");
  mask-image: url("../../images/field-type-icons/icon-field-textarea.svg");
}

.field-type-icon.field-type-icon-textarea:before {
  -webkit-mask-image: url("../../images/field-type-icons/icon-field-textarea.svg");
  mask-image: url("../../images/field-type-icons/icon-field-textarea.svg");
}

.field-type-icon.field-type-icon-number:before {
  -webkit-mask-image: url("../../images/field-type-icons/icon-field-number.svg");
  mask-image: url("../../images/field-type-icons/icon-field-number.svg");
}

.field-type-icon.field-type-icon-range:before {
  -webkit-mask-image: url("../../images/field-type-icons/icon-field-range.svg");
  mask-image: url("../../images/field-type-icons/icon-field-range.svg");
}

.field-type-icon.field-type-icon-email:before {
  -webkit-mask-image: url("../../images/field-type-icons/icon-field-email.svg");
  mask-image: url("../../images/field-type-icons/icon-field-email.svg");
}

.field-type-icon.field-type-icon-url:before {
  -webkit-mask-image: url("../../images/field-type-icons/icon-field-url.svg");
  mask-image: url("../../images/field-type-icons/icon-field-url.svg");
}

.field-type-icon.field-type-icon-password:before {
  -webkit-mask-image: url("../../images/field-type-icons/icon-field-password.svg");
  mask-image: url("../../images/field-type-icons/icon-field-password.svg");
}

.field-type-icon.field-type-icon-image:before {
  -webkit-mask-image: url("../../images/field-type-icons/icon-field-image.svg");
  mask-image: url("../../images/field-type-icons/icon-field-image.svg");
}

.field-type-icon.field-type-icon-file:before {
  -webkit-mask-image: url("../../images/field-type-icons/icon-field-file.svg");
  mask-image: url("../../images/field-type-icons/icon-field-file.svg");
}

.field-type-icon.field-type-icon-wysiwyg:before {
  -webkit-mask-image: url("../../images/field-type-icons/icon-field-wysiwyg.svg");
  mask-image: url("../../images/field-type-icons/icon-field-wysiwyg.svg");
}

.field-type-icon.field-type-icon-oembed:before {
  -webkit-mask-image: url("../../images/field-type-icons/icon-field-oembed.svg");
  mask-image: url("../../images/field-type-icons/icon-field-oembed.svg");
}

.field-type-icon.field-type-icon-gallery:before {
  -webkit-mask-image: url("../../images/field-type-icons/icon-field-gallery.svg");
  mask-image: url("../../images/field-type-icons/icon-field-gallery.svg");
}

.field-type-icon.field-type-icon-select:before {
  -webkit-mask-image: url("../../images/field-type-icons/icon-field-select.svg");
  mask-image: url("../../images/field-type-icons/icon-field-select.svg");
}

.field-type-icon.field-type-icon-checkbox:before {
  -webkit-mask-image: url("../../images/field-type-icons/icon-field-checkbox.svg");
  mask-image: url("../../images/field-type-icons/icon-field-checkbox.svg");
}

.field-type-icon.field-type-icon-radio:before {
  -webkit-mask-image: url("../../images/field-type-icons/icon-field-radio.svg");
  mask-image: url("../../images/field-type-icons/icon-field-radio.svg");
}

.field-type-icon.field-type-icon-button-group:before {
  -webkit-mask-image: url("../../images/field-type-icons/icon-field-button-group.svg");
  mask-image: url("../../images/field-type-icons/icon-field-button-group.svg");
}

.field-type-icon.field-type-icon-true-false:before {
  -webkit-mask-image: url("../../images/field-type-icons/icon-field-true-false.svg");
  mask-image: url("../../images/field-type-icons/icon-field-true-false.svg");
}

.field-type-icon.field-type-icon-link:before {
  -webkit-mask-image: url("../../images/field-type-icons/icon-field-link.svg");
  mask-image: url("../../images/field-type-icons/icon-field-link.svg");
}

.field-type-icon.field-type-icon-post-object:before {
  -webkit-mask-image: url("../../images/field-type-icons/icon-field-post-object.svg");
  mask-image: url("../../images/field-type-icons/icon-field-post-object.svg");
}

.field-type-icon.field-type-icon-page-link:before {
  -webkit-mask-image: url("../../images/field-type-icons/icon-field-page-link.svg");
  mask-image: url("../../images/field-type-icons/icon-field-page-link.svg");
}

.field-type-icon.field-type-icon-relationship:before {
  -webkit-mask-image: url("../../images/field-type-icons/icon-field-relationship.svg");
  mask-image: url("../../images/field-type-icons/icon-field-relationship.svg");
}

.field-type-icon.field-type-icon-taxonomy:before {
  -webkit-mask-image: url("../../images/field-type-icons/icon-field-taxonomy.svg");
  mask-image: url("../../images/field-type-icons/icon-field-taxonomy.svg");
}

.field-type-icon.field-type-icon-user:before {
  -webkit-mask-image: url("../../images/field-type-icons/icon-field-user.svg");
  mask-image: url("../../images/field-type-icons/icon-field-user.svg");
}

.field-type-icon.field-type-icon-google-map:before {
  -webkit-mask-image: url("../../images/field-type-icons/icon-field-google-map.svg");
  mask-image: url("../../images/field-type-icons/icon-field-google-map.svg");
}

.field-type-icon.field-type-icon-date-picker:before {
  -webkit-mask-image: url("../../images/field-type-icons/icon-field-date-picker.svg");
  mask-image: url("../../images/field-type-icons/icon-field-date-picker.svg");
}

.field-type-icon.field-type-icon-date-time-picker:before {
  -webkit-mask-image: url("../../images/field-type-icons/icon-field-date-time-picker.svg");
  mask-image: url("../../images/field-type-icons/icon-field-date-time-picker.svg");
}

.field-type-icon.field-type-icon-time-picker:before {
  -webkit-mask-image: url("../../images/field-type-icons/icon-field-time-picker.svg");
  mask-image: url("../../images/field-type-icons/icon-field-time-picker.svg");
}

.field-type-icon.field-type-icon-color-picker:before {
  -webkit-mask-image: url("../../images/field-type-icons/icon-field-color-picker.svg");
  mask-image: url("../../images/field-type-icons/icon-field-color-picker.svg");
}

.field-type-icon.field-type-icon-message:before {
  -webkit-mask-image: url("../../images/field-type-icons/icon-field-message.svg");
  mask-image: url("../../images/field-type-icons/icon-field-message.svg");
}

.field-type-icon.field-type-icon-accordion:before {
  -webkit-mask-image: url("../../images/field-type-icons/icon-field-accordion.svg");
  mask-image: url("../../images/field-type-icons/icon-field-accordion.svg");
}

.field-type-icon.field-type-icon-tab:before {
  -webkit-mask-image: url("../../images/field-type-icons/icon-field-tab.svg");
  mask-image: url("../../images/field-type-icons/icon-field-tab.svg");
}

.field-type-icon.field-type-icon-group:before {
  -webkit-mask-image: url("../../images/field-type-icons/icon-field-group.svg");
  mask-image: url("../../images/field-type-icons/icon-field-group.svg");
}

.field-type-icon.field-type-icon-repeater:before {
  -webkit-mask-image: url("../../images/field-type-icons/icon-field-repeater.svg");
  mask-image: url("../../images/field-type-icons/icon-field-repeater.svg");
}

.field-type-icon.field-type-icon-flexible-content:before {
  -webkit-mask-image: url("../../images/field-type-icons/icon-field-flexible-content.svg");
  mask-image: url("../../images/field-type-icons/icon-field-flexible-content.svg");
}

.field-type-icon.field-type-icon-clone:before {
  -webkit-mask-image: url("../../images/field-type-icons/icon-field-clone.svg");
  mask-image: url("../../images/field-type-icons/icon-field-clone.svg");
}

/*---------------------------------------------------------------------------------------------
*
* Tools page layout
*
*---------------------------------------------------------------------------------------------*/
#acf-admin-tools .postbox-header {
  display: none;
}
#acf-admin-tools .acf-meta-box-wrap.-grid {
  margin-top: 0;
  margin-right: 0;
  margin-bottom: 0;
  margin-left: 0;
}
#acf-admin-tools .acf-meta-box-wrap.-grid .postbox {
  width: 100%;
  clear: none;
  float: none;
  margin-bottom: 0;
}
@media screen and (max-width: 880px) {
  #acf-admin-tools .acf-meta-box-wrap.-grid .postbox {
    flex: 1 1 100%;
  }
}
#acf-admin-tools .acf-meta-box-wrap.-grid .postbox:nth-child(odd) {
  margin-left: 0;
}
#acf-admin-tools .meta-box-sortables {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(1, 1fr);
  grid-column-gap: 32px;
  grid-row-gap: 32px;
}
@media screen and (max-width: 880px) {
  #acf-admin-tools .meta-box-sortables {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-content: flex-start;
    align-items: center;
    grid-column-gap: 8px;
    grid-row-gap: 8px;
  }
}

/*---------------------------------------------------------------------------------------------
*
* Tools export pages
*
*---------------------------------------------------------------------------------------------*/
#acf-admin-tools.tool-export .inside {
  margin: 0;
}
#acf-admin-tools.tool-export .acf-postbox-header {
  margin-bottom: 24px;
}
#acf-admin-tools.tool-export .acf-postbox-main {
  border: none;
  margin: 0;
  padding-top: 0;
  padding-right: 24px;
  padding-bottom: 0;
  padding-left: 0;
}
#acf-admin-tools.tool-export .acf-postbox-columns {
  margin-top: 0;
  margin-right: 280px;
  margin-bottom: 0;
  margin-left: 0;
  padding: 0;
}
#acf-admin-tools.tool-export .acf-postbox-columns .acf-postbox-side {
  padding: 0;
}
#acf-admin-tools.tool-export .acf-postbox-columns .acf-postbox-side .acf-panel {
  margin: 0;
  padding: 0;
}
#acf-admin-tools.tool-export .acf-postbox-columns .acf-postbox-side:before {
  display: none;
}
#acf-admin-tools.tool-export .acf-postbox-columns .acf-postbox-side .acf-btn {
  display: block;
  width: 100%;
  text-align: center;
}
#acf-admin-tools.tool-export .meta-box-sortables {
  display: block;
}
#acf-admin-tools.tool-export .acf-panel {
  border: none;
}
#acf-admin-tools.tool-export .acf-panel h3 {
  margin: 0;
  padding: 0;
  color: #344054;
}
#acf-admin-tools.tool-export .acf-panel h3:before {
  display: none;
}
#acf-admin-tools.tool-export .acf-checkbox-list {
  margin-top: 16px;
  border-width: 1px;
  border-style: solid;
  border-color: #D0D5DD;
  border-radius: 6px;
}
#acf-admin-tools.tool-export .acf-checkbox-list li {
  display: inline-flex;
  box-sizing: border-box;
  width: 100%;
  height: 48px;
  align-items: center;
  margin: 0;
  padding-right: 12px;
  padding-left: 12px;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: #EAECF0;
}
#acf-admin-tools.tool-export .acf-checkbox-list li:last-child {
  border-bottom: none;
}

/*---------------------------------------------------------------------------------------------
*
*  Updates layout
*
*---------------------------------------------------------------------------------------------*/
.acf-settings-wrap.acf-updates {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-content: flex-start;
  align-items: flex-start;
}

.custom-fields_page_acf-settings-updates .acf-admin-notice,
.custom-fields_page_acf-settings-updates .acf-upgrade-notice,
.custom-fields_page_acf-settings-updates .notice {
  flex: 1 1 100%;
}

/*---------------------------------------------------------------------------------------------
*
*  ACF Box
*
*---------------------------------------------------------------------------------------------*/
.acf-settings-wrap.acf-updates .acf-box {
  margin-top: 0;
  margin-right: 0;
  margin-bottom: 0;
  margin-left: 0;
}
.acf-settings-wrap.acf-updates .acf-box .inner {
  padding-top: 24px;
  padding-right: 24px;
  padding-bottom: 24px;
  padding-left: 24px;
}
@media screen and (max-width: 880px) {
  .acf-settings-wrap.acf-updates .acf-box {
    flex: 1 1 100%;
  }
}

/*---------------------------------------------------------------------------------------------
*
*  Notices
*
*---------------------------------------------------------------------------------------------*/
.acf-settings-wrap.acf-updates .acf-admin-notice {
  flex: 1 1 100%;
  margin-top: 16px;
  margin-right: 0;
  margin-left: 0;
}

/*---------------------------------------------------------------------------------------------
*
* License information
*
*---------------------------------------------------------------------------------------------*/
#acf-license-information {
  flex: 1 1 65%;
  margin-right: 32px;
}
@media screen and (max-width: 1024px) {
  #acf-license-information {
    margin-right: 0;
    margin-bottom: 32px;
  }
}
#acf-license-information .acf-activation-form {
  margin-top: 24px;
}
#acf-license-information label {
  font-weight: 500;
}
#acf-license-information .acf-input-wrap {
  margin-top: 8px;
  margin-bottom: 24px;
}
#acf-license-information #acf_pro_license {
  width: 100%;
}

/*---------------------------------------------------------------------------------------------
*
*  Update information table
*
*---------------------------------------------------------------------------------------------*/
#acf-update-information {
  flex: 1 1 35%;
  max-width: calc(35% - 32px);
}
#acf-update-information .form-table th,
#acf-update-information .form-table td {
  padding-top: 0;
  padding-right: 0;
  padding-bottom: 24px;
  padding-left: 0;
  color: #344054;
}
#acf-update-information .acf-update-changelog {
  margin-top: 8px;
  margin-bottom: 24px;
  padding-top: 8px;
  border-top-width: 1px;
  border-top-style: solid;
  border-top-color: #EAECF0;
  color: #344054;
}
#acf-update-information .acf-update-changelog h4 {
  margin-bottom: 0;
}
#acf-update-information .acf-update-changelog p {
  margin-top: 0;
  margin-bottom: 16px;
}
#acf-update-information .acf-update-changelog p:last-of-type {
  margin-bottom: 0;
}
#acf-update-information .acf-update-changelog p em {
  color: #667085;
}
#acf-update-information .acf-btn {
  display: inline-flex;
}

/*--------------------------------------------------------------------------------------------
*
*	Header pro upgrade button
*
*--------------------------------------------------------------------------------------------*/
.acf-admin-toolbar a.acf-admin-toolbar-upgrade-btn {
  display: inline-flex;
  align-items: center;
  align-self: stretch;
  padding-top: 0;
  padding-right: 16px;
  padding-bottom: 0;
  padding-left: 16px;
  background: linear-gradient(90.52deg, #3E8BFF 0.44%, #A45CFF 113.3%);
  box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.16);
  background-size: 180% 80%;
  background-position: 100% 0;
  transition: background-position 0.5s;
  border-radius: 6px;
  text-decoration: none;
}
@media screen and (max-width: 768px) {
  .acf-admin-toolbar a.acf-admin-toolbar-upgrade-btn {
    display: none;
  }
}
.acf-admin-toolbar a.acf-admin-toolbar-upgrade-btn:hover {
  background-position: 0 0;
}
.acf-admin-toolbar a.acf-admin-toolbar-upgrade-btn:focus {
  border: none;
  outline: none;
  box-shadow: none;
}
.acf-admin-toolbar a.acf-admin-toolbar-upgrade-btn p {
  margin: 0;
  padding-top: 8px;
  padding-bottom: 8px;
  font-weight: normal;
  text-transform: none;
  color: #fff;
}
.acf-admin-toolbar a.acf-admin-toolbar-upgrade-btn .acf-icon {
  width: 18px;
  height: 18px;
  margin-right: 6px;
  margin-left: -2px;
  background-color: #F9FAFB;
}

/*--------------------------------------------------------------------------------------------
*
*  Upsell block
*
*--------------------------------------------------------------------------------------------*/
.acf-admin-page #tmpl-acf-field-group-pro-features,
.acf-admin-page #acf-field-group-pro-features {
  display: none;
  align-items: center;
  min-height: 120px;
  background-color: #121833;
  background-image: url(../../images/pro-upgrade-grid-bg.svg), url(../../images/pro-upgrade-overlay.svg);
  background-repeat: repeat, no-repeat;
  background-size: 1224px, 1880px;
  background-position: left top, -520px -680px;
  color: #EAECF0;
  border-radius: 8px;
  margin-top: 24px;
  margin-bottom: 24px;
}
@media screen and (max-width: 768px) {
  .acf-admin-page #tmpl-acf-field-group-pro-features,
  .acf-admin-page #acf-field-group-pro-features {
    background-size: 1024px, 980px;
    background-position: left top, -500px -200px;
  }
}
@media screen and (max-width: 1200px) {
  .acf-admin-page #tmpl-acf-field-group-pro-features,
  .acf-admin-page #acf-field-group-pro-features {
    background-size: 1024px, 1880px;
    background-position: left top, -520px -300px;
  }
}
.acf-admin-page #tmpl-acf-field-group-pro-features .postbox-header,
.acf-admin-page #acf-field-group-pro-features .postbox-header {
  display: none;
}
.acf-admin-page #tmpl-acf-field-group-pro-features .inside,
.acf-admin-page #acf-field-group-pro-features .inside {
  width: 100%;
  border: none;
  padding: 0;
}
.acf-admin-page #tmpl-acf-field-group-pro-features .acf-field-group-pro-features-wrapper,
.acf-admin-page #acf-field-group-pro-features .acf-field-group-pro-features-wrapper {
  display: flex;
  justify-content: center;
  align-content: stretch;
  align-items: center;
  gap: 96px;
  height: 358px;
  max-width: 950px;
  margin: 0 auto;
  padding: 0 35px;
}
@media screen and (max-width: 1200px) {
  .acf-admin-page #tmpl-acf-field-group-pro-features .acf-field-group-pro-features-wrapper,
  .acf-admin-page #acf-field-group-pro-features .acf-field-group-pro-features-wrapper {
    gap: 48px;
  }
}
@media screen and (max-width: 768px) {
  .acf-admin-page #tmpl-acf-field-group-pro-features .acf-field-group-pro-features-wrapper,
  .acf-admin-page #acf-field-group-pro-features .acf-field-group-pro-features-wrapper {
    gap: 0;
  }
}
.acf-admin-page #tmpl-acf-field-group-pro-features .acf-field-group-pro-features-wrapper .acf-field-group-pro-features-title,
.acf-admin-page #tmpl-acf-field-group-pro-features .acf-field-group-pro-features-wrapper .acf-field-group-pro-features-title-sm,
.acf-admin-page #acf-field-group-pro-features .acf-field-group-pro-features-wrapper .acf-field-group-pro-features-title,
.acf-admin-page #acf-field-group-pro-features .acf-field-group-pro-features-wrapper .acf-field-group-pro-features-title-sm {
  font-weight: 590;
  line-height: 150%;
}
.acf-admin-page #tmpl-acf-field-group-pro-features .acf-field-group-pro-features-wrapper .acf-field-group-pro-features-title .acf-pro-label,
.acf-admin-page #tmpl-acf-field-group-pro-features .acf-field-group-pro-features-wrapper .acf-field-group-pro-features-title-sm .acf-pro-label,
.acf-admin-page #acf-field-group-pro-features .acf-field-group-pro-features-wrapper .acf-field-group-pro-features-title .acf-pro-label,
.acf-admin-page #acf-field-group-pro-features .acf-field-group-pro-features-wrapper .acf-field-group-pro-features-title-sm .acf-pro-label {
  font-weight: normal;
  margin-top: -4px;
  margin-left: 2px;
  vertical-align: middle;
  height: 22px;
}
.acf-admin-page #tmpl-acf-field-group-pro-features .acf-field-group-pro-features-wrapper .acf-field-group-pro-features-title-sm,
.acf-admin-page #acf-field-group-pro-features .acf-field-group-pro-features-wrapper .acf-field-group-pro-features-title-sm {
  display: none !important;
  font-size: 18px;
}
.acf-admin-page #tmpl-acf-field-group-pro-features .acf-field-group-pro-features-wrapper .acf-field-group-pro-features-title-sm .acf-pro-label,
.acf-admin-page #acf-field-group-pro-features .acf-field-group-pro-features-wrapper .acf-field-group-pro-features-title-sm .acf-pro-label {
  font-size: 10px;
  height: 20px;
}
@media screen and (max-width: 768px) {
  .acf-admin-page #tmpl-acf-field-group-pro-features .acf-field-group-pro-features-wrapper .acf-field-group-pro-features-title-sm,
  .acf-admin-page #acf-field-group-pro-features .acf-field-group-pro-features-wrapper .acf-field-group-pro-features-title-sm {
    width: 100%;
    text-align: center;
  }
}
@media screen and (max-width: 768px) {
  .acf-admin-page #tmpl-acf-field-group-pro-features .acf-field-group-pro-features-wrapper,
  .acf-admin-page #acf-field-group-pro-features .acf-field-group-pro-features-wrapper {
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-content: flex-start;
    align-items: flex-start;
    padding: 32px 32px 0 32px;
    height: unset;
  }
  .acf-admin-page #tmpl-acf-field-group-pro-features .acf-field-group-pro-features-wrapper .acf-field-group-pro-features-title-sm,
  .acf-admin-page #acf-field-group-pro-features .acf-field-group-pro-features-wrapper .acf-field-group-pro-features-title-sm {
    display: block !important;
    margin-bottom: 24px;
  }
}
.acf-admin-page #tmpl-acf-field-group-pro-features .acf-field-group-pro-features-wrapper .acf-field-group-pro-features-content,
.acf-admin-page #acf-field-group-pro-features .acf-field-group-pro-features-wrapper .acf-field-group-pro-features-content {
  display: flex;
  flex-direction: column;
  width: 416px;
}
.acf-admin-page #tmpl-acf-field-group-pro-features .acf-field-group-pro-features-wrapper .acf-field-group-pro-features-content .acf-field-group-pro-features-desc,
.acf-admin-page #acf-field-group-pro-features .acf-field-group-pro-features-wrapper .acf-field-group-pro-features-content .acf-field-group-pro-features-desc {
  margin-top: 8px;
  margin-bottom: 24px;
  font-size: 15px;
  font-weight: 300;
  color: #D0D5DD;
}
@media screen and (max-width: 768px) {
  .acf-admin-page #tmpl-acf-field-group-pro-features .acf-field-group-pro-features-wrapper .acf-field-group-pro-features-content,
  .acf-admin-page #acf-field-group-pro-features .acf-field-group-pro-features-wrapper .acf-field-group-pro-features-content {
    width: 100%;
    order: 1;
    margin-right: 0;
    margin-bottom: 8px;
  }
  .acf-admin-page #tmpl-acf-field-group-pro-features .acf-field-group-pro-features-wrapper .acf-field-group-pro-features-content .acf-field-group-pro-features-title,
  .acf-admin-page #tmpl-acf-field-group-pro-features .acf-field-group-pro-features-wrapper .acf-field-group-pro-features-content .acf-field-group-pro-features-desc,
  .acf-admin-page #acf-field-group-pro-features .acf-field-group-pro-features-wrapper .acf-field-group-pro-features-content .acf-field-group-pro-features-title,
  .acf-admin-page #acf-field-group-pro-features .acf-field-group-pro-features-wrapper .acf-field-group-pro-features-content .acf-field-group-pro-features-desc {
    display: none !important;
  }
}
.acf-admin-page #tmpl-acf-field-group-pro-features .acf-field-group-pro-features-wrapper .acf-field-group-pro-features-actions,
.acf-admin-page #acf-field-group-pro-features .acf-field-group-pro-features-wrapper .acf-field-group-pro-features-actions {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  min-width: 160px;
  gap: 12px;
}
@media screen and (max-width: 768px) {
  .acf-admin-page #tmpl-acf-field-group-pro-features .acf-field-group-pro-features-wrapper .acf-field-group-pro-features-actions,
  .acf-admin-page #acf-field-group-pro-features .acf-field-group-pro-features-wrapper .acf-field-group-pro-features-actions {
    justify-content: flex-start;
    flex-direction: column;
    margin-bottom: 24px;
  }
  .acf-admin-page #tmpl-acf-field-group-pro-features .acf-field-group-pro-features-wrapper .acf-field-group-pro-features-actions a,
  .acf-admin-page #acf-field-group-pro-features .acf-field-group-pro-features-wrapper .acf-field-group-pro-features-actions a {
    justify-content: center;
    text-align: center;
    width: 100%;
  }
}
.acf-admin-page #tmpl-acf-field-group-pro-features .acf-field-group-pro-features-wrapper .acf-field-group-pro-features-grid,
.acf-admin-page #acf-field-group-pro-features .acf-field-group-pro-features-wrapper .acf-field-group-pro-features-grid {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 16px;
  width: 416px;
}
.acf-admin-page #tmpl-acf-field-group-pro-features .acf-field-group-pro-features-wrapper .acf-field-group-pro-features-grid .acf-field-group-pro-feature,
.acf-admin-page #acf-field-group-pro-features .acf-field-group-pro-features-wrapper .acf-field-group-pro-features-grid .acf-field-group-pro-feature {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 128px;
  height: 124px;
  background: rgba(255, 255, 255, 0.08);
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.04), 0px 8px 16px rgba(0, 0, 0, 0.08), inset 0 0 0 1px rgba(255, 255, 255, 0.08);
  backdrop-filter: blur(6px);
  border-radius: 8px;
}
.acf-admin-page #tmpl-acf-field-group-pro-features .acf-field-group-pro-features-wrapper .acf-field-group-pro-features-grid .acf-field-group-pro-feature .field-type-icon,
.acf-admin-page #acf-field-group-pro-features .acf-field-group-pro-features-wrapper .acf-field-group-pro-features-grid .acf-field-group-pro-feature .field-type-icon {
  border: none;
  background: none;
  width: 24px;
  opacity: 0.8;
}
.acf-admin-page #tmpl-acf-field-group-pro-features .acf-field-group-pro-features-wrapper .acf-field-group-pro-features-grid .acf-field-group-pro-feature .field-type-icon::before,
.acf-admin-page #acf-field-group-pro-features .acf-field-group-pro-features-wrapper .acf-field-group-pro-features-grid .acf-field-group-pro-feature .field-type-icon::before {
  background-color: #fff;
  width: 20px;
  height: 20px;
}
@media screen and (max-width: 1200px) {
  .acf-admin-page #tmpl-acf-field-group-pro-features .acf-field-group-pro-features-wrapper .acf-field-group-pro-features-grid .acf-field-group-pro-feature .field-type-icon::before,
  .acf-admin-page #acf-field-group-pro-features .acf-field-group-pro-features-wrapper .acf-field-group-pro-features-grid .acf-field-group-pro-feature .field-type-icon::before {
    width: 18px;
    height: 18px;
  }
}
.acf-admin-page #tmpl-acf-field-group-pro-features .acf-field-group-pro-features-wrapper .acf-field-group-pro-features-grid .acf-field-group-pro-feature .pro-feature-blocks::before,
.acf-admin-page #acf-field-group-pro-features .acf-field-group-pro-features-wrapper .acf-field-group-pro-features-grid .acf-field-group-pro-feature .pro-feature-blocks::before {
  -webkit-mask-image: url("../../images/icons/icon-extended-menu.svg");
  mask-image: url("../../images/icons/icon-extended-menu.svg");
}
.acf-admin-page #tmpl-acf-field-group-pro-features .acf-field-group-pro-features-wrapper .acf-field-group-pro-features-grid .acf-field-group-pro-feature .pro-feature-options-pages::before,
.acf-admin-page #acf-field-group-pro-features .acf-field-group-pro-features-wrapper .acf-field-group-pro-features-grid .acf-field-group-pro-feature .pro-feature-options-pages::before {
  -webkit-mask-image: url("../../images/icons/icon-settings.svg");
  mask-image: url("../../images/icons/icon-settings.svg");
}
.acf-admin-page #tmpl-acf-field-group-pro-features .acf-field-group-pro-features-wrapper .acf-field-group-pro-features-grid .acf-field-group-pro-feature .field-type-label,
.acf-admin-page #acf-field-group-pro-features .acf-field-group-pro-features-wrapper .acf-field-group-pro-features-grid .acf-field-group-pro-feature .field-type-label {
  margin-top: 4px;
  font-size: 13px;
  font-weight: 300;
  text-align: center;
  color: #fff;
}
@media screen and (max-width: 1200px) {
  .acf-admin-page #tmpl-acf-field-group-pro-features .acf-field-group-pro-features-wrapper .acf-field-group-pro-features-grid,
  .acf-admin-page #acf-field-group-pro-features .acf-field-group-pro-features-wrapper .acf-field-group-pro-features-grid {
    flex-direction: column;
    gap: 8px;
    width: 288px;
  }
  .acf-admin-page #tmpl-acf-field-group-pro-features .acf-field-group-pro-features-wrapper .acf-field-group-pro-features-grid .acf-field-group-pro-feature,
  .acf-admin-page #acf-field-group-pro-features .acf-field-group-pro-features-wrapper .acf-field-group-pro-features-grid .acf-field-group-pro-feature {
    width: 100%;
    height: 40px;
    flex-direction: row;
    justify-content: unset;
    gap: 8px;
  }
  .acf-admin-page #tmpl-acf-field-group-pro-features .acf-field-group-pro-features-wrapper .acf-field-group-pro-features-grid .acf-field-group-pro-feature .field-type-icon,
  .acf-admin-page #acf-field-group-pro-features .acf-field-group-pro-features-wrapper .acf-field-group-pro-features-grid .acf-field-group-pro-feature .field-type-icon {
    position: initial;
    margin-left: 16px;
  }
  .acf-admin-page #tmpl-acf-field-group-pro-features .acf-field-group-pro-features-wrapper .acf-field-group-pro-features-grid .acf-field-group-pro-feature .field-type-label,
  .acf-admin-page #acf-field-group-pro-features .acf-field-group-pro-features-wrapper .acf-field-group-pro-features-grid .acf-field-group-pro-feature .field-type-label {
    margin-top: 0;
  }
}
@media screen and (max-width: 768px) {
  .acf-admin-page #tmpl-acf-field-group-pro-features .acf-field-group-pro-features-wrapper .acf-field-group-pro-features-grid,
  .acf-admin-page #acf-field-group-pro-features .acf-field-group-pro-features-wrapper .acf-field-group-pro-features-grid {
    gap: 0;
    width: 100%;
    height: auto;
    margin-bottom: 16px;
    flex-direction: unset;
    flex-wrap: wrap;
  }
  .acf-admin-page #tmpl-acf-field-group-pro-features .acf-field-group-pro-features-wrapper .acf-field-group-pro-features-grid .acf-field-group-pro-feature,
  .acf-admin-page #acf-field-group-pro-features .acf-field-group-pro-features-wrapper .acf-field-group-pro-features-grid .acf-field-group-pro-feature {
    flex: 1 0 50%;
    margin-bottom: 8px;
    width: auto;
    height: auto;
    justify-content: center;
    background: none;
    box-shadow: none;
    backdrop-filter: none;
  }
  .acf-admin-page #tmpl-acf-field-group-pro-features .acf-field-group-pro-features-wrapper .acf-field-group-pro-features-grid .acf-field-group-pro-feature .field-type-label,
  .acf-admin-page #acf-field-group-pro-features .acf-field-group-pro-features-wrapper .acf-field-group-pro-features-grid .acf-field-group-pro-feature .field-type-label {
    margin-left: 2px;
  }
  .acf-admin-page #tmpl-acf-field-group-pro-features .acf-field-group-pro-features-wrapper .acf-field-group-pro-features-grid .acf-field-group-pro-feature .field-type-icon,
  .acf-admin-page #acf-field-group-pro-features .acf-field-group-pro-features-wrapper .acf-field-group-pro-features-grid .acf-field-group-pro-feature .field-type-icon {
    position: initial;
    margin-left: 0;
  }
  .acf-admin-page #tmpl-acf-field-group-pro-features .acf-field-group-pro-features-wrapper .acf-field-group-pro-features-grid .acf-field-group-pro-feature .field-type-icon:before,
  .acf-admin-page #acf-field-group-pro-features .acf-field-group-pro-features-wrapper .acf-field-group-pro-features-grid .acf-field-group-pro-feature .field-type-icon:before {
    height: 16px;
    width: 16px;
  }
  .acf-admin-page #tmpl-acf-field-group-pro-features .acf-field-group-pro-features-wrapper .acf-field-group-pro-features-grid .acf-field-group-pro-feature .field-type-label,
  .acf-admin-page #acf-field-group-pro-features .acf-field-group-pro-features-wrapper .acf-field-group-pro-features-grid .acf-field-group-pro-feature .field-type-label {
    font-size: 12px;
    margin-top: 0;
  }
}
.acf-admin-page #tmpl-acf-field-group-pro-features h1,
.acf-admin-page #acf-field-group-pro-features h1 {
  margin-top: 0;
  margin-bottom: 4px;
  padding-top: 0;
  padding-bottom: 0;
  font-weight: bold;
  color: #F9FAFB;
}
.acf-admin-page #tmpl-acf-field-group-pro-features h1 .acf-icon,
.acf-admin-page #acf-field-group-pro-features h1 .acf-icon {
  margin-right: 8px;
}
.acf-admin-page #tmpl-acf-field-group-pro-features .acf-btn,
.acf-admin-page #acf-field-group-pro-features .acf-btn {
  display: inline-flex;
  background-color: rgba(255, 255, 255, 0.1);
  border: none;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.04), 0px 4px 8px rgba(0, 0, 0, 0.06), inset 0 0 0 1px rgba(255, 255, 255, 0.16);
  backdrop-filter: blur(6px);
  padding: 8px 24px;
  height: 48px;
}
.acf-admin-page #tmpl-acf-field-group-pro-features .acf-btn:hover,
.acf-admin-page #acf-field-group-pro-features .acf-btn:hover {
  background-color: rgba(255, 255, 255, 0.2);
}
.acf-admin-page #tmpl-acf-field-group-pro-features .acf-btn .acf-icon,
.acf-admin-page #acf-field-group-pro-features .acf-btn .acf-icon {
  margin-right: -2px;
  margin-left: 6px;
}
.acf-admin-page #tmpl-acf-field-group-pro-features .acf-btn.acf-pro-features-upgrade,
.acf-admin-page #acf-field-group-pro-features .acf-btn.acf-pro-features-upgrade {
  background: linear-gradient(90.52deg, #3E8BFF 0.44%, #A45CFF 113.3%);
  background-size: 160% 80%;
  background-position: 100% 0;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.04), 0px 4px 8px rgba(0, 0, 0, 0.06), inset 0 0 0 1px rgba(255, 255, 255, 0.16);
  border-radius: 6px;
  transition: background-position 0.5s;
}
.acf-admin-page #tmpl-acf-field-group-pro-features .acf-btn.acf-pro-features-upgrade:hover,
.acf-admin-page #acf-field-group-pro-features .acf-btn.acf-pro-features-upgrade:hover {
  background-position: 0 0;
}
.acf-admin-page #tmpl-acf-field-group-pro-features .acf-field-group-pro-features-footer-wrap,
.acf-admin-page #acf-field-group-pro-features .acf-field-group-pro-features-footer-wrap {
  height: 48px;
  background: rgba(16, 24, 40, 0.4);
  backdrop-filter: blur(6px);
  border-top: 1px solid rgba(255, 255, 255, 0.08);
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  color: #98A2B3;
  font-size: 13px;
  font-weight: 300;
  padding: 0 35px;
}
.acf-admin-page #tmpl-acf-field-group-pro-features .acf-field-group-pro-features-footer-wrap .acf-field-group-pro-features-footer,
.acf-admin-page #acf-field-group-pro-features .acf-field-group-pro-features-footer-wrap .acf-field-group-pro-features-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 950px;
  height: 48px;
  margin: 0 auto;
}
.acf-admin-page #tmpl-acf-field-group-pro-features .acf-field-group-pro-features-footer-wrap .acf-field-group-pro-features-wpengine-logo,
.acf-admin-page #acf-field-group-pro-features .acf-field-group-pro-features-footer-wrap .acf-field-group-pro-features-wpengine-logo {
  height: 16px;
  vertical-align: middle;
  margin-top: -2px;
  margin-left: 3px;
}
.acf-admin-page #tmpl-acf-field-group-pro-features .acf-field-group-pro-features-footer-wrap a,
.acf-admin-page #acf-field-group-pro-features .acf-field-group-pro-features-footer-wrap a {
  color: #98A2B3;
  text-decoration: none;
}
.acf-admin-page #tmpl-acf-field-group-pro-features .acf-field-group-pro-features-footer-wrap a:hover,
.acf-admin-page #acf-field-group-pro-features .acf-field-group-pro-features-footer-wrap a:hover {
  color: #D0D5DD;
}
.acf-admin-page #tmpl-acf-field-group-pro-features .acf-field-group-pro-features-footer-wrap a .acf-icon,
.acf-admin-page #acf-field-group-pro-features .acf-field-group-pro-features-footer-wrap a .acf-icon {
  width: 18px;
  height: 18px;
  margin-left: 4px;
}
.acf-admin-page #tmpl-acf-field-group-pro-features .acf-field-group-pro-features-footer-wrap .acf-more-tools-from-wpengine a,
.acf-admin-page #acf-field-group-pro-features .acf-field-group-pro-features-footer-wrap .acf-more-tools-from-wpengine a {
  display: inline-flex;
  align-items: center;
}
@media screen and (max-width: 768px) {
  .acf-admin-page #tmpl-acf-field-group-pro-features .acf-field-group-pro-features-footer-wrap,
  .acf-admin-page #acf-field-group-pro-features .acf-field-group-pro-features-footer-wrap {
    height: 70px;
    font-size: 12px;
  }
  .acf-admin-page #tmpl-acf-field-group-pro-features .acf-field-group-pro-features-footer-wrap .acf-more-tools-from-wpengine,
  .acf-admin-page #acf-field-group-pro-features .acf-field-group-pro-features-footer-wrap .acf-more-tools-from-wpengine {
    display: none;
  }
  .acf-admin-page #tmpl-acf-field-group-pro-features .acf-field-group-pro-features-footer-wrap .acf-field-group-pro-features-footer,
  .acf-admin-page #acf-field-group-pro-features .acf-field-group-pro-features-footer-wrap .acf-field-group-pro-features-footer {
    justify-content: center;
    height: 70px;
  }
  .acf-admin-page #tmpl-acf-field-group-pro-features .acf-field-group-pro-features-footer-wrap .acf-field-group-pro-features-footer .acf-field-group-pro-features-wpengine-logo,
  .acf-admin-page #acf-field-group-pro-features .acf-field-group-pro-features-footer-wrap .acf-field-group-pro-features-footer .acf-field-group-pro-features-wpengine-logo {
    clear: both;
    margin: 6px auto 0 auto;
    display: block;
  }
}

.acf-no-field-groups #tmpl-acf-field-group-pro-features,
.acf-no-post-types #tmpl-acf-field-group-pro-features,
.acf-no-taxonomies #tmpl-acf-field-group-pro-features {
  margin-top: 0;
}

/*--------------------------------------------------------------------------------------------
*
*	Post type & taxonomies styles
*
*--------------------------------------------------------------------------------------------*/
.acf-admin-single-post-type label[for=acf-basic-settings-hide],
.acf-admin-single-taxonomy label[for=acf-basic-settings-hide],
.acf-admin-single-options-page label[for=acf-basic-settings-hide] {
  display: none;
}
.acf-admin-single-post-type fieldset.columns-prefs,
.acf-admin-single-taxonomy fieldset.columns-prefs,
.acf-admin-single-options-page fieldset.columns-prefs {
  display: none;
}
.acf-admin-single-post-type #acf-basic-settings .postbox-header,
.acf-admin-single-taxonomy #acf-basic-settings .postbox-header,
.acf-admin-single-options-page #acf-basic-settings .postbox-header {
  display: none;
}
.acf-admin-single-post-type .postbox-container,
.acf-admin-single-post-type .notice,
.acf-admin-single-taxonomy .postbox-container,
.acf-admin-single-taxonomy .notice,
.acf-admin-single-options-page .postbox-container,
.acf-admin-single-options-page .notice {
  max-width: 1440px;
  clear: left;
}
.acf-admin-single-post-type #post-body-content,
.acf-admin-single-taxonomy #post-body-content,
.acf-admin-single-options-page #post-body-content {
  margin: 0;
}
.acf-admin-single-post-type .postbox .inside,
.acf-admin-single-post-type .acf-box .inside,
.acf-admin-single-taxonomy .postbox .inside,
.acf-admin-single-taxonomy .acf-box .inside,
.acf-admin-single-options-page .postbox .inside,
.acf-admin-single-options-page .acf-box .inside {
  padding-top: 48px;
  padding-right: 48px;
  padding-bottom: 48px;
  padding-left: 48px;
}
.acf-admin-single-post-type #acf-advanced-settings.postbox .inside,
.acf-admin-single-taxonomy #acf-advanced-settings.postbox .inside,
.acf-admin-single-options-page #acf-advanced-settings.postbox .inside {
  padding-bottom: 24px;
}
.acf-admin-single-post-type .postbox-container .meta-box-sortables #acf-basic-settings .inside,
.acf-admin-single-taxonomy .postbox-container .meta-box-sortables #acf-basic-settings .inside,
.acf-admin-single-options-page .postbox-container .meta-box-sortables #acf-basic-settings .inside {
  border: none;
}
.acf-admin-single-post-type .acf-input-wrap,
.acf-admin-single-taxonomy .acf-input-wrap,
.acf-admin-single-options-page .acf-input-wrap {
  overflow: visible;
}
.acf-admin-single-post-type .acf-field,
.acf-admin-single-taxonomy .acf-field,
.acf-admin-single-options-page .acf-field {
  margin-top: 0;
  margin-right: 0;
  margin-bottom: 24px;
  margin-left: 0;
}
.acf-admin-single-post-type .acf-field .acf-label,
.acf-admin-single-taxonomy .acf-field .acf-label,
.acf-admin-single-options-page .acf-field .acf-label {
  margin-bottom: 6px;
}
.acf-admin-single-post-type .acf-field-text,
.acf-admin-single-post-type .acf-field-textarea,
.acf-admin-single-post-type .acf-field-select,
.acf-admin-single-taxonomy .acf-field-text,
.acf-admin-single-taxonomy .acf-field-textarea,
.acf-admin-single-taxonomy .acf-field-select,
.acf-admin-single-options-page .acf-field-text,
.acf-admin-single-options-page .acf-field-textarea,
.acf-admin-single-options-page .acf-field-select {
  max-width: 600px;
}
.acf-admin-single-post-type .acf-field-true-false,
.acf-admin-single-taxonomy .acf-field-true-false,
.acf-admin-single-options-page .acf-field-true-false {
  max-width: 700px;
}
.acf-admin-single-post-type .acf-field-supports,
.acf-admin-single-taxonomy .acf-field-supports,
.acf-admin-single-options-page .acf-field-supports {
  max-width: 600px;
}
.acf-admin-single-post-type .acf-field-supports .acf-label,
.acf-admin-single-taxonomy .acf-field-supports .acf-label,
.acf-admin-single-options-page .acf-field-supports .acf-label {
  display: block;
}
.acf-admin-single-post-type .acf-field-supports .acf-label .description,
.acf-admin-single-taxonomy .acf-field-supports .acf-label .description,
.acf-admin-single-options-page .acf-field-supports .acf-label .description {
  margin-top: 4px;
  margin-bottom: 12px;
}
.acf-admin-single-post-type .acf-field-supports .acf_post_type_supports,
.acf-admin-single-taxonomy .acf-field-supports .acf_post_type_supports,
.acf-admin-single-options-page .acf-field-supports .acf_post_type_supports {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-content: flex-start;
  align-items: flex-start;
}
.acf-admin-single-post-type .acf-field-supports .acf_post_type_supports:focus-within,
.acf-admin-single-taxonomy .acf-field-supports .acf_post_type_supports:focus-within,
.acf-admin-single-options-page .acf-field-supports .acf_post_type_supports:focus-within {
  border-color: transparent;
}
.acf-admin-single-post-type .acf-field-supports .acf_post_type_supports li,
.acf-admin-single-taxonomy .acf-field-supports .acf_post_type_supports li,
.acf-admin-single-options-page .acf-field-supports .acf_post_type_supports li {
  flex: 0 0 25%;
}
.acf-admin-single-post-type .acf-field-supports .acf_post_type_supports li a.button,
.acf-admin-single-taxonomy .acf-field-supports .acf_post_type_supports li a.button,
.acf-admin-single-options-page .acf-field-supports .acf_post_type_supports li a.button {
  background-color: transparent;
  padding: 0;
  border: 0;
  height: auto;
  min-height: auto;
  margin-top: 0;
  border-radius: 0;
  line-height: 22px;
}
.acf-admin-single-post-type .acf-field-supports .acf_post_type_supports li a.button:before,
.acf-admin-single-taxonomy .acf-field-supports .acf_post_type_supports li a.button:before,
.acf-admin-single-options-page .acf-field-supports .acf_post_type_supports li a.button:before {
  content: "";
  margin-right: 6px;
  display: inline-flex;
  width: 16px;
  height: 16px;
  background-color: currentColor;
  border: none;
  border-radius: 0;
  -webkit-mask-size: contain;
  mask-size: contain;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  text-indent: 500%;
  white-space: nowrap;
  overflow: hidden;
  -webkit-mask-image: url("../../images/icons/icon-add.svg");
  mask-image: url("../../images/icons/icon-add.svg");
}
.acf-admin-single-post-type .acf-field-supports .acf_post_type_supports li a.button:hover,
.acf-admin-single-taxonomy .acf-field-supports .acf_post_type_supports li a.button:hover,
.acf-admin-single-options-page .acf-field-supports .acf_post_type_supports li a.button:hover {
  color: #044E71;
}
.acf-admin-single-post-type .acf-field-supports .acf_post_type_supports li input[type=text],
.acf-admin-single-taxonomy .acf-field-supports .acf_post_type_supports li input[type=text],
.acf-admin-single-options-page .acf-field-supports .acf_post_type_supports li input[type=text] {
  width: calc(100% - 36px);
  padding: 0;
  box-shadow: none;
  border: none;
  border-bottom: 1px solid #D0D5DD;
  border-radius: 0;
  height: auto;
  margin: 0;
  min-height: auto;
}
.acf-admin-single-post-type .acf-field-supports .acf_post_type_supports li input[type=text]:focus,
.acf-admin-single-taxonomy .acf-field-supports .acf_post_type_supports li input[type=text]:focus,
.acf-admin-single-options-page .acf-field-supports .acf_post_type_supports li input[type=text]:focus {
  outline: none;
  border-bottom-color: #399CCB;
}
.acf-admin-single-post-type .acf-field-seperator,
.acf-admin-single-taxonomy .acf-field-seperator,
.acf-admin-single-options-page .acf-field-seperator {
  margin-top: 40px;
  margin-bottom: 40px;
  border-top: 1px solid #EAECF0;
  border-right: none;
  border-bottom: none;
  border-left: none;
}
.acf-admin-single-post-type .acf-field-advanced-configuration,
.acf-admin-single-taxonomy .acf-field-advanced-configuration,
.acf-admin-single-options-page .acf-field-advanced-configuration {
  margin-bottom: 0;
}
.acf-admin-single-post-type .postbox-container .acf-tab-wrap,
.acf-admin-single-post-type .acf-regenerate-labels-bar,
.acf-admin-single-taxonomy .postbox-container .acf-tab-wrap,
.acf-admin-single-taxonomy .acf-regenerate-labels-bar,
.acf-admin-single-options-page .postbox-container .acf-tab-wrap,
.acf-admin-single-options-page .acf-regenerate-labels-bar {
  position: relative;
  top: -48px;
  left: -48px;
  width: calc(100% + 96px);
}
.acf-admin-single-post-type .acf-regenerate-labels-bar,
.acf-admin-single-taxonomy .acf-regenerate-labels-bar,
.acf-admin-single-options-page .acf-regenerate-labels-bar {
  display: flex;
  align-items: center;
  justify-content: right;
  min-height: 48px;
  margin-bottom: 0;
  padding-right: 16px;
  padding-left: 16px;
  gap: 8px;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: #F2F4F7;
}
.acf-admin-single-post-type .acf-labels-tip,
.acf-admin-single-taxonomy .acf-labels-tip,
.acf-admin-single-options-page .acf-labels-tip {
  display: inline-flex;
  align-items: center;
  min-height: 24px;
  margin-right: 8px;
  padding-left: 16px;
  border-left-width: 1px;
  border-left-style: solid;
  border-left-color: #EAECF0;
}
.acf-admin-single-post-type .acf-labels-tip .acf-icon,
.acf-admin-single-taxonomy .acf-labels-tip .acf-icon,
.acf-admin-single-options-page .acf-labels-tip .acf-icon {
  display: inline-flex;
  align-items: center;
  width: 16px;
  height: 16px;
  -webkit-mask-size: 16px;
  mask-size: 16px;
  background-color: #98A2B3;
}

.acf-select2-default-pill {
  border-radius: 100px;
  min-height: 20px;
  padding-top: 2px;
  padding-bottom: 2px;
  padding-left: 8px;
  padding-right: 8px;
  font-size: 11px;
  margin-left: 6px;
  background-color: #EAECF0;
  color: #667085;
}

.acf-menu-position-desc-child {
  display: none;
}

/*---------------------------------------------------------------------------------------------
*
*  Field picker modal
*
*---------------------------------------------------------------------------------------------*/
.acf-modal.acf-browse-fields-modal {
  width: 1120px;
  height: 664px;
  top: 50%;
  right: auto;
  bottom: auto;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: row;
  border-radius: 12px;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.04), 0px 8px 16px rgba(0, 0, 0, 0.08);
  overflow: hidden;
}
.acf-modal.acf-browse-fields-modal .acf-field-picker {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 760px;
  background: #fff;
}
.acf-modal.acf-browse-fields-modal .acf-field-picker .acf-modal-title,
.acf-modal.acf-browse-fields-modal .acf-field-picker .acf-modal-content,
.acf-modal.acf-browse-fields-modal .acf-field-picker .acf-modal-toolbar {
  position: relative;
}
.acf-modal.acf-browse-fields-modal .acf-field-picker .acf-modal-title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background: #F9FAFB;
  border: none;
  padding: 35px 32px;
}
.acf-modal.acf-browse-fields-modal .acf-field-picker .acf-modal-title .acf-search-field-types-wrap {
  position: relative;
}
.acf-modal.acf-browse-fields-modal .acf-field-picker .acf-modal-title .acf-search-field-types-wrap:after {
  content: "";
  display: block;
  position: absolute;
  top: 11px;
  left: 10px;
  width: 18px;
  height: 18px;
  -webkit-mask-image: url("../../images/icons/icon-search.svg");
  mask-image: url("../../images/icons/icon-search.svg");
  background-color: #98A2B3;
  border: none;
  border-radius: 0;
  -webkit-mask-size: contain;
  mask-size: contain;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  text-indent: 500%;
  white-space: nowrap;
  overflow: hidden;
}
.acf-modal.acf-browse-fields-modal .acf-field-picker .acf-modal-title .acf-search-field-types-wrap input {
  width: 280px;
  height: 40px;
  margin: 0;
  padding-left: 32px;
  box-shadow: none;
}
.acf-modal.acf-browse-fields-modal .acf-field-picker .acf-modal-content {
  top: auto;
  bottom: auto;
  padding: 0;
  height: 100%;
}
.acf-modal.acf-browse-fields-modal .acf-field-picker .acf-modal-content .acf-tab-group {
  padding-left: 32px;
}
.acf-modal.acf-browse-fields-modal .acf-field-picker .acf-modal-content .acf-field-types-tab {
  display: flex;
}
.acf-modal.acf-browse-fields-modal .acf-field-picker .acf-modal-content .acf-field-types-tab,
.acf-modal.acf-browse-fields-modal .acf-field-picker .acf-modal-content .acf-field-type-search-results {
  flex-direction: row;
  flex-wrap: wrap;
  gap: 24px;
  padding: 32px;
}
.acf-modal.acf-browse-fields-modal .acf-field-picker .acf-modal-content .acf-field-types-tab .acf-field-type,
.acf-modal.acf-browse-fields-modal .acf-field-picker .acf-modal-content .acf-field-type-search-results .acf-field-type {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  isolation: isolate;
  width: 120px;
  height: 120px;
  background: #F9FAFB;
  border: 1px solid #EAECF0;
  border-radius: 8px;
  box-sizing: border-box;
  color: #1D2939;
  text-decoration: none;
}
.acf-modal.acf-browse-fields-modal .acf-field-picker .acf-modal-content .acf-field-types-tab .acf-field-type:hover, .acf-modal.acf-browse-fields-modal .acf-field-picker .acf-modal-content .acf-field-types-tab .acf-field-type:active, .acf-modal.acf-browse-fields-modal .acf-field-picker .acf-modal-content .acf-field-types-tab .acf-field-type.selected,
.acf-modal.acf-browse-fields-modal .acf-field-picker .acf-modal-content .acf-field-type-search-results .acf-field-type:hover,
.acf-modal.acf-browse-fields-modal .acf-field-picker .acf-modal-content .acf-field-type-search-results .acf-field-type:active,
.acf-modal.acf-browse-fields-modal .acf-field-picker .acf-modal-content .acf-field-type-search-results .acf-field-type.selected {
  background: #EBF5FA;
  border: 1px solid #399CCB;
  box-shadow: inset 0 0 0 1px #399CCB;
}
.acf-modal.acf-browse-fields-modal .acf-field-picker .acf-modal-content .acf-field-types-tab .acf-field-type .field-type-icon,
.acf-modal.acf-browse-fields-modal .acf-field-picker .acf-modal-content .acf-field-type-search-results .acf-field-type .field-type-icon {
  border: none;
  background: none;
  top: 0;
}
.acf-modal.acf-browse-fields-modal .acf-field-picker .acf-modal-content .acf-field-types-tab .acf-field-type .field-type-icon:before,
.acf-modal.acf-browse-fields-modal .acf-field-picker .acf-modal-content .acf-field-type-search-results .acf-field-type .field-type-icon:before {
  width: 22px;
  height: 22px;
}
.acf-modal.acf-browse-fields-modal .acf-field-picker .acf-modal-content .acf-field-types-tab .acf-field-type .field-type-label,
.acf-modal.acf-browse-fields-modal .acf-field-picker .acf-modal-content .acf-field-type-search-results .acf-field-type .field-type-label {
  margin-top: 12px;
}
.acf-modal.acf-browse-fields-modal .acf-field-picker .acf-modal-content .acf-field-types-tab .field-type-requires-pro,
.acf-modal.acf-browse-fields-modal .acf-field-picker .acf-modal-content .acf-field-type-search-results .field-type-requires-pro {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: -10px;
  right: -10px;
  height: 21px;
  color: white;
  background: linear-gradient(90.52deg, #3E8BFF 0.44%, #A45CFF 113.3%);
  background-size: 140% 20%;
  background-position: 100% 0;
  border-radius: 100px;
  font-size: 11px;
  padding-right: 6px;
  padding-left: 6px;
}
.acf-modal.acf-browse-fields-modal .acf-field-picker .acf-modal-content .acf-field-types-tab .field-type-requires-pro i,
.acf-modal.acf-browse-fields-modal .acf-field-picker .acf-modal-content .acf-field-type-search-results .field-type-requires-pro i {
  width: 12px;
  height: 12px;
  margin-right: 2px;
}
.acf-modal.acf-browse-fields-modal .acf-field-picker .acf-modal-toolbar {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  height: auto;
  min-height: 72px;
  padding-top: 0;
  padding-right: 32px;
  padding-bottom: 0;
  padding-left: 32px;
  margin: 0;
  border: none;
}
.acf-modal.acf-browse-fields-modal .acf-field-picker .acf-modal-toolbar .acf-select-field,
.acf-modal.acf-browse-fields-modal .acf-field-picker .acf-modal-toolbar .acf-btn-pro {
  min-width: 160px;
  justify-content: center;
}
.acf-modal.acf-browse-fields-modal .acf-field-picker .acf-modal-toolbar .acf-insert-field-label {
  min-width: 280px;
  box-shadow: none;
}
.acf-modal.acf-browse-fields-modal .acf-field-picker .acf-modal-toolbar .acf-field-picker-actions {
  display: flex;
  gap: 8px;
}
.acf-modal.acf-browse-fields-modal .acf-field-type-preview {
  display: flex;
  flex-direction: column;
  width: 360px;
  background-color: #F9FAFB;
  background-image: url("../../images/field-preview-grid.png");
  background-size: 740px;
  background-repeat: no-repeat;
  background-position: center bottom;
  border-left: 1px solid #EAECF0;
  box-sizing: border-box;
  padding: 32px;
}
.acf-modal.acf-browse-fields-modal .acf-field-type-preview .field-type-desc {
  margin: 0;
  padding: 0;
  color: #667085;
}
.acf-modal.acf-browse-fields-modal .acf-field-type-preview .field-type-preview-container {
  display: inline-flex;
  justify-content: center;
  width: 100%;
  margin-top: 24px;
  padding-top: 32px;
  padding-bottom: 32px;
  background-color: rgba(255, 255, 255, 0.64);
  border-radius: 8px;
  box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.04), 0px 8px 24px rgba(0, 0, 0, 0.04);
}
.acf-modal.acf-browse-fields-modal .acf-field-type-preview .field-type-image {
  max-width: 232px;
}
.acf-modal.acf-browse-fields-modal .acf-field-type-preview .field-type-info {
  flex-grow: 1;
}
.acf-modal.acf-browse-fields-modal .acf-field-type-preview .field-type-info .field-type-name {
  font-size: 21px;
  margin-top: 0;
  margin-right: 0;
  margin-bottom: 16px;
  margin-left: 0;
}
.acf-modal.acf-browse-fields-modal .acf-field-type-preview .field-type-info .field-type-upgrade-to-unlock {
  display: inline-flex;
  justify-items: center;
  align-items: center;
  min-height: 24px;
  margin-bottom: 12px;
  padding-right: 8px;
  padding-left: 8px;
  background: linear-gradient(90.52deg, #3E8BFF 0.44%, #A45CFF 113.3%);
  background-size: 140% 20%;
  background-position: 100% 0;
  border-radius: 100px;
  color: white;
  text-decoration: none;
  font-size: 11px;
}
.acf-modal.acf-browse-fields-modal .acf-field-type-preview .field-type-info .field-type-upgrade-to-unlock i.acf-icon {
  width: 14px;
  height: 14px;
  margin-right: 4px;
}
.acf-modal.acf-browse-fields-modal .acf-field-type-preview .field-type-links {
  display: flex;
  align-items: center;
  gap: 24px;
  min-height: 40px;
}
.acf-modal.acf-browse-fields-modal .acf-field-type-preview .field-type-links .acf-icon {
  width: 18px;
  height: 18px;
}
.acf-modal.acf-browse-fields-modal .acf-field-type-preview .field-type-links:before {
  display: none;
}
.acf-modal.acf-browse-fields-modal .acf-field-type-preview .field-type-links a {
  display: flex;
  gap: 6px;
  text-decoration: none;
}
.acf-modal.acf-browse-fields-modal .acf-field-type-preview .field-type-links a:hover {
  text-decoration: underline;
}
.acf-modal.acf-browse-fields-modal .acf-field-type-search-results,
.acf-modal.acf-browse-fields-modal .acf-field-type-search-no-results {
  display: none;
}
.acf-modal.acf-browse-fields-modal.is-searching .acf-tab-wrap,
.acf-modal.acf-browse-fields-modal.is-searching .acf-field-types-tab,
.acf-modal.acf-browse-fields-modal.is-searching .acf-field-type-search-no-results {
  display: none !important;
}
.acf-modal.acf-browse-fields-modal.is-searching .acf-field-type-search-results {
  display: flex;
}
.acf-modal.acf-browse-fields-modal.no-results-found .acf-tab-wrap,
.acf-modal.acf-browse-fields-modal.no-results-found .acf-field-types-tab,
.acf-modal.acf-browse-fields-modal.no-results-found .acf-field-type-search-results,
.acf-modal.acf-browse-fields-modal.no-results-found .field-type-info,
.acf-modal.acf-browse-fields-modal.no-results-found .field-type-links,
.acf-modal.acf-browse-fields-modal.no-results-found .acf-field-picker-toolbar {
  display: none !important;
}
.acf-modal.acf-browse-fields-modal.no-results-found .acf-modal-title {
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: #EAECF0;
}
.acf-modal.acf-browse-fields-modal.no-results-found .acf-field-type-search-no-results {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  gap: 6px;
}
.acf-modal.acf-browse-fields-modal.no-results-found .acf-field-type-search-no-results img {
  margin-bottom: 19px;
}
.acf-modal.acf-browse-fields-modal.no-results-found .acf-field-type-search-no-results p {
  margin: 0;
}
.acf-modal.acf-browse-fields-modal.no-results-found .acf-field-type-search-no-results p.acf-no-results-text {
  display: flex;
}
.acf-modal.acf-browse-fields-modal.no-results-found .acf-field-type-search-no-results .acf-invalid-search-term {
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
}

/*---------------------------------------------------------------------------------------------
*
*  Hide browse fields button for smaller screen sizes
*
*---------------------------------------------------------------------------------------------*/
@media only screen and (max-width: 1080px) {
  .acf-btn.browse-fields {
    display: none;
  }
}